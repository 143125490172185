import { useEffect } from "react";

export default function usePageView() {

  useEffect(() => {
    if (!!window.fathom) {
      window.fathom.trackPageview();
    } else {
      let attempts = 0;

      const timer = setInterval(() => {
        if (!!window.fathom) {
          window.fathom.trackPageview();
          clearInterval(timer);
        } else if (++attempts > 10) {
          clearInterval(timer);
        }
      }, 1000);

      return () => clearInterval(timer);
    }
  }, []);

}