import axios from "axios";
import { Collection, Retry } from "@emberly/rtac";
import PluginEntity from "./PluginEntity";

export default class PluginCollection extends Collection {

  constructor(context, contextId) {
    super(context, "Plugin", contextId);
    this.contextId = contextId;
    this.flushLimit = 4;
  }


  async fetchEverything() {
    return await this.fetchRemoteState();
  }

  async fetchRemoteState() {
    try {
      const res = await Retry.Axios(async () => await axios(`/api/plugin`), 6);
      return { list: res.data.plugins, success: true };
    } catch (err) {
      console.log("error fetching plugins", err, err ? err.status : null, err && err.response ? err.response.status : null);
      return { list: [], success: false };
    }
  }

  async fetchEntityState(entityId) {
    // todo this one fetches state of loaded topics.
    try {
      const res = await Retry.Axios(async () => await axios(`/api/plugin/${entityId}`), 6);
      return { data: res.data, success: !!res.data };
    } catch (err) {
      console.log("error fetching plugin", err, err ? err.status : null, err && err.response ? err.response.status : null);
      return { data: null, success: false };
    }
  }

  canFetchEverything() {
    return true;
  }

  canFetchEntity() {
    return true;
  }

  /// --- Processing Changes --- //
  onPatchEntity(data) {
    const entity = this.getEntityById(data.id);

    if (!entity) return false;

    if (data.index !== null && typeof data.index === "string") {
      entity.setIndex(data.index, { sync: false });
    }

    if (data.name !== null && typeof data.name === "string") {
      entity.setName(data.name, { sync: false });
    }

    if (data.description !== null && typeof data.description === "string") {
      entity.setDescription(data.description, { sync: false });
    }

    return true;
  }

  // Diff State //

  getUpdatedFields(oldEntity, newEntity) {
    return {
      name: oldEntity.name !== newEntity.name,
      index: oldEntity.index !== newEntity.index,
      description: oldEntity.description !== newEntity.description
    };
  }

  canPatch(updatedFields) {
    return (
      updatedFields.name ||
      updatedFields.index ||
      updatedFields.description
    );
  }


  makeEntity(data) {
    return new PluginEntity(this.entityEvents, data);
  }
}
