import { isEmbed } from "../providers/DeviceContext";

const THEME_MODE_VALUE = "emberly_theme_mode";


export function setLocalThemeMode(themeMode = "light") {
  try {
    window.localStorage.setItem(THEME_MODE_VALUE, themeMode);
  } catch {}
}

export function getLocalThemeMode() {
  try {
    const path = window.location.pathname;
    if (isEmbed || !(path.startsWith("/app") || path.startsWith("/map/"))) {
      return "light";
    }
    return window.localStorage.getItem(THEME_MODE_VALUE) || "light";
  } catch {}
  return false;
}

export function clearLocalThemeMode() {
  try {
    window.localStorage.deleteItem(THEME_MODE_VALUE);
  } catch {}
}