import { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ConfirmDialog from "../../atoms/views/ConfirmDialog";
import { unregister } from "../../../serviceWorkerRegistration";
import { MinimumApiVersionBreached } from "../../../actions/app";

export default function ApiVersionDialog() {
  const [ updating, setUpdating ] = useState(false);
  const dispatch = useDispatch();
  const active = useSelector(state => state.app.minimumApiVersionBreached);
  const handleCancel = useCallback(() => dispatch(MinimumApiVersionBreached(false)),[dispatch]);

  const handleConfirm = useCallback(() => {
    if (updating) return;

    setUpdating(true);

    try {
      unregister();

      if (!!caches) {
        caches.keys().then((names) => {
          for (let name of names) caches.delete(name);
        });
      }

    } catch (err) {
      console.log("error updating service worker", err);
    }

    setTimeout(() => {
      window.location.reload();
    }, 2500);

  },[setUpdating, updating]);

  return (
    <ConfirmDialog
      title={updating ? "Updating.." : "Update Available"}
      open={active}
      confirmText={updating ?  "Wait" : "Update"}
      confirmColor={updating ? "neutral" : "warning"}
      description={updating ? "Waiting for update to complete. Application will reload once it is ready." : "A important update is available, it is recommended to update right now."}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
    />
  );
}