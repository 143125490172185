import * as ActionTypes from "../actions/actionTypes";
import { setAffiliateFromQuery } from "../common/AffiliateHandler";

const currentLocation = new URL(window.location.href);
const isEmbed = inIframe() || currentLocation.searchParams.has("embed");
const showEmbedDescription = isEmbed && currentLocation.searchParams.has("description");

setAffiliateFromQuery(currentLocation);

const initialState = {
  serviceWorkerInitialized: false,
  serviceWorkerUpdated: false,
  serviceWorkerRegistration: null,
  showEmbedDescription: showEmbedDescription,
  contextCardState: isEmbed ? 0 : 1,
  nodeViewTabIndex: currentLocation.searchParams.get("tab") === "1" ? 1 : 0,
  searchViewTabIndex: -1,
  cardLimits: [0, 0],
  hasCardReport: false,
  cardSwipeActive: false,
  cardApproachingFullscreen: false,
  notesFullscreen: false,
  confirmDialogHandles: [],
  canSwipeUpOnContent: false,
  canSwipeDownOnContent: false,
  dragOverActive: false,
  appTour: false,
  appTourIndex: 0,
  minimumApiVersionBreached: false,
  hideUpgradeAlert: false
};

export default function mapReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    
    case ActionTypes.SET_CONTEXT_CARD: return { ...state, contextCardState: payload.cardState };

    case ActionTypes.SET_NODEVIEW_TAB_INDEX: return { ...state, nodeViewTabIndex: payload.index };
    
    case ActionTypes.SET_SEARCHVIEW_TAB_INDEX: return { ...state, searchViewTabIndex: payload.index };

    case ActionTypes.REPORT_CARD_POSITION: 
      return { ...state, cardLimits: [ payload.x, payload.y ] , hasCardReport: true };

    case ActionTypes.SET_CARD_SWIPE_ACTIVE: return { ...state, cardSwipeActive: payload.active, cardApproachingFullscreen: (payload.active && state.cardApproachingFullscreen) || state.contextCardState === 3 };

    case ActionTypes.SET_CARD_APPROACHING_FULLSCREEN: return { ...state, cardApproachingFullscreen: payload.active };

    case ActionTypes.SET_CAN_SWIPE_ON_CONTENT: return { ...state, canSwipeDownOnContent: payload.canSwipeDown, canSwipeUpOnContent: payload.canSwipeUp };

    case ActionTypes.SET_NOTES_FULLSCREEN: return { ...state, notesFullscreen: payload.fullscreen, nodeViewTabIndex: 1 };

    case ActionTypes.SET_MINIMUM_API_VERSION_BREACHED: return { ...state, minimumApiVersionBreached: payload.breached };

    case ActionTypes.HIDE_UPGRADE_ALERT: return { ...state, hideUpgradeAlert: true };

    // Drag
    case ActionTypes.SET_DRAGOVER_ACTIVE:
      return { ...state, dragOverActive: payload.active };

    // Confirm dialogs
    case ActionTypes.ADD_CONFIRM_DIALOG: 
      return { ...state, confirmDialogHandles: state.confirmDialogHandles.concat([payload]) };
    
    case ActionTypes.CONFIRM_DIALOG_CONFIRM: 
    case ActionTypes.CONFIRM_DIALOG_CANCEL:
      return { ...state, confirmDialogHandles: state.confirmDialogHandles.filter((t,i) => i !== 0) };

    // Tours
    case ActionTypes.SET_TOURS: 
    case ActionTypes.AUTH_ONBOARDING_FINISH:
      return { 
        ...state, 
        appTour: payload.active === undefined ? true : payload.active, 
        appTourIndex: 0 
      };

    case ActionTypes.SET_APP_TOUR:
      return { ...state, appTour: payload.active };

    case ActionTypes.SET_APP_TOUR_INDEX:
      return { ...state, appTourIndex: payload.index };

    // Service Worker
    case ActionTypes.WORKER_INIT: return { ...state, serviceWorkerInitialized: true };
    case ActionTypes.UPDATE_AVAILABLE: return { ...state, serviceWorkerUpdated: true, serviceWorkerRegistration: payload };

    default: return state;
  }
};

function inIframe () {
  try {
      return window.self !== window.top;
  } catch (e) {
      return true;
  }
}