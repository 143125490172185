import * as ActionTypes from "../actions/actionTypes";

const initialState = {
  snackbarMessage: "",
  action: null,
  actionMessage: null,
  lastMessage: 0
};

export default function mapReducer(state = initialState, action) {


  switch (action.type) {

    case ActionTypes.COPY_TO_CLIPBOARD_SUCCESS: return { ...state, lastMessage: Date.now(), snackbarMessage: "Copied to clipboard!", action: null };
    case ActionTypes.COPY_TO_CLIPBOARD_ERROR: return { ...state, lastMessage: Date.now(), snackbarMessage: "Unable to access clipboard!", action: null };
    case ActionTypes.AUTH_RESET_PASSWORD_FINISH: return { ...state, lastMessage: Date.now(), snackbarMessage: "Sent password reset Email!", action: null };
    case ActionTypes.AUTH_RESET_PASSWORD_ERROR: return { ...state, lastMessage: Date.now(), snackbarMessage: "Unable to reset password!", action: null };

    case ActionTypes.AUTH_SUBSCRIBE_PREMIUM_FINISH: return { ...state, lastMessage: Date.now(), snackbarMessage: "Created subscription!", action: null };
    case ActionTypes.AUTH_UPDATE_PREMIUM_FINISH: return { ...state, lastMessage: Date.now(), snackbarMessage: "Updated subscription!", action: null };

    case ActionTypes.NOTIFICATION_DISPLAY:
      const isText = typeof action.payload.message === "string";
      return {
        ...state,
        lastMessage: Date.now(),
        snackbarMessage: isText ? action.payload.message : action.payload.message.text,
        action: isText ? null : action.payload.message.action,
        actionMessage: isText ? null : action.payload.message.actionText
      };

    default: return state;
  }
};
