import { useEffect, useRef } from "react";
import ReactDOM from "react-dom";

const events = new Map();

export const ConditionalPortal = (props) => {
  const { active, onClose, portalId } = props;
  const el = useRef(document.createElement("div"));
  const id = portalId || "conditional-portal";

  useEffect(() => {
    if (active) {
      const element = el.current;
      const portal = document.getElementById(id);
      portal.appendChild(element);
      return () => {
        portal.removeChild(element);
      };
    }
  }, [el, active, id]);

  useEffect(() => {
    if (typeof onClose === "function") {
      events.set(id, onClose);
      return () => {
        events.delete(id);
      }
    }
  }, [id, onClose]);

  return active ? ReactDOM.createPortal(props.children, el.current) : props.children;
}

export const ConditionalPortalView = (props) => {
  const id = props.portalId || "conditional-portal";

  useEffect(() => {
    return () => {
      const fn = events.get(id);
      if (typeof fn === "function") {
        events.delete(id);
        fn();
      }
    }
  }, [id])

  return <div id={id}></div>;
};