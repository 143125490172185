
export default function Form(theme) {
  return {
    MuiFormControlLabel: {
      defaultProps: {
        disableTypography: true,
        sx: {
          typography: "body2",
        }
      }
    }
  };
}
