import { Grid, Typography, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { MAX_WIDTH, SECTION_SPACING, SECTION_SPACING_2X, WIDTH } from "./constants";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { RequestSignup } from "../../../../actions/auth";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: SECTION_SPACING,
    marginBottom: SECTION_SPACING_2X,
    height: "400px",
    maxWidth: MAX_WIDTH,
    width: WIDTH,
  },
  header: {
    position: "relative",
    marginBottom: theme.spacing(0.5),
    maxWidth: "458px"
  },
  description: {
    maxWidth: "458px",
    marginBottom: theme.spacing(2)
  },
  link: {
    textDecoration: "none"
  },
  button: {
    height: "48px"
  }
}));

export default function TryTodaySection(props) {
  const { title, description } = props;
  const classes = useStyles();
  const dispatch = useDispatch();


  const onClick = useCallback(() => {
    dispatch(RequestSignup());
    if (!!window.fathom) {
      window.fathom.trackGoal("WIEUXHEM", 0);
    }
  }, [dispatch]);

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center" className={classes.root} spacing={3}>

      <Grid item container xs={12} direction="column" alignItems="center">
        <Typography variant="p_header" className={classes.header} align="center">{title}</Typography>
        <Typography variant="p_subtitle" className={classes.description} color="textSecondary" align="center">{description}</Typography>
        <Link to="/app" className={classes.link}>
          <Button color="primary" variant="contained" onClick={onClick} size="large" className={classes.button}>Use Emberly for free</Button>
        </Link>
      </Grid>

    </Grid>
  );
}
