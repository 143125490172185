import * as ActionTypes from "../actions/actionTypes";

const initialState = {
  filterMinRating: 0,
  filterSortBy: "date",
  filterResourceType: "any",
  filterArchived: "visible",
  filterTags: [],
  filterActive: false
};

export default function mapReducer(state = initialState, action) {
  const { payload, type } = action;
  
  switch (type) {

    // Filter
    case ActionTypes.SET_FILTER:
      return { ...state, filterMinRating: payload.minRating, filterSortBy: payload.sortBy, filterResourceType: payload.resourceType, filterTags: payload.tags, filterArchived: payload.archived, filterActive: (payload.minRating !== 0 || payload.resourceType !== "any" || payload.tags.length !== 0 || payload.archived !== "visible" || payload.sortBy !== "date") };

    default: return state;
  }
};


