import { Button, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { SECTION_SPACING } from "./constants";
import { ArrowForward as ArrowForwardIcon, AutoAwesome as AutoAwesomeIcon, Quiz as QuizIcon, Edit as EditIcon, Forum as ForumIcon } from "@mui/icons-material";
import AICard from "./AICard";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: SECTION_SPACING,
    width: "100%",
    marginRight: 0,
    marginLeft: 0,
    background: "#0D0D0D",
    paddingBottom: SECTION_SPACING,
    paddingRight: theme.spacing(3)
  },
  header: {
    position: "relative",
    marginBottom: theme.spacing(0.5),
    marginTop: "120px",
    fontSize: "56px",
    background: "linear-gradient(90.0deg, #CC31A1 0%, #F6B7E4 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    textFillColor: "transparent",
    "@media (max-width: 400px)": {
      fontSize: "30px"
    },
    "& span:nth-of-type(1)": {
      color: "#FBC167"
    },
    "& span:nth-of-type(2)": {
      color: "#F25ADA"
    },
    "& span:nth-of-type(3)": {
      color: "#8EADFB"
    },
    "@media (max-width: 560px)": {
      marginTop: "40px"
    }
  },
  description: {
    color: theme.palette.grey[50],
    marginBottom: "72px",
    "@media (max-width: 400px)": {
      marginBottom: "32px",
    },
  },
  link: {
    color: "inherit",
    textDecoration: "none",
    marginTop: "64px",
    marginBottom: "40px",
    "@media (max-width: 560px)": {
      marginTop: "32px",
      marginBottom: "8px"
    }
  },
  learnMoreButton: {
    color: "#FFF",
    fontSize: "20px",
    fontWeight: "400"
  },
  starsLeft: {
    position: "absolute",
    left: "-84px",
    top: "-50%",
    width: "86px",
    height: "auto",
    "@media (max-width: 740px)": {
      display: "none"
    }
  },
  starsRight: {
    position: "absolute",
    right: "-48px",
    top: "-50%",
    width: "40px",
    height: "auto",
    "@media (max-width: 740px)": {
      display: "none"
    }
  },
  cardContainer: {
    maxWidth: "1044px",
  }
}));

export default function AINextSection(props) {
  const classes = useStyles();
  const { aiTitle, aiDescription } = props;

  return (
    <Grid container direction="row" justifyContent="center" alignItems="flex-start" className={classes.root} spacing={3}>

      <Grid item container xs={12} direction="column" alignItems="center">
        <Typography variant="p_header" className={classes.header} textAlign="center">
          {aiTitle}
          <img src="/assets/home/landing_page/ai_section/stars_left.svg" className={classes.starsLeft} alt="decorative stars" />
          <img src="/assets/home/landing_page/ai_section/stars_right.svg" className={classes.starsRight} alt="decorative stars" />
        </Typography>
        <Typography variant="p_subtitle" className={classes.description} textAlign="center">{aiDescription}</Typography>
      </Grid>

      <Grid item container xs={12} justifyContent="space-between" alignItems="space-between" spacing={4} className={classes.cardContainer}>

        <AICard
          title="AI generated mind-maps"
          description="Let the AI help you get started with making your maps. A great way to kickstart your creativity!"
          src="/assets/home/landing_page/ai_section/ai_generate_map.mp4"
          icon={<AutoAwesomeIcon />}
          isVideo
          xs={12}
          md={12}
        />

        <AICard
          title="Automatic quizzes"
          description="Learn faster with unique quizzes generated automatically from your notes."
          src="/assets/home/landing_page/ai_section/ai_quiz_half.png"
          icon={<QuizIcon />}
          xs={12}
          md={6}
        />

        <AICard
          title="AI writing assistant"
          description="Let our AI help you write, brainstorm, and summarize your notes. "
          src="/assets/home/landing_page/ai_section/ai_writer.png"
          icon={<EditIcon />}
          xs={12}
          md={6}
        />

        <AICard
          title="Discussion simulator"
          description="Solidify you knowledge by discussing any topic against a virtual debater."
          src="/assets/home/landing_page/ai_section/ai_discussion_sim.png"
          icon={<ForumIcon />}
          xs={12}
          md={12}
          narrowImage
        />

      </Grid>

      <Grid item container xs={12} direction="column" alignItems="center">
        <a href="https://blog.ember.ly/emberly-ai/" rel="noreferrer" target="_blank" className={classes.link}>
          <Button
            className={classes.learnMoreButton}
            endIcon={<ArrowForwardIcon fontSize="inherit" />}
            size="large"
            fontSize="large"
          >
            Learn more about the AI-benefits
          </Button>
        </a>
      </Grid>
    </Grid>
  );
}
