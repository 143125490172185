import axios from "axios";
import { Entity, Retry } from "@emberly/rtac";

export default class NodeEntity extends Entity {

  constructor(eventEmitter, data) {
    super(eventEmitter, data);
    this.name = data.name || "";
    this.state = data.state || 0;
    this.rating = data.rating || 0;
    this.depth = data.depth || 0;
    this.side = data.side || 0;
    this.color = typeof data.color === "number" && data.color >= 0 ? data.color : -1;
    this.isCollapsed = data.isCollapsed || false;
    this.path = data.path || [];

    this.lastModified = data.lastModified || new Date().toISOString();
    this.created = data.created || new Date().toISOString();

    // TODO
    this._lastModified = new Date(this.lastModified);
  }

  hasParent() {
    return !this.isRoot;
  }

  getParentId() {
    return this.parentId || null;
  }

  getData() {
    return {
      id: this.id,
      parentId: this.parentId,
      name: this.name,
      state: this.state,
      rating: this.rating,
      index: this.index,
      depth: this.depth,
      side: this.side,
      color: this.color,
      isCollapsed: this.isCollapsed,
      path: this.path
    };
  }

  get isRoot() {
    return this.parentId === null;
  }

  setName(val, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    if (this.name !== val) {
      this.name = val;
      if (sync) {
        this.update("updated", { sync, metadata, instanceId, historyGroup });
      }
    }
  }

  setLearningState(state, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    if (state === 0) {
      this.setState(this.state & 0b11111100, { sync, metadata, instanceId, historyGroup });
    } else if (state === 1) {
      this.setState((this.state & 0b11111101) | 0b1, { sync, metadata, instanceId, historyGroup });
    } else if (state === 2) {
      this.setState((this.state & 0b11111110) | 0b10, { sync, metadata, instanceId, historyGroup });
    }
  }

  getLearningState() {
    return this.state & 0b11;
  }

  setHasResources(hasResources, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    const val = hasResources ? (this.state | 0b00000100) : (this.state & 0b11111011);
    if (this.state !== val) {
      this.state = val;
      if (sync) {
        this.eventEmitter.emit("updated", this, { sync: false, metadata, instanceId }); // to prevent this being added to history
      }
    }
  }

  setHasNotes(hasNotes, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    const val = hasNotes ? (this.state | 0b00001000) : (this.state & 0b11110111);
    if (this.state !== val) {
      this.state = val;
      if (sync) {
        this.eventEmitter.emit("updated", this, { sync: false, metadata, instanceId }); // to prevent this being added to history
      }
    }
  }

  hasNotes() {
    return !!(this.state & 0b1000);
  }

  hasResources() {
    return !!(this.state & 0b100);
  }

  setState(val, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    if (this.state !== val) {
      this.state = val;
      if (sync) {
        this.update("updated", { sync, metadata, instanceId, historyGroup });
      }
    }
  }

  setRating(val, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    if (this.rating !== val) {
      this.rating = val;
      if (sync) {
        this.update("updated", { sync, metadata, instanceId, historyGroup });
      }
    }
  }

  setDepth(val, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    if (this.depth !== val) {
      this.depth = !this.isRoot ? Math.max(1, val) : 0;
      if (sync) {
        this.update("updated", { sync, metadata, instanceId, historyGroup });
      }
    }
  }

  setSide(val, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    if (this.side !== val) {
      this.side = val;
      if (sync) {
        this.update("updated", { sync, metadata, instanceId, historyGroup });
      }
    }
  }

  setColor(val, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    if (this.color !== val) {
      this.color = val;
      if (sync) {
        this.update("updated", { sync, metadata, instanceId, historyGroup });
      }
    }
  }


  unsetColor({ sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    if (this.color !== null || this.color >= 0) {
      this.color = -1;
      if (sync) {
        this.update("updated", { sync, metadata, instanceId, historyGroup });
      }
    } 
  }

  get rgbColor() {
    return this.color !== null && this.color >= 0 ? this.color : null;
  }

  get hexColor() {
    return NodeEntity.Signed24BitToColor(this.color);
  }

  setIsCollapsed(val, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    if (this.isCollapsed !== val) {
      this.isCollapsed = val;
      if (sync) {
        this.update("updated", { sync, metadata, instanceId, historyGroup });
      }
    }
  }

  setPath(val, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    if (this.path !== val) {
      this.path = val;
      if (sync) {
        this.update("updated", { sync, metadata, instanceId, historyGroup });
      }
    }
  }

  deleteBranch({ sync = true, metadata = null, instanceId = null, historyGroup = null, filter = () => true } = {}) {
    this._deleted = true;
    this.update("deleteBranch", { sync, metadata, instanceId, historyGroup, filter });
  }

  // custom methods for root
  setCustomImage(url) {

    if (url === null) {
      if (this.name !== "root") {
        this.name = "root";
        this.update("updated", { sync: false });
      }
    } else {
      const val = `IMG://${url}`;

      if (val !== this.name) {
        this.name = val;
        this.update("updated", { sync: false });
      }
    }
  }

  setCustomText(text) {
    const val = `TXT://${text}`;
    if (this.name !== val) {
      this.name = val;
      this.update("updated", { sync: false });
    }
  }

  updatePath() {
    this.update("updatePath", this);
  }

  isFullTree() {
    return this.isRoot && this.side === 0;
  }

  hasCustomText() {
    return this.name.startsWith("TXT://");
  }

  hasCustomImage() {
    return this.name.startsWith("IMG://");
  }

  customTextValue() {
    if (this.hasCustomText()) {
      return this.name.substr(6);
    } else {
      return this.name;
    }
  }

  customImageValue() {
    if (this.hasCustomImage()) {
      return this.name.substr(6);
    } else {
      return this.name;
    }
  }

  setFullTree(val) {
    if (this.isRoot) {
      const side = val ? 0 : 1;
      if (this.side !== side) {
        this.side = side;
        this.update("updated", { sync: false });
      }
    }
  }

  async uploadCenterImage(contextId, imageData) {
    try {
      const { data } = await Retry.Axios(async () => await axios.post(`/api/map/${contextId}/upload/image/center`, {
        data: imageData
      }));
      this.setCustomImage(data.url);
      return data.url;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static Signed24BitToColor(n) {
    let c = (n & 0x00FFFFFF).toString(16).toUpperCase();
    return "#" + "00000".substring(0, 6 - c.length) + c;
  }
}