import { Entity } from "@emberly/rtac";

export default class PluginEntity extends Entity {

  constructor(eventEmitter, data) {
    super(eventEmitter, data);
    this.name = data.name || "New Project";
    this.description = data.description || "";
    this.lastEdited = new Date(data.lastModified || Date.now());
    this.created = new Date(data.created || Date.now());
    this.coverImage = null;
  }

  setName(val, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    if (this.name !== val) {
      this.name = val;
      if (sync) {
        this.update("updated", { sync, metadata, instanceId, historyGroup });
      }
      return true;
    }
    return false;
  }

  setDescription(val, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    if (this.description !== val) {
      this.description = val;
      if (sync) {
        this.update("updated", { sync, metadata, instanceId, historyGroup });
      }
      return true;
    }
    return false;
  }


  getData() {
    return {
      id: this.id,
      type: this.type,
      name: this.name,
      description: this.description,
      index: this.index
    };
  }

  delete(data) {
    this.lastEdited = new Date();
    super.delete(data)
  }

  restore(data) {
    this.lastEdited = new Date();
    super.restore(data)
  }

  getTimeSinceLastEdit() { // TODO
    return this.renderDeltaTime(Date.now() - this.lastEdited.getTime());
  }

  getTimeSinceLastOpen() {
    return this.renderDeltaTime(Date.now() - this.lastOpened.getTime());
  }

  getTimeUntilDelete() {
    const t = 1000 * 60 * 60 * 24 * 3.05;
    const delta = (Date.now() - this.lastEdited.getTime()) - t;
    return delta < -1000 * 60 * 15 ? `in ${this.renderDeltaTime(delta)}` : "now";
  }


  getCoverImage(error) {
    if (error || !this.coverImage) {
      return "/assets/map_empty_state.svg";
    }
    return this.coverImage;
  }


}
