import * as ActionTypes from "./actionTypes";

export function SetContextCard(cardState) {
  return {
    type: ActionTypes.SET_CONTEXT_CARD,
    payload: { cardState }
  };
}

export function SetNodeViewTabIndex(index) {
  return { 
    type: ActionTypes.SET_NODEVIEW_TAB_INDEX,
    payload: {
      index
    }
  };
}

export function SetSearchViewTabIndex(index) {
  return { 
    type: ActionTypes.SET_SEARCHVIEW_TAB_INDEX,
    payload: {
      index
    }
  };
}

export function ReportCardPosition(x, y) {
  return {
    type: ActionTypes.REPORT_CARD_POSITION,
    payload: {
      x,
      y
    }
  };
}

export function SetCardSwipeActive(active) {
  return {
    type: ActionTypes.SET_CARD_SWIPE_ACTIVE,
    payload: {
      active
    }
  };
}

export function SetCanSwipeOnContent(canSwipeUp, canSwipeDown) {
  return {
    type: ActionTypes.SET_CAN_SWIPE_ON_CONTENT,
    payload: {
      canSwipeUp,
      canSwipeDown
    }
  };
}

export function SetCardApproachingFullscreen(active) {
  return {
    type: ActionTypes.SET_CARD_APPROACHING_FULLSCREEN,
    payload: {
      active
    }
  };
}

export function SetNotesFullscreen(fullscreen) {
  return {
    type: ActionTypes.SET_NOTES_FULLSCREEN,
    payload: { fullscreen }
  };
}

export function CopyToClipboard(value) {
  return async (dispatch) => {
    try {
      await navigator.clipboard.writeText(value);

      dispatch({
        type: ActionTypes.COPY_TO_CLIPBOARD_SUCCESS,
        payload: { value }
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.COPY_TO_CLIPBOARD_ERROR,
        payload: { value }
      });
    }
  };
}


export function MinimumApiVersionBreached(breached = true) {
  return {
    type: ActionTypes.SET_MINIMUM_API_VERSION_BREACHED,
    payload: {
      breached
    }
  };
}

export function DisplayNotification(message) {
  return {
    type: ActionTypes.NOTIFICATION_DISPLAY,
    payload: { message }
  };
}

export function AddConfirmDialog(title, description, confirmText, color, onCancel, onConfirm, content = null) {
  return {
    type: ActionTypes.ADD_CONFIRM_DIALOG,
    payload: {
      title, 
      description,
      confirmText,
      color,
      onCancel,
      onConfirm,
      content
    }
  };
}

export function ConfirmDialogConfirm() {
  return {
    type: ActionTypes.CONFIRM_DIALOG_CONFIRM,
    payload: {}
  }
}

export function ConfirmDialogCancel() {
  return {
    type: ActionTypes.CONFIRM_DIALOG_CANCEL,
    payload: {}
  }
}

export function SetDragActive(active) {
  return {
    type: ActionTypes.SET_DRAGOVER_ACTIVE,
    payload: { active }
  };
}

export function SetTours(active = true) {
  return {
    type: ActionTypes.SET_TOURS,
    payload: { active }
  };
}

export function SetAppTour(active = false) {
  return {
    type: ActionTypes.SET_APP_TOUR,
    payload: { active }
  }
}

export function SetAppTourIndex(index = false) {
  return {
    type: ActionTypes.SET_APP_TOUR_INDEX,
    payload: { index }
  }
}

export function HideUpgradeAlert() {
  return {
    type: ActionTypes.HIDE_UPGRADE_ALERT,
    payload: {}
  };
}