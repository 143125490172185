import { useState, useEffect, useCallback } from "react";
import { Typography, Grid, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useRTACAuth } from "@emberly/dataplane";
import axios from "axios";

const useStyles = makeStyles(theme => ({
  root: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 999999,
    background: "#F5F7F6"
  },
  logout: {
    cursor: "pointer"
  },
  description: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  grid: {
    maxWidth: "386px",
    marginBottom: theme.spacing(4),
    padding: theme.spacing(2)
  },
  activationSuccess: {
    color: theme.palette.success.main
  },
  activationError: {
    color: theme.palette.common.heart
  },
  link: {
    textDecoration: "none"
  }
}));


export default function FallbackPanel(props) {
  const { hasError } = props;
  const classes = useStyles();
  const { logout } = useRTACAuth();
  const [resentActivationLink, setResentActivationLink] = useState(false);
  const [activationError, setActivationError] = useState(false);
  const [activationDialogOpen, setActivationDialogOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [showDevEnv, setShowDevEnv] = useState(false);

  useEffect(() => {
    try {
      if (window.location.hostname.includes("myskilltree.com") && window.sessionStorage.getItem("enable-test-env") !== "true") {
        setShowDevEnv(true);
      }
    } catch (err) {
      console.log("unable to access session storage");
    }
  }, [showDevEnv])

  const handleClose = useCallback(() => setActivationDialogOpen(false), [setActivationDialogOpen]);
  const handleOpen = useCallback(() => setActivationDialogOpen(true), [setActivationDialogOpen]);
  const onEmailChange = useCallback(ev => setEmail(ev.target.value), [setEmail]);

  const handleDevEnvClose = useCallback(() => {
    setShowDevEnv(false);
    try {
      window.sessionStorage.setItem("enable-test-env", "true");
    } catch (err) {
      console.log("unable to access session storage");
    }
  }, [setShowDevEnv]);

  const onLogout = useCallback(() => {
    logout({
      returnTo: window.location.origin
    });
  }, [logout]);

  const onLogin = useCallback(() => {
    window.history.replaceState({}, document.title, window.location.pathname);
    window.history.go();
  }, []);

  const onResendActivation = useCallback(() => {

    const fn = async () => {
      try {
        await axios.post("/api/client/activation/resend", { email });
        setResentActivationLink(true);
        setActivationError(false);
      } catch (err) {
        setResentActivationLink(false);
        setActivationError(true);
      }

      handleClose();
    }

    fn();

  }, [setResentActivationLink, handleClose, email]);

  return hasError ? (
    <Grid container className={classes.root} alignItems="center" justifyContent="center">

      <Grid container alignItems="center" justifyContent="center" spacing={1} className={classes.grid}>
        <Grid item xs={12}>
          <Typography variant="h6" align="center">Email not verified</Typography>
          <Typography variant="body2" align="center" className={classes.description}>You need to verify your email by clicking the link in the email we sent you earlier. Remember to check your junk folder. </Typography>
        </Grid>

        <Grid item xs={12} container>
          <Button variant="contained" color="primary" fullWidth onClick={onLogin}>I just verified, now sign me in</Button>
        </Grid>

        <Grid item xs={12}>
          <Button variant="text" color="neutral" fullWidth onClick={handleOpen} className={`${activationError ? classes.activationError : (resentActivationLink ? classes.activationSuccess : "")}`}>
            {`${activationError ? "Unknown email, try again?" : (resentActivationLink ? "Verification email sent" : "Re-send activation email")}`}
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Button variant="text" color="neutral" fullWidth onClick={onLogout}>Log out</Button>
        </Grid>
      </Grid>
      <Dialog open={activationDialogOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Re-Send Verification email</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To re-send the verfification email, please enter your email address here. In case you don't find it, check the spam folder in case it got misplaced!
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
            onChange={onEmailChange}
            color="primary"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={onResendActivation}>
            Resend Verification Email
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  ) : (
    <>
      <Dialog open={showDevEnv} onClose={handleDevEnvClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Test Environment</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are now entering Emberly's test environment, where we test experimental builds.
            Beware, we can suddenly delete all your data. Happy testing!
            For any questions, contact us at <a href="mailto:hi@ember.ly">hi@ember.ly</a>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="neutral" onClick={handleDevEnvClose}>
            Open Test Environment
          </Button>
          <a href="https://ember.ly" className={classes.link}>
            <Button variant="contained" color="secondary">
              Take me to regular Emberly
            </Button>
          </a>
        </DialogActions>
      </Dialog>
    </>
  );
}
