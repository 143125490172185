
export default function Button(theme) {
  return {
    MuiButtonBase: {
      defaultProps: {
        disableTouchRipple: true,
        focusRipple: true
      },
      styleOverrides: {
        root: {
          "&:focus": {
            //border: "2px solid blue !important"
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
        },
        sizeLarge: {
          borderRadius: "4px",
          height: "40px",
          fontSize: "16px",
          lineHeight: "26px",
          letterSpacing: "0.46px",
          fontWeight: "500"
        },
        sizeMedium: {
          borderRadius: "4px",
          height: "36px",
          fontSize: "14px",
          lineHeight: "24px",
          letterSpacing: "0.2px",
          fontWeight: "500"
        },
        sizeSmall: {
          borderRadius: "4px",
          height: "32px",
          fontSize: "12px",
          lineHeight: "22px",
          letterSpacing: "0.2px",
          fontWeight: "500"
        },
        contained: {
          boxShadow: "none",
          "&:hover, &:focus": {
            boxShadow: "none"
          }
        },
        containedInherit: {
          boxShadow: "none"
        },
        containedPrimary: {
          boxShadow: "none"
        },
        containedSecondary: {
          boxShadow: "none"
        },
        containedNeutral: {
          fontWeight: "400 !important",
          color: theme.palette.neutral.contrastText,
          background: theme.palette.neutral.main
        },
        textNeutral: {
          fontWeight: "400 !important",
          color: theme.palette.neutral.contrastText,
          "&:hover": {
            backgroundColor: theme.palette.action.hover
          }
        },
        outlinedNeutral: {
          fontWeight: "400 !important",
          borderColor: theme.palette.divider,
          color: theme.palette.neutral.contrastText,
          "&:hover": theme.palette.mode === "dark" ? undefined : {
            backgroundColor: theme.palette.neutral.main,
            borderColor: theme.palette.divider
          }
        }

      }
    }
  };
}
