import React, { useMemo, createContext, useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { CssBaseline } from "@mui/material";
import { ThemeProvider, createTheme, StyledEngineProvider } from "@mui/material/styles";
import shape from "./shape";
import lightModePalette from "./lightModePalette";
import darkModePalette from "./darkModePalette";
import typography from "./typography";
import componentsOverride from "./overrides";
import lightModeShadows, { customShadows as lightModeCustomShadows, inverseShadows as lightModeInverseShadows } from "./lightModeShadows";
import darkModeShadows, { customShadows as darkModeCustomShadows, inverseShadows as darkModeInverseShadows } from "./darkModeShadows";
import breakpoints from "./breakpoints";

ThemeConfig.propTypes = {
  children: PropTypes.node
};

export const ThemeConfigContext = createContext();

export const useThemeConfig = () => useContext(ThemeConfigContext);

export default function ThemeConfig({ children, mode: initialMode, events }) {
  const [themeMode, setThemeMode] = useState(initialMode || "light");

  useEffect(() => {
    if (!!events) {
      events.on("themeMode", (mode) => setThemeMode(mode || "light"));
    }
  }, [events]);

  const themeOptions = useMemo(
    () => {
      const mode = (themeMode === "system" ? (
        window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? "dark" : "light"
      ) : themeMode) || "light";

      return {
        palette: mode === "dark" ? darkModePalette : lightModePalette,
        shape,
        typography,
        shadows: mode === "dark" ? darkModeShadows : lightModeShadows,
        customShadows: mode === "dark" ? darkModeCustomShadows : lightModeCustomShadows,
        inverseShadows: mode === "dark" ? darkModeInverseShadows : lightModeInverseShadows,
        breakpoints
      };
    },
    [themeMode]
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <ThemeConfigContext.Provider value={{
      setThemeMode,
      themeMode
    }}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline enableColorScheme />
          {children}
        </ThemeProvider>
      </StyledEngineProvider>
    </ThemeConfigContext.Provider>
  );
}
