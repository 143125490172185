import axios from "axios";
import { Entity, Retry, Collection, Task } from "@emberly/rtac";
import ResourceEntity from "./ResourceEntity";

export default class ResourceCollection extends Collection {
  constructor(context, contextId) { // TODO remove templates if any blocks are occupied.
    super(context, "Resource", contextId);

    this.tagsMap = new Map();
    this.tagsLoaded = false;
    this.tagsTask = null;
    this.uploadingEntities = new Set();

    this.uploadedSize = 0;
    this.uploadedSizeLoaded = false;
    this.uploadedSizeTask = null;

    this.map = null;

    this.getContext().getCollectionInContext("Map", "default").then(t => {
      this.map = t;
    });

    // custom listeners
    this.entityEvents
      .on("uploadRequested", (resource) => {
        this.registerPendingHandle(resource.id, (handle) => resource.onHandleReceived(handle));
        this.uploadingEntities.add(resource.id);
      })
      .on("uploadProgress", (resource) => {
        //console.log("upload progress", resource.name, resource._progress, resource.fileSize);
        this.emitParentEvent(resource.parentId, resource);
      })
      .on("uploadFinished", (resource) => {
        this.uploadingEntities.delete(resource.id);
      })
      .on("archived", (resource) => {
        this.loadParent(resource.parentId).then((success) => {
          if (success) {
            resource.placeAtEnd();
            this.updateTreeNode(resource.parentId).then(() => {
              this.unloadParent(resource.parentId);
            });
          }
        });
      })
      .on("updated", (resource) => {
        if (resource.parentId !== "inbox") {
          this.map?.addModifiedNode(this.contextId, resource.parentId);
        }
      })
      .on("deleted", (resource) => {
        this.updateTreeNode(resource.parentId);
      })
      .on("created", (resource) => {
        this.updateTreeNode(resource.parentId);
      })
      .on("moved", (resource, oldParentId) => {
        if (resource.parentId !== oldParentId && !!oldParentId) {
          this.updateTreeNode(resource.parentId, true);
          this.updateTreeNode(oldParentId);
        }
      })
      .on("movedToContext", (resource) => {
        // TODO patch tags and uploadedSize, unmount, etc. this event to be sent after update. remember to prevent conflict between unmounted object and the diff that is to be created.
        // transmit global deleted event. see below.
        this.diffLoadedStatsOnCreated(resource);
        this.updateTreeNode(resource.parentId, true);
      })
      .on("movedFromContext", (resource) => {
        // TODO patch tags and uploadedSize, unmount, etc. this event to be sent after update. remember to prevent conflict between unmounted object and the diff that is to be created.
        // transmit global deleted event. see below.
        this.diffLoadedStatsOnDeleted(resource);
        this.updateTreeNode(resource.parentId);
      });

    this
      .onGlobalEvent("updated", (resource) => this.diffLoadedStatsOnUpdated(resource))
      .onGlobalEvent("created", (resource) => this.diffLoadedStatsOnCreated(resource))
      .onGlobalEvent("deleted", (resource) => this.diffLoadedStatsOnDeleted(resource));
    // TODO need to diff tags and uploadSize on received events too!
  }


  hasUpdates() {
    return this.uploadingEntities.size !== 0 || super.hasUpdates();
  }

  diffLoadedStatsOnDeleted(resource) { // TODO debug
    if (resource) {

      if (this.tagsLoaded && resource.tags?.length > 0) {
        resource.tags?.forEach(t => this.removeTag(t));
        this.emitTagsEvent();
      }

      if (this.uploadedSizeLoaded && resource?.filesize > 0) {
        this.uploadedSize -= resource.fileSize;
        this.emitUploadedSizeEvent();
      }
    }
  }

  diffLoadedStatsOnCreated(resource) {
    if (resource) {
      if (this.tagsLoaded && resource.tags?.length > 0) {
        resource.tags?.forEach(t => this.addTag(t));
        this.emitTagsEvent();
      }

      if (this.uploadedSizeLoaded && resource.fileSize > 0) {
        this.uploadedSize += resource.fileSize;
        this.emitUploadedSizeEvent();
      }
    }
  }

  diffLoadedStatsOnUpdated(resource) {
    if (resource) {
      const checkpoint = this.tagsLoaded || this.uploadedSizeLoaded ? this.getServerState(resource.id) : null;

      if (this.tagsLoaded && checkpoint && (checkpoint.tags?.length > 0 || resource.tags?.length > 0)) {
        checkpoint.tags?.forEach(t => this.removeTag(t));
        resource.tags?.forEach(t => this.addTag(t));
        this.emitTagsEvent();
      }

      if (this.uploadedSizeLoaded && checkpoint && (resource.fileSize > 0 || checkpoint?.fileSize > 0)) {
        this.uploadedSize += resource.fileSize - checkpoint.fileSize;
        this.emitUploadedSizeEvent();
      }
    }
  }

  diffTags(commonTags, resourceTags) {
    if (!resourceTags) return commonTags;

    const buffer = [];

    for (let i = 0; i < resourceTags.length; i++) {
      const tag = resourceTags[i];
      if (commonTags.indexOf(tag) === -1) {
        buffer.push(tag);
      }
    }

    return buffer.length !== 0 ? commonTags.concat(buffer) : commonTags;
  }

  addTag(tag) {
    // add to tagmap
    if (this.tagsMap.has(tag)) {
      const count = this.tagsMap.get(tag) + 1;
      this.tagsMap.set(tag, count);
    } else {
      this.tagsMap.set(tag, 1);
    }
  }

  removeTag(tag) {
    // remove from tagmap
    if (this.tagsMap.has(tag)) {
      const count = this.tagsMap.get(tag) - 1;
      if (count <= 0) {
        this.tagsMap.delete(tag);
      } else {
        this.tagsMap.set(tag, count);
      }
    }
  }

  onUploadedSizeEvent(fn) {
    this.externalEvents.on(this.getUploadedSizeEventKey(), fn);
    return this;
  }

  offUploadedSizeEvent(fn) {
    this.externalEvents.off(this.getUploadedSizeEventKey(), fn);
    return this;
  }

  onTagsEvent(fn) {
    this.externalEvents.on(this.getTagsEventKey(), fn);
    return this;
  }

  offTagsEvent(fn) {
    this.externalEvents.off(this.getTagsEventKey(), fn);
    return this;
  }

  emitUploadedSizeEvent() {
    this.externalEvents.emit(this.getUploadedSizeEventKey());
  }

  emitTagsEvent() {
    this.externalEvents.emit(this.getTagsEventKey());
  }

  getUploadedSizeEventKey() {
    return "/Resource/uploadedsize";
  }

  getTagsEventKey() {
    return "/Resource/tags";
  }

  async loadUploadedSize() {
    if (this.uploadedSizeLoaded) {
      return true;
    } else if (this.uploadedSizeTask !== null) {
      await this.uploadedSizeTask.wait();
      return this.uploadedSizeLoaded;
    }

    this.uploadedSizeTask = new Task();
    const { data, success } = await this.fetchUploadedSizeState();

    if (success) {
      this.uploadedSize = data.size;
      this.uploadedSizeLoaded = true;
    }

    this.uploadedSizeTask.complete();

    return this.tagsLoaded;
  }

  getUploadedSize() {
    return this.uploadedSize;
  }

  async loadTags() {
    if (this.tagsLoaded) {
      return true;
    } else if (this.tagsTask !== null) {
      await this.tagsTask.wait();
      return this.tagsLoaded;
    }

    this.tagsTask = new Task();
    const { data, success } = await this.fetchTagsState();

    if (success) {
      data.tags.forEach(t => this.tagsMap.set(t.tag, t.count));
      this.tagsLoaded = true;
    }

    this.tagsTask.complete();

    return this.tagsLoaded;
  }

  getTags() {
    return [...this.tagsMap.entries()].sort((a, b) => b[1] - a[1]).map(t => t[0]);
  }

  async fetchUploadedSizeState() {
    // todo this one fetches state of loaded topics.
    try {
      const res = await Retry.Axios(async () => await axios(`/api/resource/${this.sharePrefix}${this.contextId}/uploadedsize`), 6);
      return { data: res.data, success: !!res.data };
    } catch (err) {
      console.log("error fetching tags", err, err ? err.status : null, err && err.response ? err.response.status : null);
      return { data: null, success: false };
    }
  }

  async fetchTagsState() {
    // todo this one fetches state of loaded topics.
    try {
      const res = await Retry.Axios(async () => await axios(`/api/resource/${this.sharePrefix}${this.contextId}/tags`), 6);
      return { data: res.data, success: !!res.data };
    } catch (err) {
      console.log("error fetching tags", err, err ? err.status : null, err && err.response ? err.response.status : null);
      return { data: null, success: false };
    }
  }


  async fetchEntityState(resourceId) {
    // todo this one fetches state of loaded topics.
    try {
      const res = await Retry.Axios(async () => await axios(`/api/resource/${this.sharePrefix}${this.contextId}/${resourceId}`), 6);
      return { data: res.data, success: !!res.data };
    } catch (err) {
      console.log("error fetching resources", err, err ? err.status : null, err && err.response ? err.response.status : null);
      return { data: null, success: false };
    }
  }

  async fetchParentState(parentId) {
    // todo this one fetches state of loaded topics.
    try {
      const res = await Retry.Axios(async () => await axios(`/api/resource/node/${this.sharePrefix}${this.contextId}/${parentId}`), 6);
      return { list: res.data.resources.sort((a, b) => Entity.Compare(a, b)), success: true };
    } catch (err) {
      console.log("error fetching resources", err, err ? err.status : null, err && err.response ? err.response.status : null);
      return { list: [], success: false };
    }
  }

  async fetchRemoteState() {
    // todo this one fetches state of loaded topics.
    try {
      const body = this.getRemoteStateQuery();

      if (body === null) {
        return { list: [], success: true };
      }

      const res = await Retry.Axios(async () => await axios.post(`/api/resource/${this.sharePrefix}${this.contextId}`, body), 6);

      return { list: res.data.resources.sort((a, b) => Entity.Compare(a, b)), success: true };
    } catch (err) {
      console.log("error fetching resources", err, err ? err.status : null, err && err.response ? err.response.status : null);
      return { list: [], success: false };
    }
  }

  async fetchEntitiesState(entities) {
    // todo this one fetches state of loaded topics.
    try {
      const body = { entities };
      const res = await Retry.Axios(async () => await axios.post(`/api/resource/${this.sharePrefix}${this.contextId}`, body), 6);

      return { list: res.data.resources.sort((a, b) => Entity.Compare(a, b)), success: true };
    } catch (err) {
      console.log("error fetching resources", err, err ? err.status : null, err && err.response ? err.response.status : null);
      return { list: [], success: false };
    }
  }

  async fetchSearchResult(query) {
    try {
      const res = await Retry.Axios(async () => await axios.post(`/api/resource/search/${this.sharePrefix}${this.contextId}`, query), 3);
      return { entities: res.data.resources, success: true };
    } catch (err) {
      console.log(err);
      return { entities: [], success: false };
    }
  }


  canFetchEntity() {
    return true;
  }

  canFetchEntities() {
    return true;
  }

  canFetchParent() {
    return true;
  }

  canSearch() {
    return true;
  }

  /// --- Processing Changes --- //

  onPatchEntity(data, isUndo = false) {
    const resource = this.getEntityById(data.id);
    // TODO we may have to skip using null to prevent patching.

    if (!resource) {
      return false;
    }

    if (data.name !== null && typeof data.name === "string") {
      resource.setName(data.name, { sync: false });
    }

    if (data.type !== null && typeof data.type === "string") {
      resource.setType(data.type, { sync: false });
    }

    if (data.description !== null && typeof data.description === "string") {
      resource.setDescription(data.description, { sync: false });
    }

    if (data.source !== null && typeof data.source === "string") {
      resource.setSource(data.source, { sync: false });
    }

    if (data.rating !== null && typeof data.rating === "number") {
      resource.setRating(data.rating, { sync: false });
    }

    if (data.url !== null && typeof data.url === "string") {
      resource.setUrl(data.url, { sync: false });
    }

    if (data.imageUrl !== null && typeof data.imageUrl === "string") {
      resource.setImageUrl(data.imageUrl, { sync: false });
    }

    if (data.tags !== null && data.tags !== undefined && typeof data.tags === "object" && typeof data.tags.length === "number") {
      resource.setTags(data.tags, { sync: false });
    }

    if (data.fileHandle !== null && typeof data.fileHandle === "string") {
      resource.setFileHandle(data.fileHandle, { sync: false });
    }

    if (data.fileSize !== null && typeof data.fileSize === "number") {
      resource.setFileSize(data.fileSize, { sync: false });
    }

    if (data.fileType !== null && typeof data.fileType === "string") {
      resource.setFileType(data.fileType, { sync: false });
    }

    if (data.incomplete !== null && typeof data.incomplete === "boolean") {
      resource.setIncomplete(data.incomplete, { sync: false });
    }

    if (data.archived !== null && typeof data.archived === "boolean") {
      resource.setArchived(data.archived, { sync: false });
    }

    if (data.index !== null && typeof data.index === "string") {
      resource.setIndex(data.index, { sync: false });
    }

    if (data.contextId !== null && typeof data.contextId === "string") {
      // remove from here, return false, transmit deleted event
      // if contextId is equal to this, we unload, and also unload any noteblocks with this parent, or else we simply patch.

      if (data.contextId !== this.contextId) { // TODO rename resourceId to contextId?
        // unmount the entity, send deleted event. return false; also unmount NoteBlocks etc, patch tags and uploadedSize.
        this.unloadEntityFromCache(resource);
        this.emitChangeEvents(resource, "deleted");

        resource._deleted = true;
        resource.contextId = data.contextId;
        resource.parentId = data.parentId;

        this.wipeEntity(resource);
        this.onMovedFromContext(resource);

        return false;
      }
    }

    if (data.parentId !== null && typeof data.parentId === "string") {
      resource.setParentId(data.parentId, { sync: false, refresh: true });
    }

    return true;
  }

  onMovedFromContext(entity, toHandler = null) {
    try {
      const parentId = `resource-${entity.id}`;
      const blockHandler = this.getHandler("Note");

      if (blockHandler.loadedParents.has(parentId)) {
        const blocks = blockHandler.getChildren(parentId);

        blocks.forEach(block => {
          blockHandler.unloadEntityFromCache(block);
          blockHandler.emitChangeEvents(block, "deleted");
          blockHandler.wipeEntity(block);
        });

        blockHandler.loadedParents.delete(parentId);
      }

      if (toHandler) {
        const blockContextHandler = toHandler.getHandler("Note");

        if (blockContextHandler.loadedParents.has(parentId)) {
          const blocks = blockContextHandler.getChildren(parentId);

          if (blocks.length === 0) {
            blockContextHandler.syncToRemote();
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  }


  async moveManyToContext(items, contextId, parentId, data) {

    const isUndo = !!data.isUndo;
    const resources = items.filter(t => !t.isWorking()).reverse();
    const length = resources.length;
    const targetContextId = contextId === "default" ? this.getContext().getDefaultContextId() : contextId;

    if (resources.length !== items.length) {
      this.notify("Can't move resources that are currently processing!");
    }

    if (length === 0) return false;

    const originParentId = resources[0].parentId;
    const originContextId = resources[0].contextId;

    try {
      const toHandler = await this.getContext().getCollectionInContext(this.resourceType, targetContextId, true); // todo this one needs to default to fetching maps?      
      if (!toHandler || !toHandler.canEdit()) return false;

      for (let i = 0; i < resources.length; i++) {
        await this.moveToContext(resources[i], contextId, parentId, data);
      }

      const originNodeName = originParentId === "inbox" ? "inbox" : this.getHandler("Node")?.getEntityById(originParentId)?.name;
      const targetNodeName = parentId === "inbox" ? "inbox" : toHandler.getHandler("Node")?.getEntityById(parentId)?.name;

      if (originNodeName && targetNodeName) {
        if (isUndo) {
          this.notify(`Moved ${length} Resource${length === 1 ? "" : "s"} back!`)
        } else {
          const undo = async () => {
            try {
              const fromHandler = await this.getContext().getCollectionInContext(this.resourceType, targetContextId, true);
              const ids = items.map(t => t.id);

              if (!!fromHandler && await fromHandler.loadEntities(ids)) {
                const entities = ids.map(id => fromHandler.getEntityById(id)).filter(t => !!t);
                await fromHandler.moveManyToContext(entities, originContextId, originParentId, { position: "end", isUndo: true });
                fromHandler.unloadEntities(ids);
              }

            } catch (err) {
              console.log(err);
            } finally {
              this.getContext().leaveGroupInContext(targetContextId);
            }
          };

          this.notify({ action: undo, text: `${length} Resource${length === 1 ? "" : "s"} moved from ${originNodeName} to ${targetNodeName}`, actionText: "UNDO" });
        }
      }

      return true;
    } catch (err) {

      return false;
    } finally {
      this.getContext().leaveGroupInContext(targetContextId);
    }
  }

  async moveMany(items, parentId, position) {
    const resources = items.filter(t => !t.isWorking()).reverse();
    const length = resources.length;

    if (resources.length !== items.length) {
      this.notify("Can't move resources that are currently processing!");
    }

    if (length === 0) return false;

    const originParentId = resources[0].parentId;
    resources.forEach(t => t.move(parentId, position));

    const positions = resources.map(t => t.index);
    const nodeHandler = this.getHandler("Node");
    const originNodeName = originParentId === "inbox" ? "inbox" : nodeHandler?.getEntityById(originParentId)?.name;
    const targetNodeName = parentId === "inbox" ? "inbox" : nodeHandler?.getEntityById(parentId)?.name;

    if (originNodeName && targetNodeName) {

      const undo = () => {
        resources.forEach((t, i) => t.move(originParentId, positions[i]));
        this.notify(`Moved ${length} resource${length === 1 ? "" : "s"} back!`);
      }

      this.notify({ action: undo, text: `${length} Resource${length === 1 ? "" : "s"} moved from ${originNodeName} to ${targetNodeName}`, actionText: "UNDO" });
    }

  }

  // Diff State //

  getUpdatedFields(oldEntity, newEntity) {
    return {
      parentId: oldEntity.parentId !== newEntity.parentId,
      contextId: oldEntity.contextId !== newEntity.contextId,
      type: oldEntity.type !== newEntity.type,
      index: oldEntity.index !== newEntity.index,
      name: oldEntity.name !== newEntity.name,
      description: oldEntity.description !== newEntity.description,
      source: oldEntity.source !== newEntity.source,
      rating: oldEntity.rating !== newEntity.rating,
      url: oldEntity.url !== newEntity.url,
      imageUrl: oldEntity.imageUrl !== newEntity.imageUrl,
      tags: !!oldEntity.tags && !!newEntity.tags && !(oldEntity.tags.length === newEntity.tags.length && oldEntity.tags.every((v, i) => v === newEntity.tags[i])),
      fileHandle: oldEntity.fileHandle !== newEntity.fileHandle,
      fileSize: oldEntity.fileSize !== newEntity.fileSize,
      fileType: oldEntity.fileType !== newEntity.fileType,
      incomplete: oldEntity.incomplete !== newEntity.incomplete,
      archived: oldEntity.archived !== newEntity.archived
    };
  }

  canPatch(updatedFields) {
    return (
      updatedFields.parentId ||
      updatedFields.contextId ||
      updatedFields.type ||
      updatedFields.index ||
      updatedFields.name ||
      updatedFields.description ||
      updatedFields.source ||
      updatedFields.rating ||
      updatedFields.url ||
      updatedFields.imageUrl ||
      updatedFields.tags ||
      updatedFields.fileHandle ||
      updatedFields.fileSize ||
      updatedFields.fileType ||
      updatedFields.incomplete ||
      updatedFields.archived
    );
  }

  onUndo(_, entity) {
    if (!!entity && !!entity.parentId) {
      this.updateTreeNode(entity.parentId);
    }
  }

  onRedo(_, entity) {
    if (!!entity && !!entity.parentId) {
      this.updateTreeNode(entity.parentId);
    }
  }

  async updateTreeNode(parentId, force = false) {
    if (this.getContext().isDefaultContext()) return;
    
    try {
      const tree = this.getHandler("Node");
      if (!!tree && parentId !== "inbox") {
        const loadedTree = await tree.loadEntity(parentId);
        const loadedFolder = force || await this.loadParent(parentId);
        
        if (loadedTree && loadedFolder) {
          const treeNode = tree.getEntityById(parentId);

          if (treeNode) {
            const hasResources = force || !!this.getChildren(parentId).find(t => !t.archived);
            treeNode.setHasResources(hasResources);
            this.map?.addModifiedNode(this.contextId, parentId);
          }
        }

        await tree.unloadEntity(parentId);

        if (!force) {
          await this.unloadParent(parentId);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  makeEntity(data) {
    return new ResourceEntity(this.entityEvents, data);
  }


  onBeforeCreatingEntity(entity) {
    entity.contextId = this.contextId;
  }
}