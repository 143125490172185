import { useCallback, useState, useRef, useMemo, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useDevice } from "../../providers/DeviceContext";

const useStyles = makeStyles({
  scrollbar: {
    position: "relative",
    height: "100%",
    overflowY: "visible",
    overflowX: "visible"
  },
  scrollbarLocked: {
    overflowY: "hidden"
  },
  scrollbarAreaDisabled: {
    position: "relative",
    minHeight: "auto",
    height: "100%",
    overflowY: "visible",
    overflowX: "visible"
  },
  scrollbarThumb: {
    position: "relative",
    display: "block",
    width: "100%",
    cursor: "pointer",
    borderRadius: "inherit",
    backgroundColor: "rgba(155, 155, 155, 0.418)",
    zIndex: 3
  },
  hideHorizontalBar: {
    display: "none"
  }
});

const disabledStyles = { overflowX: "visible", overflowY: "visible", width: "100%", height: "inherit" };

const visible = { overflow: "visible" };

const scrollStateMap = new Map();

export default function Scrollbar(props) {
  const { className, onScrollStateChange, scrollAreaDisabled, moveOffset, scrollStateId, loading, swipeEnabled } = props;
  const [isOnTop, setIsOnTop] = useState(false);
  const [isOnBottom, setIsOnBottom] = useState(false);
  const { isIos, isMobilePortraitView } = useDevice();
  const scrollbar = useRef(null);
  const classes = useStyles();

  const onUpdate = useCallback((ev) => {
    const top = ev.scrollTop <= 8;
    const bottom = ev.scrollHeight - ev.clientHeight - 4 <= ev.scrollTop;

    if (top !== isOnTop || bottom !== isOnBottom) {
      if (onScrollStateChange !== undefined) {
        onScrollStateChange(top, bottom);
      }
      setIsOnTop(top);
      setIsOnBottom(bottom);
    }

    if (!!scrollStateId && !loading) {
      scrollStateMap.set(scrollStateId, ev.scrollTop);
    }
  }, [onScrollStateChange, isOnTop, isOnBottom, setIsOnTop, setIsOnBottom, scrollStateId, loading]);


  useEffect(() => {
    if (!!moveOffset && moveOffset > 0 && !!scrollbar.current && scrollbar.current.view.scrollTop < moveOffset) {
      scrollbar.current.scrollToTop();
    }
  }, [moveOffset, scrollbar]);

  useEffect(() => {
    if (!!scrollStateId && !!scrollbar.current && !scrollAreaDisabled && !loading) {
      const position = scrollStateMap.has(scrollStateId) ? scrollStateMap.get(scrollStateId) : 0;
      scrollbar.current.scrollTop(position);

      const timeout = setTimeout(() => {
        if (!!scrollbar.current && !!scrollbar.current.scrollTop) {
          scrollbar.current.scrollTop(position);

          if (!!scrollbar.current) {
            const top = position <= 8;
            const bottom = scrollbar.current.getScrollHeight() - scrollbar.current.getClientHeight() - 4 <= position;

            if (onScrollStateChange !== undefined) {
              onScrollStateChange(top, bottom);
            }

            setIsOnTop(top);
            setIsOnBottom(bottom);
          }
        }
      }, 0);

      return () => clearTimeout(timeout);
    }
  }, [scrollStateId, scrollAreaDisabled, loading, onScrollStateChange, setIsOnBottom, setIsOnTop]);


  const renderView = useCallback(props =>
    scrollAreaDisabled ? (
      <div style={disabledStyles} />
    ) : (
      <div {...props}
        className="scrollv"
        style={{
          ...props.style,
          width: isMobilePortraitView ? "100%" : undefined,
          overflowX: "hidden",
          overflowY: "scroll",
          touchAction: !isMobilePortraitView || !swipeEnabled || isIos ? "auto" : (isOnTop && isOnBottom ? "auto" : (isOnTop ? "pan-down" : "auto"))
        }} 
      />
    ),
    [scrollAreaDisabled, isOnTop, isOnBottom, isIos, isMobilePortraitView, swipeEnabled]
  );

  

  const renderThumbVertical = useCallback(props => <div {...props} className={classes.scrollbarThumb} />, [classes]);
  const renderThumbHorizontal = useCallback(props => <div {...props} className={classes.hideHorizontalBar} />, [classes]);

  return useMemo(() => (
    <Scrollbars
      ref={scrollbar}
      className={`${scrollAreaDisabled ? classes.scrollbarAreaDisabled : classes.scrollbar} ${className !== undefined ? className : ""}`}
      style={scrollAreaDisabled ? visible : undefined}
      renderThumbVertical={renderThumbVertical}
      renderView={renderView}
      onUpdate={onUpdate}
      renderTrackHorizontal={renderThumbHorizontal}
      renderThumbHorizontal={renderThumbHorizontal}
      hideTracksWhenNotNeeded
      autoHide
    >
      {props.children}
    </Scrollbars>
  ), [classes, onUpdate, className, scrollbar, props.children, renderThumbVertical, renderThumbHorizontal, renderView, scrollAreaDisabled]);
}
