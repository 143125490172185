const AFFILIATE_ID_VALUE = "emberly_affiliate_id";
let affiliateId = null;


export function getAffiliateId() {
  try {
    return affiliateId || window.localStorage.getItem(AFFILIATE_ID_VALUE) || null;
  } catch (err) {
    console.log(err);
    return null;
  }
}

export function setAffiliateId(id, override = false) {
  try {
    if ((affiliateId === null || override) && !!id) {
      const local = getAffiliateId();

      if (local === null || override) { // perhaps override it?
        affiliateId = id;
        window.localStorage.setItem(AFFILIATE_ID_VALUE, id);
      } else {
        affiliateId = local;
      }
    }
  } catch (err) {
    console.log(err);
  }
}

export function setAffiliateFromQuery(url) {
  if (url.searchParams.has("eai")) {
    setAffiliateId(url.searchParams.get("eai"), true);
  }
}