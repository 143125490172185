import { Entity } from "@emberly/rtac"

export default class InviteEntity extends Entity {

  constructor(eventEmitter, data) {
    super(eventEmitter, data);
    this.email = data.email || "";
    this.rawEmail = data.rawEmail || data.email;
    this.senderUsername = data.username || data.senderUsername || "";
    this.senderPublicId = data.senderPublicId || "";
    this.ownerPublicId = data.ownerPublicId || "";
    this.senderEmail = data.senderEmail || "";
    this.accepted = data.accepted || false;
    this.declined = data.declined || false;
    this.contextName = data.contextName || "";
    this.contextId = data.contextId || "";
    this.description = data.description || "";
    this.access = data.access || 0;
    this.lastModified = data.lastModified || new Date().toISOString();
    this.created = data.created || new Date().toISOString();
  }

  setContextName(val, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    if (this.contextName !== val) {
      this.contextName = val;
      if (sync) {
        this.update("updated", { sync, metadata, instanceId, historyGroup });
      }
    }
  }

  setAccepted(val, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    if (this.accepted !== val) {
      this.accepted = val;
      this.lastModified = new Date().toISOString();
      if (sync) {
        this.update("updated", { sync, metadata, instanceId, historyGroup });
      }
    }
  }

  setDeclined(val, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    if (this.declined !== val) {
      this.declined = val;
      this.lastModified = new Date().toISOString();
      if (sync) {
        this.update("updated", { sync, metadata, instanceId, historyGroup });
      }
    }
  }

  setAccess(val, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    if (this.access !== val) {
      this.access = val;
      if (sync) {
        this.update("updated", { sync, metadata, instanceId, historyGroup });
      }
    }
  }

  getData() {
    return {
      id: this.id,
      username: this.senderUsername,
      contextName: this.contextName,
      ownerPublicId: this.ownerPublicId,
      contextId: this.contextId,
      email: this.email,
      accepted: this.accepted,
      declined: this.declined,
      description: this.description,
      access: this.access
    };
  }
}
