import { useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { MAX_WIDTH, SECTION_SPACING, WIDTH } from "./constants";
import { ArrowForward as ArrowForwardIcon } from "@mui/icons-material";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: SECTION_SPACING,
    marginBottom: SECTION_SPACING,
    maxWidth: MAX_WIDTH,
    width: WIDTH,
  },
  selectorItem: {
    "@media (max-width: 900px)": {
      flexDirection: "row"
    }
  },
  header: {
    marginBottom: theme.spacing(0.5)
  },
  title: {
    marginBottom: theme.spacing(1)
  },
  image: {
    width: "calc(100% - 32px)",
    height: "auto",
    boxShadow: theme.shadows[3],
    borderRadius: theme.shape.borderRadius,
    marginLeft: "32px",
    "@media (max-width: 900px)": {
      marginLeft: 0,
      marginTop: theme.spacing(3),
      width: "100%"
    }
  },
  useCaseButton: {
    fontWeight: "500 !important"
  },
  useCaseButtonSelected: {
    background: theme.palette.grey[500_16],
  },
  useCaseItem: {
    background: theme.palette.grey[500_8],
    borderRadius: theme.spacing(1.5),
    border: `1px solid ${theme.palette.divider}`,
    padding: "32px"
  },
  learnMoreButton: {
    marginTop: theme.spacing(3),
    color: theme.palette.text.primary
  },
  link: {
    color: "inherit"
  }
}));

export default function UseCaseSection(props) {
  const classes = useStyles();
  const { title, description, features } = props;
  const [selected, setSelected] = useState(0);
  const selectedFeature = features[selected];

  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" className={classes.root} spacing={3}>

      <Grid item container xs={12} direction="column">
        <Typography variant="p_header" className={classes.header}>{title}</Typography>
        <Typography variant="p_subtitle" color="textSecondary">{description}</Typography>
      </Grid>

      <Grid item container xs={12} md="auto" direction="column" className={classes.selectorItem} justifyContent="flex-start" alignItems="flex-start">
        {features.map((feature, i) => (
          <Grid item key={i} xs={false}>
            <Button className={`${classes.useCaseButton} ${i === selected ? classes.useCaseButtonSelected : ""}`} color="neutral" onClick={() => setSelected(i)}>
              {feature.name}
            </Button>
          </Grid>
        ))}
      </Grid>

      <Grid item container xs={12} md>
        <Grid item container xs={12} direction="row" className={classes.useCaseItem} justifyContent="space-between" alignItems="flex-start">
          <Grid item xs={12} md={4}>
            <Typography variant="h5" className={classes.title}>{selectedFeature.title}</Typography>
            <Typography variant="body1">{selectedFeature.description}</Typography>
            {
              !!selectedFeature.link ? (
                <a href={selectedFeature.link} className={classes.link}>
                  <Button endIcon={<ArrowForwardIcon />} className={classes.learnMoreButton}>
                    {selectedFeature.linkName || "Learn more"}
                  </Button>
                </a>
              ) : null
            }
          </Grid>

          <Grid item xs={12} md={8}>
            <img src={selectedFeature.image} alt={selectedFeature.title} className={classes.image} loading="lazy" />
          </Grid>

        </Grid>
      </Grid>

    </Grid>
  );
}
