import axios from "axios";
import { Collection, Retry } from "@emberly/rtac";
import NoteBlockEntity from "./NoteBlockEntity";

export default class NoteCollection extends Collection {

  constructor(context, contextId) {
    super(context, "Note", contextId);
    this.contextId = contextId;
    this.flushLimit = 10;
    this.map = null;

    this.getContext().getCollectionInContext("Map", "default").then(t => {
      this.map = t;
    });

    this.entityEvents
      .on("updated", (entity) => {
        this.handleEmptyNote(entity);
      })
      .on("deleted", (entity) => {
        this.handleEmptyNote(entity);
      })
      .on("created", (entity) => {
        this.handleEmptyNote(entity);
      });


    // TODO custom listener to update notelink and treeNode!
    // enable fetching single treenode so we can patch state.
  }

  async fetchParentState(parentId) {
    try {
      const res = await Retry.Axios(async () => await axios(`/api/noteblock/${this.sharePrefix}${this.contextId}/parent/${parentId}`), 6);
      return { list: res.data.blocks, success: true };
    } catch (err) {
      console.log("error fetching noteblocks", err, err ? err.status : null, err && err.response ? err.response.status : null);
      return { list: [], success: false };
    }
  }

  async fetchRemoteState() {
    try {
      const body = this.getRemoteStateQuery();

      if (body === null) {
        return { list: [], success: true };
      }

      const res = await Retry.Axios(async () => await axios.post(`/api/noteblock/${this.sharePrefix}${this.contextId}`, body), 6);
      return { list: res.data.blocks, success: true };
    } catch (err) {
      console.log("error fetching noteblocks", err, err ? err.status : null, err && err.response ? err.response.status : null);
      return { list: [], success: false };
    }
  }

  async fetchEntityState(entityId) {
    // todo this one fetches state of loaded topics.
    try {
      const res = await Retry.Axios(async () => await axios(`/api/noteblock/${this.sharePrefix}${this.contextId}/${entityId}`), 6);
      return { data: res.data, success: !!res.data };
    } catch (err) {
      console.log("error fetching resources", err, err ? err.status : null, err && err.response ? err.response.status : null);
      return { data: null, success: false };
    }
  }

  async fetchEntitiesState(entities) {
    try {
      const body = { entities };
      const res = await Retry.Axios(async () => await axios.post(`/api/noteblock/${this.sharePrefix}${this.contextId}`, body), 6);
      return { list: res.data.blocks, success: true };
    } catch (err) {
      console.log("error fetching noteblocks", err, err ? err.status : null, err && err.response ? err.response.status : null);
      return { list: [], success: false };
    }
  }

  async fetchReferenceState(reference) {
    try {
      const res = await Retry.Axios(async () => await axios(`/api/noteblock/${this.sharePrefix}${this.contextId}/reference/${reference}`), 6);
      return { list: res.data.blocks, success: true };
    } catch (err) {
      console.log("error fetching referencing noteblocks", err, err ? err.status : null, err && err.response ? err.response.status : null);
      return { list: [], success: false };
    }
  }

  async fetchSearchResult(query) {
    try {
      const res = await Retry.Axios(async () => await axios.post(`/api/noteblock/search/${this.sharePrefix}${this.contextId}`, query), 3);
      return { entities: res.data.blocks, success: true };
    } catch (err) {
      console.log(err);
      return { entities: [], success: false };
    }
  }


  canFetchEntity() {
    return true;
  }

  canFetchEntities() {
    return true;
  }

  canFetchParent() {
    return true;
  }

  canFetchReferences() {
    return true;
  }

  canSearch() {
    return true;
  }

  /// --- Processing Changes --- //
  onPatchEntity(data) {
    const entity = this.getEntityById(data.id);

    if (!entity) return false;

    if (data.index !== null && typeof data.index === "string") {
      entity.setIndex(data.index, { sync: false });
    }

    if (data.data !== null && typeof data.data === "string") {
      entity.setData(JSON.parse(data.data), { sync: false });
    }

    if (data.type !== null && typeof data.type === "string") {
      entity.setType(data.type, { sync: false });
    }

    if (data.references !== null && typeof data.references === "object" && typeof data.references.length === "number") {
      entity.setReferences(data.references, { sync: false, refresh: true });
    }

    if (data.parentId !== null && typeof data.parentId === "string") {
      entity.setParentId(data.parentId, { sync: false, refresh: true });
    }

    return true;
  }

  // Diff State //

  getUpdatedFields(oldEntity, newEntity) {
    return {
      index: oldEntity.index !== newEntity.index,
      data: oldEntity.data !== newEntity.data,
      type: oldEntity.type !== newEntity.type,
      parentId: oldEntity.parentId !== newEntity.parentId,
      references: !!oldEntity.references && !!newEntity.references && !(oldEntity.references.length === newEntity.references.length && oldEntity.references.every((v, i) => v === newEntity.references[i])),
    };
  }

  canPatch(updatedFields) {
    return (
      updatedFields.data ||
      updatedFields.index ||
      updatedFields.type ||
      updatedFields.references ||
      updatedFields.parentId
    );
  }

  isArrayEmpty(children) {
    for (let i = 0; i < children.length; i++) {
      if (!children[i].isEmpty()) return false;
    }
    return true;
  }

  handleEmptyNote(entity) {

    if (!this.isLoaded(entity.parentId)) return;

    if (entity.isParentNode()) {
      const children = this.getChildrenFast(entity.parentId);
      const empty = this.isArrayEmpty(children);
      const tree = this.getHandler("Node");
      const id = entity.getParentResourceId();
      if (tree && id !== "inbox") {
        const node = tree.getEntityById(id);
        if (node) {
          node.setHasNotes(!empty);
        }
        this.map?.addModifiedNode(this.contextId, id);
      }
    } else if (entity.isParentResource()) {
      const resources = this.getHandler("Resource");
      const resourceId = entity.getParentResourceId();
      const resource = resources?.getEntityById(resourceId);
      
      if (!!resource && resource.parentId !== "inbox") {
        this.map?.addModifiedNode(this.contextId, resource.parentId);
      }
    }
  }

  makeEntity(data) {
    return new NoteBlockEntity(this.entityEvents, data);
  }
}
