import axios from "axios";
import { Collection, Retry } from "@emberly/rtac";
import InviteEntity from "./InviteEntity";

export default class InviteCollection extends Collection {

  constructor(context, contextId) {
    super(context, "Invite", contextId, true);
    this.contextId = contextId;

    // listen for extra stuff
    this._onInvite = (inviteOps) => {
      this.processSyncOps({ entries: [inviteOps], pointer: null });
    };

    this._onInviteCancelled = (inviteOps) => {
      this.processSyncOps({ entries: [inviteOps], pointer: null });
    };

    this.getContext().on("OnInvite", this._onInvite);
    this.getContext().on("OnInviteCancelled", this._onInviteCancelled);
  }

  destroy() {
    this.getContext().off("OnInvite", this._onInvite);
    this.getContext().off("OnInviteCancelled", this._onInviteCancelled);
    super.destroy();
  }

  async fetchEverything() {
    return await this.fetchRemoteState();
  }

  canFetchEverything() {
    return true;
  }

  async fetchRemoteState() {
    try {
      const isDefault = this.getContext().isDefaultContext();
      const res = await Retry.Axios(async () => await axios(`/api/invite/${isDefault ? "email" : `context/${this.contextId}`}`), 6);
      return { list: res.data.invites, success: true };
    } catch (err) {
      console.log("error fetching tree", err, err ? err.status : null, err && err.response ? err.response.status : null);
      return { list: [{ id: "root", name: "Root", depth: 0, side: 0, index: "a0", color: 0 }], success: false };
    }
  }

  /// --- Processing Changes --- //
  onPatchEntity(data) {
    const entity = this.getEntityById(data.id);

    if (!entity) return false;

    if (data.name !== null && typeof data.name === "string") {
      entity.setName(data.name, { sync: false });
    }

    if (data.declined !== null && typeof data.declined === "boolean") {
      entity.setDeclined(data.declined, { sync: false });
    }

    if (data.access !== null && typeof data.access === "number") {
      entity.setAccess(data.access, { sync: false });
    }

    if (data.accepted !== null && typeof data.accepted === "boolean") {
      entity.setAccepted(data.accepted, { sync: false });
    }

    return true;
  }

  // Diff State //

  getUpdatedFields(oldEntity, newEntity) {
    return {
      name: oldEntity.name !== newEntity.name,
      accepted: oldEntity.accepted !== newEntity.accepted,
      declined: oldEntity.declined !== newEntity.declined,
      access: oldEntity.access !== newEntity.access,
      ownerPublicId: oldEntity.ownerPublicId !== newEntity.ownerPublicId
    };
  }

  canPatch(updatedFields) {
    return (
      updatedFields.name ||
      updatedFields.declined ||
      updatedFields.accepted ||
      updatedFields.access ||
      updatedFields.ownerPublicId
    );
  }

  onBeforeCreatingEntity(entity) { }

  makeEntity(data) {
    return new InviteEntity(this.entityEvents, data);
  }

  hasUpdates() {
    return false;
  }
}
