import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    background: "#1A1A1A",
    border: "2px solid #6A6A6A",
    borderRadius: "8px",
    padding: theme.spacing(4),
    width: "100%"
  },
  header: {
    marginTop: theme.spacing(0.5),
    color: "#FFF",
    maxWidth: "460px"
  },
  subheader: {
    color: "#D1D1D1",
    maxWidth: "460px",
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(4)
  },
  video: {
    borderRadius: "8px",
    width: "100%",
    border: "1px solid #494949"
  },
  image: {
    borderRadius: "2px",
    width: "100%"
  },
  narrowImage: {
    width: "70%",
    marginBottom: "24px",
    "@media(max-width: 700px)": {
      width: "100%",
      marginBottom: 0
    }
  },
  iconContainer: {
    "& svg": {
      width: "40px",
      height: "40px",
      color: "#EC4DEF"
    }
  }
}));

export default function AICard(props) {
  const classes = useStyles();
  const { isVideo, src, title, description, xs, md, lg, icon, narrowImage } = props;

  return (
    <Grid item xs={xs} md={md} lg={lg}>
      <Grid item container direction="row" className={classes.root}>

        <Grid item xs={12} container className={classes.iconContainer}>
          {icon}
        </Grid>

        <Grid item xs={12} container direction="column">
          <Typography variant="h5" className={classes.header}>{title}</Typography>
          <Typography variant="body1" className={classes.subheader}>{description}</Typography>
        </Grid>

        <Grid item xs={12} container justifyContent="center">
          {isVideo ? (
            <video className={classes.video} autoPlay loop disableRemotePlayback muted preload="none">
              <source src={src} type="video/mp4" />
            </video>
          ) : (
            <img className={`${classes.image} ${narrowImage ? classes.narrowImage : ""}`} src={src} alt={description} />
          )}
        </Grid>

      </Grid>
    </Grid>
  )
}