import { CollectionProvider } from "@emberly/rtac";
import ResourceCollection from "./resources/ResourceCollection";
import NodeCollection from "./tree/NodeCollection";
import NoteCollection from "./note/NoteCollection";
import MapCollection from "./map/MapCollection";
import InviteCollection from "./invites/InviteCollection";
import PluginCollection from "./plugins/PluginCollection";
import PluginFileCollection from "./plugins/PluginFileCollection";

export default class EmberlyCollectionProvider extends CollectionProvider {

  create(context, variant, contextId) {
    switch (variant) {
      case "Resource": return new ResourceCollection(context, contextId);
      case "Node": return context.isDefaultContext() ? null : new NodeCollection(context, contextId);
      case "Note": return new NoteCollection(context, contextId);
      case "Map": return new MapCollection(context, contextId);
      case "Invite": return new InviteCollection(context, contextId);
      case "Plugin": return context.isDefaultContext() ? new PluginCollection(context, contextId) : null;
      case "PluginFile": return context.isDefaultContext() ? new PluginFileCollection(context, contextId) : null;
      default: return null;
    }
  }

  getContextId(groupName, defaultGroupName) {
    if (groupName === defaultGroupName) {
      return groupName;
    } else {
      const idx = groupName.indexOf("/");
      return idx === -1 ? null : groupName.substr(idx + 1);
    }
  }

}
