const BOOT_PATH_ENABLED = "emberly_boot_enabled";
const BOOT_PATH_VALUE = "emberly_boot_path";

let prevMap = null;

export function getBootPath() {
  try {
    return window.localStorage.getItem(BOOT_PATH_ENABLED) === "y" ? (window.localStorage.getItem(BOOT_PATH_VALUE) || "/app/home") : "/app/home";
  } catch { }
  return "/app/home";
}

export function enableBootPath(value = true) {
  try {
    window.localStorage.setItem(BOOT_PATH_ENABLED, value ? "y" : "n");
  } catch {}
}

export function isBootPathEnabled() {
  try {
    return window.localStorage.getItem(BOOT_PATH_ENABLED) === "y";
  } catch {}
  return false;
}

export function setBootPath(path) {
  try {

    if (!!path) {
      const v = window.localStorage.getItem(BOOT_PATH_VALUE);
      if (!!v && v !== "/app/home") {
        prevMap = v;
      }
    }
    
    window.localStorage.setItem(BOOT_PATH_VALUE, path || "/app/home");
  } catch {}
}

export function getPreviousMap() {
  const v = getBootPath();
  return (v === "/app/home" ? prevMap : v) || "/app/home";
}


export function clearBootPath() {
  try {
    window.localStorage.deleteItem(BOOT_PATH_VALUE);
    window.localStorage.deleteItem(BOOT_PATH_ENABLED);
  } catch {}
}