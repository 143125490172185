import * as ActionTypes from "./actionTypes";
import { AuthAPI } from "@emberly/dataplane";


export function RequestSignup() {
  return {
    type: ActionTypes.AUTH_REQUEST_SIGNUP,
    payload: {}
  };
}

export function ResetPassword() {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.AUTH_RESET_PASSWORD_BEGIN,
        payload: {}
      });
      await AuthAPI.ResetPassword();
      dispatch({
        type: ActionTypes.AUTH_RESET_PASSWORD_FINISH,
        payload: {}
      });
    } catch (err) {
      console.log("err reset pwd", err);
      dispatch({
        type: ActionTypes.AUTH_RESET_PASSWORD_ERROR,
        payload: {
          reason: "unable to reset password"
        }
      });
    }
  };
}


export default function ShowOnboarding(val = true) {
  if (val) {
    return {
      type: ActionTypes.AUTH_ONBOARDING_BEGIN,
      payload: {}
    };
  } else {
    return {
      type: ActionTypes.AUTH_ONBOARDING_FINISH,
      payload: {}
    };
  }
}

export function SubscribeToPremium(profile, product, onFinish) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.AUTH_SUBSCRIBE_PREMIUM_BEGIN,
        payload: {}
      });

      window.Paddle.Checkout.open({
        product,
        passthrough: profile.userId,
        email: profile.email,
        country: !!profile.locale && typeof profile.locale === "string" ? profile.locale.toUpperCase() : undefined,
        successCallback: (data, err) => {
          console.log("paddle response", data, err);
          
          if (!err) {
            console.log("paddle success", data.product);
            dispatch({
              type: ActionTypes.AUTH_SUBSCRIBE_PREMIUM_FINISH,
              payload: {
                product: `${data.product.id}`
              }
            });

            if (onFinish !== undefined) {
              onFinish();
            }

          } else {
            console.log("paddle error");
            dispatch({
              type: ActionTypes.AUTH_SUBSCRIBE_PREMIUM_ERROR,
              payload: {}
            });
          }

        },
        closeCallback: () => {
          console.log("close paddle dialog");
          dispatch({
            type: ActionTypes.AUTH_SUBSCRIBE_PREMIUM_CANCEL,
            payload: {}
          });
        }
      });
      
    } catch (err) {
      console.log("error opening paddle checkout");
      console.log(err);
      dispatch({
        type: ActionTypes.AUTH_SUBSCRIBE_PREMIUM_ERROR,
        payload: {}
      });
    }
  };
}

export function UpdatePremiumPaymentMethod(profile) {
  return async (dispatch) => {
    try {

      dispatch({
        type: ActionTypes.AUTH_UPDATE_PREMIUM_BEGIN,
        payload: {}
      });

      window.Paddle.Checkout.open({
        override: profile.subscriptionUpdateUrl,
        passthrough: profile.userId,
        email: profile.email,
        country:  !!profile.locale && typeof profile.locale === "string" ? profile.locale.toUpperCase() : undefined,
        successCallback: (data, err) => {
          console.log("paddle response", data, err);
          
          if (!err) {
            console.log("paddle success");

            dispatch({
              type: ActionTypes.AUTH_UPDATE_PREMIUM_FINISH,
              payload: { profile, product: profile.subscriptionPlanId }
            });
          } else {
            console.log("paddle error");
            dispatch({
              type: ActionTypes.AUTH_UPDATE_PREMIUM_ERROR,
              payload: {}
            });
          }
        },
        closeCallback: () => {
          console.log("close paddle dialog");
          dispatch({
            type: ActionTypes.AUTH_UPDATE_PREMIUM_ERROR,
            payload: {}
          });
        }
      });
      
    } catch (err) {
      console.log("error opening paddle checkout");
      dispatch({
        type: ActionTypes.AUTH_UPDATE_PREMIUM_ERROR,
        payload: {}
      });
    }
  };
}

export function UpdatePremiumPlan(planId, type) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: ActionTypes.AUTH_UPDATE_PREMIUM_PLAN_BEGIN,
        payload: {
          product: planId,
          type
        }
      });

      const production = getState().auth.production;
      await AuthAPI.UpdatePlan(planId, !production);
      
      dispatch({
        type: ActionTypes.AUTH_UPDATE_PREMIUM_PLAN_FINISH,
        payload: { 
          product: planId
        }
      });

      // TODO fetch profile.
      
    } catch (err) {
      dispatch({
        type: ActionTypes.AUTH_UPDATE_PREMIUM_PLAN_ERROR,
        payload: {}
      });
    }
  };
}

export function LoadInvoices() {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.AUTH_INVOICES_LOAD_BEGIN,
        payload: {}
      });

      const invoices = await AuthAPI.LoadInvoices();

      dispatch({
        type: ActionTypes.AUTH_INVOICES_LOAD_FINISH,
        payload: {
          invoices
        }
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.AUTH_INVOICES_LOAD_ERROR,
        payload: {}
      });
    }
  };
}

export function LoadPlans() {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.AUTH_PLANS_LOAD_BEGIN,
        payload: {}
      });

      const plans = await AuthAPI.LoadPlans();

      dispatch({
        type: ActionTypes.AUTH_PLANS_LOAD_FINISH,
        payload: {
          plans
        }
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.AUTH_PLANS_LOAD_ERROR,
        payload: {}
      });
    }
  };
}