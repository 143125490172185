import * as ActionTypes from "../actions/actionTypes";

const initialState = {
  hasRenderer: false,
  dragEnabled: false,
  activeNodeId: null,
  activeNodeIdCount: 0,
  createNodeDialogHandles: [],
  loadedContextId: null
};

export default function mapReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.REGISTER_VIEW: return { ...state, hasRenderer: true };
    
    case ActionTypes.TREE_ON_LOAD: return { ...state, loadedContextId: payload.contextId };

    case ActionTypes.TREE_SET_ACTIVE_NODE_ID: return {  ...state, activeNodeId: payload.nodeId, activeNodeIdCount: state.activeNodeIdCount + 1 };

    case ActionTypes.TREE_SET_DRAG_ENABLED: return { ...state, dragEnabled: payload.enabled };

    // Create Node dialogs
    case ActionTypes.TREE_ADD_CREATE_NODE_DIALOG: 
    return { ...state, createNodeDialogHandles: state.createNodeDialogHandles.concat([payload]) };
  
    case ActionTypes.TREE_CREATE_NODE_DIALOG_CONFIRM: 
    case ActionTypes.TREE_CREATE_NODE_DIALOG_CANCEL:
      return { ...state, createNodeDialogHandles: state.createNodeDialogHandles.filter((t,i) => i !== 0) };

    default: return state;
  }
};
