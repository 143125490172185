import { combineReducers } from "redux";
import auth from "./auth";
import app from "./app";
import tree from "./tree";
import search from "./search";
import notification from "./notification";
import filter from "./filter";

const reducers = combineReducers({
  auth,
  app,
  tree,
  search,
  notification,
  filter
});

export default reducers;
