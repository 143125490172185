// ----------------------------------------------------------------------

export default function Lists(theme) {
  return {
    MuiMenu: {
      styleOverrides: {
        paper: {
          minWidth: "128px !important",
          maxWidth: "100vw"
        }
      }
    }
  };
}
