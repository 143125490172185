import axios from "axios";
import { Retry } from "@emberly/rtac";

export async function LoadProfile() {
  const result = await Retry.Axios(async () => await axios(`/api/client`));
  return result.data;
}

export async function LoadPublicProfile(userId) {
  const result = await Retry.Axios(async () => await axios(`/api/client/${userId}`));
  return result.data;
}

export async function ResetPassword() {
  const result = await Retry.Axios(async () => await axios.post(`/api/client/password/reset`, {}));
  return result.data;
}

export async function UpdateProfile(fullName, onboarded, avatar, resetAvatar, openLastVisited, affiliateEnabled, affiliateId, theme) {
  const result = await Retry.Axios(async () => await axios.patch(`/api/client`, { fullName, onboarded, avatar, resetAvatar, openLastVisited, affiliateEnabled, affiliateId, theme }));
  return result.data;
}

export async function LoadInvoices() {
  const result = await Retry.Axios(async () => await axios.get(`/api/client/invoices`));
  return result.data;
}

export async function LoadPlans() {
  const result = await Retry.Axios(async () => await axios.get(`/api/client/plans`));
  return result.data;
}

export async function UpdatePlan(planId, isTest = false) {
  const result = await Retry.Axios(async () => await axios.patch(`/api/client/plan/${planId}${isTest ? "/test" : ""}`, {}));
  return result.data;
}