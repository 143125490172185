import axios from "axios";
import { Collection, Retry } from "@emberly/rtac";
import MapEntity from "./MapEntity";

export default class MapCollection extends Collection {

  constructor(context, contextId) {
    super(context, "Map", contextId, true);
    this.contextId = contextId;

    this.entityEvents
      .on("uploadRequested", (map) => {
        this.registerPendingHandle(map.id, (handle) => map.onHandleReceived(handle));
      })
      .on("restore", (map) => {
        this.notify(`Map ${map.name} restored!`);
      });

    // listen for extra stuff
    this._onInviteAccepted = (map) => {
      if (!!map && !map.deleted) {
        this.loadedEntities.add(map.id)
        this.processSyncOps({ entries: [{ ops: 0, entity: map }], pointer: null });
        this.processSyncOps({ entries: [{ ops: 4, entity: map }], pointer: null });
        
        const entity = this.getEntityById(map.id);
        
        if (!!entity) {
          this.getContext().getRootContext().setGroupAccess(entity.groupName, entity.access);
        }
      }
    };

    this._onDuplicatedMap = (map) => {
      this.loadedEntities.add(map.id)
      this.processSyncOps({ entries: [{ ops: 0, entity: map }], pointer: null });
      this.processSyncOps({ entries: [{ ops: 4, entity: map }], pointer: null });
    };

    this.getContext().on("OnInviteAccepted", this._onInviteAccepted);
    this.getContext().on("OnDuplicatedMap", this._onDuplicatedMap);
    this.getContext().registerGroupCollection(this);
  }

  destroy() {
    this.getContext().off("OnInviteAccepted", this._onInviteAccepted);
    this.getContext().off("OnDuplicatedMap", this._onDuplicatedMap);
    super.destroy();
  }

  async fetchEverything() {
    return await this.fetchRemoteState();
  }

  canFetchEverything() {
    return true;
  }

  async fetchRemoteState() {
    try {
      const res = await Retry.Axios(async () => await axios(`/api/map`), 6);
      // this is done to prevent removing access to currently public maps we are not members of.
      const publicMaps = this.entityIndex.filter(t => t.access === 0 && t.isPublic).map(t => t.getData());
      return { list: res.data.maps.concat(publicMaps), success: true };
    } catch (err) {
      console.log("error fetching tree", err, err ? err.status : null, err && err.response ? err.response.status : null);
      return { list: [{ id: "root", name: "Root", depth: 0, side: 0, index: "a0", color: 0 }], success: false };
    }
  }

  async fetchEntityState(entityId) {
    // todo this one fetches state of loaded topics.
    try {
      const res = await Retry.Axios(async () => await axios(`/api/map/${this.getContext().isPublic() ? "public/" : ""}${entityId}`), 6);
      return { data: res.data, success: !!res.data };
    } catch (err) {
      console.log("error fetching resources", err, err ? err.status : null, err && err.response ? err.response.status : null);
      return { data: null, success: false };
    }
  }

  canFetchEntity() {
    return true;
  }

  /// --- Processing Changes --- //
  onPatchEntity(data) {
    const entity = this.getEntityById(data.id);

    if (!entity) return false;

    if (data.name !== null && typeof data.name === "string") {
      entity.setName(data.name, { sync: false });
    }

    if (data.lastOpened !== null && typeof data.lastOpened === "string") {
      entity.setLastOpened(new Date(data.lastOpened), { sync: false });
    }

    if (data.favorite !== null && typeof data.favorite === "boolean") {
      entity.setFavorite(data.favorite, { sync: false });
    }

    if (!!data.lastModifiedBy && typeof data.lastModifiedBy === "object" && typeof data.lastModifiedBy.length === "number") {
      entity.setLastModifiedBy(data.lastModifiedBy, { sync: false });
    }

    if (typeof data.preview === "string" && data.preview !== "upload" && data.preview !== "complete") {
      entity.setPreview(data.preview, { sync: false });
    }

    if (typeof data.previewFileSize === "number") {
      entity.setPreviewFileSize(data.previewFileSize, { sync: false });
    }

    if (typeof data.previewFileType === "string") {
      entity.setPreviewFileType(data.previewFileType, { sync: false });
    }

    if (typeof data.isPublic === "boolean") {
      entity.setIsPublic(data.isPublic, { sync: false });
    }

    if (typeof data.duplicateEnabled === "boolean") {
      entity.setDuplicateEnabled(data.duplicateEnabled, { sync: false });
    }

    if (typeof data.watermarkEnabled === "boolean") {
      entity.setWatermarkEnabled(data.watermarkEnabled, { sync: false });
    }

    if (typeof data.updateAccess === "object" && typeof data.updateAccess.length === "number") {
      entity.updateMembers(data.updateAccess, this.getContext().getLoadedProfile()?.publicId);
    }

    if (typeof data.members === "object" && typeof data.members.length === "number") {
      entity.setMembers(data.members);
    }

    if (typeof data.access === "number") {
      entity.access = data.access;
    }

    // TODO add patching for members list!!! but only when coming from syncToRemote. dont transmit it from other people, so simply dont add it to the model.

    return true;
  }

  // Update and broadcast accesslevel locally
  setAccessLevel(contextId, accessLevel, updated = false) {
    const entity = this.getEntityById(contextId);
    if (!!entity && typeof accessLevel === "number" && accessLevel !== entity.access) {
      entity.access = accessLevel;
      entity.isOwner = entity.access === 4;
      entity.broadcast();

      if (updated) {
        switch (accessLevel) {
          case 0:
            this.notify(`You no longer have access to ${entity.name}`);
            break;

          case 1:
            this.notify(`You now have view access to ${entity.name}`);
            break;

          case 2:
            this.notify(`You now have edit access to ${entity.name}`);
            break;

          case 3:
            this.notify(`You now have access to invite new members to ${entity.name}`);
            break;

          default:
            break;
        }
      }
    }

    return entity;
  }

  // Diff State //

  getUpdatedFields(oldEntity, newEntity) {
    return {
      name: oldEntity.name !== newEntity.name,
      favorite: oldEntity.favorite !== newEntity.favorite,
      lastOpened: oldEntity.lastOpened !== newEntity.lastOpened,
      lastModifiedBy: this.compareLastModifiedBy(oldEntity.lastModifiedBy, newEntity.lastModifiedBy),
      updateAccess: this.compareUpdateAccess(oldEntity.updateAccess, newEntity.updateAccess),
      preview: oldEntity.preview !== newEntity.preview,
      previewFileSize: newEntity.preview === "upload",
      previewFileType: newEntity.preview === "upload",
      isPublic: oldEntity.isPublic !== newEntity.isPublic,
      duplicateEnabled: oldEntity.duplicateEnabled !== newEntity.duplicateEnabled,
      watermarkEnabled: oldEntity.watermarkEnabled !== newEntity.watermarkEnabled,
      members: this.compareUpdateAccess(oldEntity.members, newEntity.members),
    };
  }

  compareLastModifiedBy(oldList, newList) {
    if (oldList.length !== newList.length) {
      return true;
    }

    for (let i = 0; i < oldList.length; i++) {
      let a = oldList[i];
      let b = newList[i];
      if (a.id !== b.id || a.userId !== b.userId || a.operation !== b.operation) {
        return true;
      }
    }

    return false;
  }

  compareUpdateAccess(oldList, newList) {

    if (!newList || newList.length === 0) {
      return false;
    }

    if ((!oldList && !!newList) || (oldList.length !== newList.length)) {
      return true;
    }

    for (let i = 0; i < oldList.length; i++) {
      let a = oldList[i];
      let b = newList[i];
      if (a.access !== b.access || a.publicUserId !== b.publicUserId) {
        return true;
      }
    }

    return false;
  }

  compareMembers(oldList, newList) {

    if (!newList) {
      return false;
    }

    if ((!oldList && !!newList) || (oldList.length !== newList.length)) {
      return true;
    }

    for (let i = 0; i < oldList.length; i++) {
      let a = oldList[i];
      let b = newList[i];
      if (a.access !== b.access || a.publicUserId !== b.publicUserId || a.email !== b.email) {
        return true;
      }
    }

    return false;
  }

  canPatch(updatedFields) {
    return (
      updatedFields.name ||
      updatedFields.favorite ||
      updatedFields.lastOpened ||
      updatedFields.lastModifiedBy ||
      updatedFields.preview ||
      updatedFields.previewFileSize ||
      updatedFields.previewFileType ||
      updatedFields.isPublic ||
      updatedFields.duplicateEnabled ||
      updatedFields.watermarkEnabled ||
      updatedFields.updateAccess ||
      updatedFields.members
    );
  }

  onBeforeCreatingEntity(entity) {
    const profile = this.getContext()?.getLoadedProfile();
    if (!entity.publicUserId) {
      entity.publicUserId = profile.publicId;
    }
    entity.access = 4;
    entity.isOwner = true;
    entity.watermarkEnabled = true;

    if ((!entity.members || entity.members.length === 0) && !!profile && !!profile.email && !!profile.publicId) {
      entity.members = [
        {
          access: 4,
          email: profile.email,
          publicUserId: profile.publicId
        }
      ];
    }
  }

  onRecievedEntity(entity) {
    const profile = this.getContext().getLoadedProfile();

    if (!!profile && !!entity && !entity.publicUserId && entity.access === 0) {
      entity.publicUserId = profile.publicId;
      entity.access = 4;
      entity.isOwner = true;
      entity.watermarkEnabled = true;
    }
  }

  addModifiedNode(contextId, nodeId) {
    if (!!nodeId) {
      const profile = this.getContext().getLoadedProfile();
      const entity = this.getEntityById(contextId);

      if (!!entity) {
        entity.addModifiedNode(nodeId, profile.publicId);
      } else {
        this.loadAndRunEntity(contextId, e => e.addModifiedNode(nodeId, profile.publicId));
      }
    }
  }
  
  async loadAndRunEntity(id, fn) {
    if (!id) return;
    try {
      await this.loadEntity(id);
      const entity = this.getEntityById(id);

      if (!!entity) {
        fn(entity);
      }
    } catch(err) {
      console.log(err);
    } finally {
      await this.unloadEntity(id);
    }
  }

  makeEntity(data) {
    return new MapEntity(this.entityEvents, data);
  }

  hasUpdates() {
    return false;
  }
}
