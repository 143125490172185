import { Entity } from "@emberly/rtac"

export default class NoteBlockEntity extends Entity {

  constructor(eventEmitter, data) {
    super(eventEmitter, data);
    this.type = data.type || "paragraph";
    this.data = typeof data.data === "string" ? JSON.parse(data.data) : (data.data || { text: "" });
    this.references = data.references || [];
    this._referenceSet = new Set(this.references);
    this._data = typeof data.data === "string" ? data.data : JSON.stringify(data.data);
  }

  hasParent() {
    return true;
  }

  setType(val, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    if (this.type !== val) {
      this.type = val;
      if (sync) {
        this.update("updated", { sync, metadata, instanceId, historyGroup });
      }
      return true;
    }
    return false;
  }

  setData(val, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    let sdata = JSON.stringify(val);
    if (sdata !== this._data) {
      this._data = sdata;
      this.data = val;
      if (sync) {
        this.update("updated", { sync, metadata, instanceId, historyGroup });
      }
      return true;
    }
  }

  getData() {
    return {
      id: this.id,
      type: this.type,
      data: this._data,
      index: this.index,
      parentId: this.parentId,
      references: this.references
    };
  }

  hasReference(reference) {
    return this._referenceSet.has(reference);
  }

  getReferences() {
    return this.references;
  }

  hasReferences() {
    return true;
  }

  isParentDescription() {
    return this.parentId.startsWith("resource-");
  }

  isParentResource() {
    return this.parentId.startsWith("resource-");
  }

  isParentNode() {
    return this.parentId.startsWith("node-");
  }

  getParentResourceId() {
    return this.parentId.substr(this.parentId.indexOf("-") + 1);
  }

  isParentResourceId(id) {
    return this.parentId.endsWith(id);
  }

  isEmpty() {
    return this.type === "paragraph" && this.data.text.trim() === "";
  }
}
