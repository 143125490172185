import { useMemo, Suspense, lazy, useEffect } from "react";
import { Router as BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import PrivateRoute from "./components/atoms/PrivateRoute";
import { useRTACAuth } from "@emberly/dataplane";
import FallbackPanel from "./components/molecules/panels/FallbackPanel";
import { Helmet } from "react-helmet";

// Instant
import MapAccessLayer from "./components/organisms/access/MapAccessLayer";
import LandingPage from "./components/pages/home/LandingPage";
import ExtensionLoginPage from "./components/pages/home/ExtensionLoginPage";
import ApiVersionDialog from "./components/organisms/dialogs/ApiVersionDialog";
import NotificationSnackbar from "./components/atoms/NotificationSnackbar";
import { getBootPath } from "./common/BootHandler";
import { useTheme } from "@mui/styles";

const DashboardPage = lazy(() => import("./components/pages/dashboard"));

// Suspense
const MapView = lazy(() => import("./components/pages/map/MapView"));
const AboutUs = lazy(() => import("./components/pages/home/AboutUs"));
const PricingPage = lazy(() => import("./components/pages/home/PricingPage"));

const redirectWithParams = `${getBootPath()}${window.location.search}`;

export default function App(props) {
  const { loading, isAuthenticated } = useRTACAuth();

  const hasError = useMemo(() => {
    try {
      const parsedUrl = new URL(window.location);
      return parsedUrl.searchParams.has("error");
    } catch (err) {
      return false;
    }
  }, []);

  const theme = useTheme();
  
  const globalStyles = useMemo(() => ([
    {
      cssText: `
      ::-moz-selection {
        background-color: ${theme.palette.common.textSelection};
      }
      
      ::selection {
        background-color: ${theme.palette.common.textSelection};
      }

      body, html, #root, .App {
        background: ${theme.palette.background.paper} !important;
      }

      .ct::before, .ct::after {
        background-color: ${theme.palette.common.editorTooltip};
      }
      `
    }
  ]), [theme]);

  useEffect(() => {
    const meta = document.querySelector('meta[name="theme-color"]');
    if (!!meta) {
      meta.content = theme.palette.common.themeColor;
    }
  }, [theme]);

  // TODO load display only routes even if we are still loading? move if switch inside router? TODO any way to move graph engine out so we can re-use canvas.
  return (
    <>
      <Helmet style={globalStyles}>
        <title>Emberly - {isAuthenticated ? "Home" : "Your knowledge. Organized."}</title>
        <meta property="og:image" content="https://ember.ly/assets/link_preview.png"></meta>
      </Helmet>
      <FallbackPanel hasError={hasError} />
      <BrowserRouter history={props.history}>
        {
          !loading && !hasError ? (
            <Suspense fallback={
              null
            }>
              <Switch>
                <Route path="/" exact>
                  {isAuthenticated ? <Redirect to={redirectWithParams} /> : <LandingPage />}
                </Route>
                <PrivateRoute path="/app" component={DashboardPage} />
                <Route path="/map/:userId?/:mapId?">
                  <MapAccessLayer>
                    <MapView />
                  </MapAccessLayer>
                </Route>
                <PrivateRoute path="/extension/login" component={ExtensionLoginPage} />
                <Route path="/pricing" component={PricingPage} />
                <Route path="/about" component={AboutUs} />
                <Route
                  path="/sapling/:userId?/:mapId?"
                  render={props => (
                    <Redirect to={`/map/${props.match.params.userId}/${props.match.params.mapId}`} />
                  )}
                />
                <Redirect to="/" />
              </Switch>
            </Suspense>
          ) : null
        }
      </BrowserRouter>
      <ApiVersionDialog />
      <NotificationSnackbar active={isAuthenticated} />
    </>
  );
}