import axios from "axios";
import { Collection, Retry } from "@emberly/rtac";
import PluginFileEntity from "./PluginFileEntity";

export default class PluginFileCollection extends Collection {

  constructor(context, contextId) {
    super(context, "PluginFile", contextId);
    this.contextId = contextId;
    this.flushLimit = 4;
  }

  async fetchRemoteState() {
    try {
      const res = await Retry.Axios(async () => await axios(`/api/pluginfile`), 6);
      return { list: res.data.files, success: true };
    } catch (err) {
      console.log("error fetching plugins", err, err ? err.status : null, err && err.response ? err.response.status : null);
      return { list: [], success: false };
    }
  }

  async fetchEntityState(entityId) {
    // todo this one fetches state of loaded topics.
    try {
      const res = await Retry.Axios(async () => await axios(`/api/pluginfile/${entityId}`), 6);
      return { data: res.data, success: !!res.data };
    } catch (err) {
      console.log("error fetching plugin", err, err ? err.status : null, err && err.response ? err.response.status : null);
      return { data: null, success: false };
    }
  }

  async fetchParentState(parentId) {
    try {
      const res = await Retry.Axios(async () => await axios(`/api/pluginfile/parent/${parentId}`), 6);
      return { list: res.data.files, success: true };
    } catch (err) {
      console.log("error fetching plugin files", err, err ? err.status : null, err && err.response ? err.response.status : null);
      return { list: [], success: false };
    }
  }

  canFetchEntity() {
    return true;
  }

  canFetchParent() {
    return true;
  }

  /// --- Processing Changes --- //
  onPatchEntity(data) {
    const entity = this.getEntityById(data.id);

    if (!entity) return false;

    if (data.index !== null && typeof data.index === "string") {
      entity.setIndex(data.index, { sync: false });
    }

    if (data.name !== null && typeof data.name === "string") {
      entity.setName(data.name, { sync: false });
    }

    if (data.fullPath !== null && typeof data.fullPath === "string") {
      entity.setFullPath(data.fullPath, { sync: false });
    }

    if (data.data !== null && typeof data.data === "string") {
      entity.setData(data.data, { sync: false });
    }

    if (data.fileType !== null && typeof data.fileType === "string") {
      entity.setFileType(data.fileType, { sync: false });
    }

    if (data.folderId !== null && typeof data.folderId === "string") {
      entity.setFolderId(data.folderId, { sync: false });
    }

    return true;
  }

  // Diff State //

  getUpdatedFields(oldEntity, newEntity) {
    return {
      name: oldEntity.name !== newEntity.name,
      index: oldEntity.index !== newEntity.index,
      data: oldEntity.data !== newEntity.data,
      folderId: oldEntity.folderId !== newEntity.folderId,
      fullPath: oldEntity.fullPath !== newEntity.fullPath,
      fileType: oldEntity.fileType !== newEntity.fileType,
    };
  }

  canPatch(updatedFields) {
    return (
      updatedFields.name ||
      updatedFields.index ||
      updatedFields.fullPath || 
      updatedFields.fileType || 
      updatedFields.data ||
      updatedFields.folderId
    );
  }


  makeEntity(data) {
    return new PluginFileEntity(this.entityEvents, data);
  }
}
