import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { PanToActiveNode } from "../actions/tree";

export function useIsPortrait() {
  const [ isPortrait, setIsPortrait ] = useState(window.innerWidth < 512);
  const dispatch = useDispatch();

  useEffect(() => {
    const setScreenOrientation = () => {
      let orientation = window.innerWidth < 512;
      setIsPortrait(orientation);
    };

    const onRotate = () => dispatch(PanToActiveNode());
    window.addEventListener("resize", setScreenOrientation);
    window.addEventListener("orientationchange", setScreenOrientation);
    window.addEventListener("orientationchange", onRotate);
    return () => {
      window.removeEventListener("resize", setScreenOrientation);
      window.removeEventListener("orientationchange", setScreenOrientation);
      window.removeEventListener("orientationchange", onRotate);
    };
  },[dispatch]);

  return isPortrait;
}