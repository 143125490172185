import { Entity } from "@emberly/rtac";

const noSync = { sync: false };

export default class TreeHelper {

  static GetOrderedTreeByReference(nodeData, allowIteration = true) {

    let nodes = new Map();
    let result = [];
    let root = null;
    let buffer = [];

    for (let i = 0; i < nodeData.length; i++) {

      const node = new TreeNode(nodeData[i]);

      if (!node.parentId) {
        root = node;
      }

      nodes.set(node.id, node);
      buffer.push(node);
    }

    for (let i = 0; i < buffer.length; i++) {
      const node = buffer[i];

      if (node.parentId && nodes.has(node.parentId)) {
        nodes.get(node.parentId).children.push(node);
      } else if (node.parentId) {
        root.children.push(node);
      }
    }

    const sortFn = (a, b) => Entity.Compare(a, b);
    buffer = [root];

    while (buffer.length !== 0) {
      const node = buffer.shift();
      result.push(node.data);
      nodes.delete(node.id);

      if (node.children.length !== 0) {
        const children = node.children.sort(sortFn);
        for (let i = 0; i < children.length; i++) {
          buffer.push(children[i]);
        }
      }
    }

    if (nodes.size !== 0 && allowIteration) {
      TreeHelper.CorrectReferences(nodes, root)
      return TreeHelper.GetOrderedTreeByReference(nodeData, false);
    } else if (nodes.size !== 0) {
      console.log("hanging nodes, already iterated");
    }

    return result; // TODO also return messed up references so we can push the changes to backend.
  }

  static MessReference(nodeData, id, parentId) {
    const n = nodeData.find(t => t.id === id);
    if (n) {
      n.parentId = parentId;
    }
  }

  static CorrectReferences(nodes, root) {
    // need to separate out branches and correct them individually
    const referenced = new Set();

    // this corrects mixed references
    for (let node of nodes.values()) {

      for (let i = 0; i < node.children.length; i++) {
        referenced.add(node.parentId);
        const child = node.children[i];
        if (referenced.has(child.id)) {
          const correction = TreeHelper.PickRootChild(child, nodes.get(child.parentId));
          TreeHelper.RemoveFromParent(correction, nodes);
          TreeHelper.AddToParent(correction, root);
        }
      }
    }

    // TODO correct loose references
  }


  static PickRootChild(a, b) {
    if (!b) return a;
    if (!a) return b;

    try {
      if (b.data.depth < a.data.depth) {
        return b;
      }

      if (b.children.length > a.children.length) {
        return b;
      }

      if (new Date(a.lastModified) > new Date(b.lastModified)) {
        return b;
      }
    } catch { }

    return a;
  }


  static RemoveFromParent(node, nodes) {
    const parent = nodes.get(node.parentId);

    if (parent) {
      if (node.data.color === null) {
        node.data.setColor(parent.data.color, noSync);
        node.data.setSide(parent.data.side, noSync);
        node.data.update("updated", noSync);
      }

      const idx = parent.children.findIndex(t => t.id === node.id);
      if (idx !== -1) {
        parent.children.splice(idx, 1);
      }
    }
  }

  static AddToParent(node, parent) {
    node.parentId = parent.id;
    parent.children.push(node);
    node.data.setParentId(parent.id, noSync);
    node.data.update("updated", noSync);
  }
}

// can we detect issues right here
// a branch with a looping reference will never be detected.

class TreeNode {
  constructor(data) {
    this.data = data;
    this.id = data.id;
    this.parentId = data.parentId;
    this.index = data.index;
    this.children = [];
  }
}