import { Favorite as FavoriteIcon, FiberManualRecord as RatingIcon } from "@mui/icons-material";

export default function LoadingAnimation(props) {
  const { className } = props;

  return (
    <div className={`${className || ""} dots-container`}>
      <RatingIcon className="dots dot1"/>
      <RatingIcon className="dots dot2"/>
      <RatingIcon className="dots dot3"/>
      <FavoriteIcon className="dots heart"/>
      <RatingIcon className="dots dot1 dot1a"/>
      <RatingIcon className="dots dot2 dot2a"/>
      <RatingIcon className="dots dot3 dot3a"/>
      <FavoriteIcon className="dots heart hearta" />
    </div>
  );
}
