import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { MAX_WIDTH, SECTION_SPACING, WIDTH } from "./constants";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: SECTION_SPACING,
    marginBottom: SECTION_SPACING,
    maxWidth: MAX_WIDTH,
    width: WIDTH,
  },
  textItem: {
    paddingRight: theme.spacing(2)
  },
  header: {
    marginBottom: theme.spacing(1)
  },
  image: {
    width: "100%",
    height: "auto",
    boxShadow: theme.shadows[3],
    borderRadius: theme.shape.borderRadius,
    minHeight: "510px",
    "@media (max-width: 1280px)": {
      minHeight: "calc((100vw / 1.6) / 1.6)",
    },
    "@media (max-width: 900px)": {
      marginTop: theme.spacing(1),
      minHeight: "auto",
      height: "calc((100vw - 56px) / 1.6)"
    }
  }
}));

export default function PageSection(props) {
  const classes = useStyles();
  const { title, description, image } = props;
  return (
    <Grid container direction="row" justifyContent="space-between" alignItems="center" className={classes.root} spacing={3}>
      <Grid item xs={12} md={4} container direction="column" className={classes.textItem}>
        <Typography variant="p_header" className={classes.header}>{title}</Typography>
        <Typography variant="p_subtitle" color="textSecondary">{description}</Typography>
      </Grid>

      <Grid item xs={12} md={8}>
        <video className={classes.image} autoPlay loop disableRemotePlayback muted preload="none">
          <source src={image} type="video/mp4" />
        </video>
      </Grid>

    </Grid>
  );
}
