import { useState, useEffect } from "react";
import { Fade, Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import LoadingAnimation from "../../atoms/display/LoadingAnimation";

const useStyles = makeStyles(theme => ({
  root: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 9999999,
    background: theme.palette.common.canvas
  },
  animation: {
    position: "absolute",
    width: "120px",
    top: "calc(50% - 100px)",
    left: "calc(50% - 60px)"
  }, 
  refreshButton: {
    position: "absolute",
    top: "calc(50% + 44px)",
    left: "calc(50% - 60px)",
    width: "120px"
  },
  refreshDescription: {
    position: "absolute",
    top: "calc(50% - 50px)",
    left: "calc(50% - 150px)",
    width: "300px"
  }
}));

const duration = { enter: 0, exit: 325 };

export default function LoadingPanel(props) {
  const { display } = props;
  const classes = useStyles();
  const [mounted, setMounted] = useState(false);
  const [visible, setVisible] = useState(true);
  const [animate, setAnimate] = useState(false);
  const [showRefreshButton, setShowRefreshButton] = useState(false);

  useEffect(() => {
    if (!display) {
      return () => {
        setVisible(true);
        setMounted(true);
      }
    }
  }, [display]);


  useEffect(() => {
    if (display) {
      setMounted(true);
      const t0 = setTimeout(() => setAnimate(true), 420);
      const t1 = setTimeout(() => setVisible(true), 10);
      const t2 = setTimeout(() => setShowRefreshButton(true), 12000);

      return () => {
        clearTimeout(t0);
        clearTimeout(t1);
        clearTimeout(t2);
      };
    } else {
      setShowRefreshButton(false);
      const t0 = setTimeout(() => setVisible(false), 100);
      const t1 = setTimeout(() => setMounted(false), 1000);

      return () => {
        setAnimate(false);
        setMounted(false);
        clearTimeout(t0);
        clearTimeout(t1);
      };
    }
  }, [display]);

  return mounted ? (
    <Fade in={visible} timeout={duration}>
      <div className={classes.root}>
        {
          animate ? (
            <LoadingAnimation className={classes.animation} />
          ) : null
        }
        {showRefreshButton ? (
          <>
            <Typography textAlign="center" className={classes.refreshDescription} variant="body1">
              We're sorry, but it looks like the loading is taking a while. You might want to refresh the page.
            </Typography>
            <Button 
              variant="contained" 
              color="primary" 
              className={classes.refreshButton}
              onClick={() => document.location.reload()}
            >
              Refresh
            </Button>
          </>
        ) : null}
      </div>
    </Fade>
  ) : null;
}
