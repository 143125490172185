import { useEffect, useMemo, useRef, useState } from "react";
import { ConditionalPortal } from "../../atoms/views/ConditionalPortalView";
import LoadingPanel from "../../molecules/panels/LoadingPanel";
import { useRTACAuth } from "@emberly/dataplane";
import InviteView from "./InviteView";
import MapNotFoundView from "./MapNotFoundView";
import { useLocation, useRouteMatch } from "react-router";

const hasLoadedAccess = (context, mapId) => {
  if (context) {
    const group = context.getGroupContextOrDefault(context.getDefaultGroupName());
    
    if (group) {
      const collection = group.getCollection("Map");
      const entity = collection.getEntityById(mapId);
      return entity?._deleted ? 0 : entity?.access || 0;
    }
  }

  return 0;
};

const currentLocation = new URL(window.location.href);
const isEmbed = inIframe() || currentLocation.searchParams.has("embed");

function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export default function MapAccessLayer(props) { 
  const { loading, context } = useRTACAuth();
  const match = useRouteMatch();
  const location = useLocation();
  const { userId, mapId } = match.params;
  const loadedAccess = useMemo(() => hasLoadedAccess(context, mapId), [mapId, context]);
  const [invite, setInvite] = useState(null); // ensure these are reset when switching map.
  const [access, setAccess] = useState(loadedAccess);
  const [readyForMapId, setReadyForMapId] = useState(loadedAccess !== 0 ? mapId : "");
  const [isPublic, setIsPublic] = useState(false);
  const locationRef = useRef(location);
  locationRef.current = location;

  // TODO need smooth switching between maps.
  useEffect(() => {

    if (loadedAccess === 0) { // trigger this one to force loading.
      setAccess(0);
      setInvite(null);
      setReadyForMapId("");
    } else {
      setReadyForMapId(mapId);
    }

    if (!!context && isEmbed) { // bypass check if isEmbed, assume public.
      context.setGroupIsPublic(`${userId}/${mapId}`, userId, true);
      setAccess(0);
      setIsPublic(true);
      setReadyForMapId(mapId);
    } else if (!!context && !loading && mapId && userId) {
      const state = new AccessStateMachine(context, userId, mapId, locationRef.current.pathname, locationRef.current.search);
      state.run().then(() => {

        if (state.invite) {
          setInvite(state.invite);
        } else if (state.access > 0) {
          setAccess(state.access);
        }

        setIsPublic(state.isPublic);
        
        if (state.ready) {
          setReadyForMapId(mapId);
        }

        if (state.ready) {
          // listen for changes to entity and invite

          if (state.invite) {
            state.onInviteUpdate((iid, patch, method) => {
              if (method === "deleted") {
                setInvite(null);
              }
            });
          }

          state.onMapUpdate((iid, patch, method) => {
            if (method === "deleted") {
              setAccess(0);
              setIsPublic(false);
              setInvite(null);
            } else if (method === "updated" || method === "created") {
              const entity = state.mapCollection.getEntityById(mapId);
              if (entity) {
                setAccess(entity.access);
                setIsPublic(entity.isPublic);
                if (entity.access) {
                  setInvite(null);
                }
              }
            }
          });

        }
      });

      return () => {
        state.destroy();
      };
    }

  }, [loading, context, mapId, userId, loadedAccess]);

  return readyForMapId === mapId || loadedAccess !== 0 ? (
    access || (!invite && isPublic) ? (
      props.children
    ) : (
      invite ? (
        <InviteView invite={invite} />
      ) : (
        <MapNotFoundView />
      )
    )
  ) : (
    <ConditionalPortal active portalId="root-portal">
      <LoadingPanel display />
    </ConditionalPortal>
  );
}

class AccessStateMachine {

  constructor(context, userId, mapId, pathname, search) {
    this.context = context;
    this.userId = userId;
    this.mapId = mapId;
    this.groupName = `${userId}/${mapId}`;
    this.isPublic = false;
    this.pathname = pathname;
    this.search = search;
    this.access = -1;
    this.defaultGroup = null;
    this.invite = null;
    this.inviteId = null;
    this.ready = false;
    this.params = new URLSearchParams(this.search);
    this.inviteCollection = null;
    this.mapCollection = null;
    this.inviteCallback = null;
    this.mapCallback = null;
  }

  async run() { // maybe let this add rules to corecontext into how a group is connected to? naah, this is only relevant for public access, need to add a map in corecontext that lets us mark groups with a given accessLevel, or maybe we specify that as a param when fetching it.
    this.inviteId = this.search ? this.params.get("invite") : null;
    this.ready = true;
    if (this.context.hasAuthentication()) {
      this.defaultGroup = await this.context.getGroupContext(this.context.getDefaultGroupName(), false);
      const collection = this.defaultGroup.getCollection("Map");
      this.mapCollection = collection;

      if (await collection.loadEntity(this.mapId)) {
        const entity = collection.getEntityById(this.mapId);
        
        if (!entity._deleted) {
          this.map = entity;
          // check how we can access it, allow getEntityById to be fetched, if it is public.
          this.setAccessLevel(entity.isPublic, entity.access);
  
          if (this.access === 0 && this.inviteId) {
            await this.lookForInvite();
          }
        }

        // TODO check for invites if we only have public access

      } else {
        // no access
        if (this.inviteId) {
          // fetch invite
          await this.lookForInvite();

        } else {
          // no access
          console.log("there are no ways to access this");
        }
      }

    } else {

      if (this.inviteId) {
        // force login
        this.ready = false;
        this.context.forceAuthentication("", true, this.params.get("hint") || "");
      } else {
        // assume public access, try to fetch entity
        const publicGroup = await this.context.getGroupContext("public", false);
        const collection = publicGroup.getCollection("Map");

        if (await collection.loadEntity(this.mapId)) {
          const entity = collection.getEntityById(this.mapId);
          if (entity?._deleted === false) {
            this.setAccessLevel(entity.isPublic, entity.access);
          }
        } else {

          // here we login if not embedded, or else we show the mapnotfoundview
          if (!this.isEmbed()) {
            this.ready = false;
            this.context.forceAuthentication();
          }
        }
      }
    }
  }

  setAccessLevel(isPublic = false, access = 0) {
    this.isPublic = isPublic;
    this.access = access;
    if (this.access === 0) {
      this.context.setGroupIsPublic(this.groupName, this.userId, isPublic);
    } else {
      this.context.setGroupAccess(this.groupName, this.access);
    }
  }

  async lookForInvite() {
    const collection = this.defaultGroup.getCollection("Invite");

    if (await collection.loadEverything()) {
      const entity = collection.getEntityById(this.inviteId);

      if (!!entity && !entity.accepted && !entity.declined) {
        this.invite = entity;
      }

      this.inviteCollection = collection;
    }
  }

  destroy() {
    if (this.inviteCallback !== null) {
      this.inviteCollection?.offEntityEvent(this.inviteId, "*", this.inviteCallback);
      this.inviteCallback = null;
    }

    if (this.mapCallback !== null) {
      this.mapCollection?.offEntityEvent(this.mapId, "*", this.mapCallback);
      this.mapCallback = null;
    }

    this.mapCollection = null;
    this.inviteCollection = null;
  }

  onInviteUpdate(call) {
    if (this.inviteCallback !== null) {
      this.inviteCollection?.offEntityEvent(this.inviteId, "*", this.inviteCallback);
    }
    this.inviteCallback = call;
    this.inviteCollection?.onEntityEvent(this.inviteId, "*", call);
  }

  onMapUpdate(call) {
    if (this.mapCallback !== null) {
      this.mapCollection?.offEntityEvent(this.mapId, "*", this.mapCallback);
    }
    this.mapCallback = call;
    this.mapCollection?.onEntityEvent(this.mapId, "*", call);
  }

  isEmbed() {
    try {
      return window.self !== window.top || this.params.has("embed");
    } catch (e) {
      return true;
    }
  }
}
