import { useCallback } from "react";
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";
import { usePrivateRTACRoute } from "@emberly/dataplane";

export default function PrivateRoute(props) {
  const { component: Component, path, ...rest } = props;
  const requestSignup = useSelector(state => state.auth.requestSignup);
  const { isAuthenticated } = usePrivateRTACRoute(path, requestSignup);
  const onRender = useCallback((props) => isAuthenticated ? <Component {...props} /> : null, [isAuthenticated]);
  return <Route path={path} render={onRender} {...rest} />;
}