import { Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material";

export default function ConfirmDialog(props) {
  const { onCancel, onConfirm, title, description, open, confirmText, cancelText, confirmColor, content } = props;

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
        {content}
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1} sx={{ display: { xs: "block", sm: "none" } }}>
          <Grid item xs={12}>
            <Button onClick={onCancel} color="neutral" variant="outlined" fullWidth>
              {cancelText || "Cancel"}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button onClick={onConfirm} variant="contained" color={confirmColor || "primary"} autoFocus fullWidth>
              {confirmText || "Confirm"}
            </Button>
          </Grid>
        </Grid>
        <Button onClick={onCancel} color="neutral" variant="outlined" sx={{ display: { xs: "none", sm: "block" } }}>
          {cancelText || "Cancel"}
        </Button>
        <Button onClick={onConfirm} variant="contained" color={confirmColor || "primary"} autoFocus sx={{ display: { xs: "none", sm: "block" } }}>
          {confirmText || "Confirm"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}