import * as ActionTypes from "../actions/actionTypes";

const initialState = {
  query: "",
  selectHandles: [],
  inboundLinksPanelOpen: false
};

export default function mapReducer(state = initialState, action) {
  const payload = action.payload;
  switch (action.type) {
    case ActionTypes.SEARCH_SET_QUERY: return { ...state, query: payload.query || "" };

    case ActionTypes.SEARCH_SELECT_ADD: return { ...state, selectHandles: state.selectHandles.concat(payload) };
    case ActionTypes.SEARCH_SELECT_FINISH:
    case ActionTypes.SEARCH_SELECT_CANCEL: 
      return { ...state, selectHandles: state.selectHandles.filter((_, i) => i !== 0) };

    case ActionTypes.SEARCH_SET_INBOUND_LINKS_PANEL: return { ...state, inboundLinksPanelOpen: payload.open };

    default: return state;
  }
};
