import { useMemo, useState, useCallback } from "react";
import { Divider, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import Scrollbar from "../../atoms/Scrollbar";
import { Header, Footer } from "./PageComponents";
import usePageView from "../../../hooks/usePageView";
import PageSection from "./common/PageSection";
import SocialProof from "./common/SocialProof";
import HeaderSection from "./common/HeaderSection";
import { MAX_WIDTH, SECTION_SPACING, WIDTH } from "./common/constants";
import FeaturesSection from "./common/FeaturesSection";
import EmbedSection from "./common/EmbedSection";
import TryTodaySection from "./common/TryTodaySection";
import UseCaseSection from "./common/UseCaseSection";
import AINextSection from "./common/AINextSection";

const useStyles = makeStyles(theme => ({
  root: {
    background: "#F6F9FC"
  },
  grid: {
    position: "relative",
    width: "100%",
    paddingTop: "64px",
    height: "auto",
    minHeight: "100%"
  },
  width: {
    position: "relative",
    maxWidth: `${MAX_WIDTH} !important`,
    width: WIDTH,
  },
  margin: {
    marginTop: SECTION_SPACING,
    marginBottom: SECTION_SPACING
  },
  divider: {
    position: "relative",
    width: `calc(${WIDTH} - ${theme.spacing(6)})`,
    maxWidth: `calc(100% - ${theme.spacing(7)})`
  },
  link: {
    color: "inherit"
  }
}));

export default function LandingPage() {
  const classes = useStyles();
  const [scrollTop, setScrollTop] = useState(true);
  const [reachedBottom, setReachedBottom] = useState(false);
  usePageView();

  const onScroll = useCallback((top, bottom) => {
    setScrollTop(top);
    if (bottom && !reachedBottom && !!window.fathom) {
      setReachedBottom(true);
      window.fathom.trackGoal("EFFRBUEV", 0);
    }
  }, [setScrollTop, setReachedBottom, reachedBottom]);

  return (
    <Scrollbar onScrollStateChange={onScroll} className={classes.root}>
      <Header scrollTop={scrollTop} />
      {
        useMemo(() => (
          <Grid container className={classes.grid} direction="column" alignItems="center" justifyContent="space-between">

            <HeaderSection
              title="Note taking meets mind mapping"
              description="Emberly combines mind-maps and note-taking into one powerful tool. It lets you store notes, files, and bookmarks inside nodes to organize your thoughts and ideas efficiently."
              video="/assets/home/landing_page/hero/knowledge-map-example.mp4"
              poster="/assets/home/landing_page/hero/knowledge-map-example.png"
            />

            <Grid container item className={`${classes.width} ${classes.margin}`} direction="row" justifyContent="space-between" alignItems="flex-start" spacing={3}>
              <SocialProof
                quote="“I have spent way too many years learning stuff that has just faded completely away, and Emberly is a potent insurance policy against that”"
                name="Michael Day"
              />
              <SocialProof
                quote="“I make a lot of mindmaps for my classes, and ember.ly trumps all other tools I’ve used. This is an amazing tool for students!”"
                name="Jingxiang Mo"
              />
              <SocialProof
                quote="“Emberly helped me a lot to get organized in my learning process! I can’t do without it anymore.”"
                name="Eban"
              />
            </Grid>

            <Divider className={classes.divider} />

            <PageSection
              title="Collect everything in one place"
              description="Content spread all over aren’t helpful. In Emberly, you keep your bookmarks, files, and notes together - as they belong."
              image="/assets/home/landing_page/collect_everything_section/everything_1.mp4"
            />

            <PageSection
              title="Organize visually"
              description="Unlike folders and tags, where things quickly become chaotic, Emberly uses the power of mind maps to make things easy to re-find."
              image="/assets/home/landing_page/organize_visually/visually_400.mp4"
            />

            <UseCaseSection
              title="Built for many use cases"
              description="Emberly helps solve problems for all sorts of people. These are a few. "
              features={[
                {
                  name: "Knowledge library",
                  title: "Keep your knowledge organized and everlasting",
                  description: "Too often things we learn one year, is forgotten the next. With an knowledge library in Emberly you can quickly re-find resources and notes, and fresh up on old topics quickly.",
                  image: "/assets/home/landing_page/use_case_section/knowledge_library.png"
                },
                {
                  name: "CV-map",
                  title: "Jack of all trades, master of some",
                  description: "A traditional CV only reveals a fraction of your skillset. Don't leave yourself at a disadvantage. Instead, create a map of your skills to show your true versatility.",
                  image: "/assets/home/landing_page/use_case_section/cv_map.png",
                  link: "https://blog.ember.ly/use-a-skill-tree-in-your-next-interview/"
                },
                {
                  name: "Manage belongings",
                  title: "Manage everything you own with a mind-map",
                  description: "The things we own come with a lot of information. Receipts, user manuals, model numbers, and maintenance instructions. Use an Emberly map to keep track of it all.",
                  image: "/assets/home/landing_page/use_case_section/manage_belongings.png",
                  link: "https://blog.ember.ly/emberly-for-belongings/"
                },
                {
                  name: "Share content",
                  title: "Share your curated content, beautifully",
                  description: "Embed an interactive map in your website. Great for sharing curated content with your audience. ",
                  image: "/assets/home/landing_page/use_case_section/share_content.png",
                  link: "https://blog.ember.ly/master-design-thinking-with-these-curated-resources/",
                  linkName: "Open example"
                },
                {
                  name: "Team Wiki",
                  title: "Keep project resources available for all",
                  description: "A project wiki is not supposed to be chaos. In Emberly you can collaborate on a shared map, creating a valuable team library, where documents actually are found and used. ",
                  image: "/assets/home/landing_page/use_case_section/team_wiki.png"
                },
                {
                  name: "Learn topics",
                  title: "Make what you learn stick",
                  description: "Create a map to vizualize and evolve your understanding of any concept or topic. And as a bonus, you can keep your notes and resources easily re-findable inside it.",
                  image: "/assets/home/landing_page/use_case_section/learn_topics.png"
                }
              ]}
            />

            <AINextSection
              aiTitle="AI-enhanced learning"
              aiDescription="The Power of AI ready to enhance your learning, and create more."
            />

            <EmbedSection
              title="Give Emberly a try right now"
              description="This map right here is interactable. Try clicking around. "
              src="https://ember.ly/map/bd86fb692e98423d899e322e300030d9/5eda84dc2e5350000105b252?embed=true"
            />

            <FeaturesSection
              title="Powerful, alone or together"
              description="Emberly has the features to make things simple"
              features={[
                {
                  title: "Collaborative Editing",
                  description: "Share maps, and work realtime with others.",
                  image: "/assets/home/landing_page/feature_section/sharing.png"
                },
                {
                  title: "Bi-directional links",
                  description: "Create an interconnected web of thoughts.",
                  image: "/assets/home/landing_page/feature_section/bi_directional_link.png"
                },
                {
                  title: "Browser plug-in",
                  description: "Save bookmarks to Emberly with one click.",
                  image: "/assets/home/landing_page/feature_section/webclipper.png"
                }
              ]}
            />

            <TryTodaySection
              title="Try Emberly today"
              description={(
                <>
                  Interested? It’s free to get started. Or perhaps you are curious about the <Link className={classes.link} to="/pricing">pricing</Link>?
                </>
              )}
            />

            <Footer />
          </Grid>
        ), [classes])
      }
    </Scrollbar>
  );
}

// TODO change embed section link to /map/ instead of /sapling/ before deploy.


