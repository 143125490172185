import { useState, useRef, useEffect } from "react";
import { Divider, Typography, Grid, ButtonBase, Avatar, CircularProgress, Collapse, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Check as CheckIcon } from "@mui/icons-material";
import { useRTACAuth, useRTACProfile, useRTACUserAvatarUrl } from "@emberly/dataplane";
import Scrollbars from "react-custom-scrollbars-2";
import { useHistory } from "react-router";

const useStyles = makeStyles(theme => ({
  root: {
    position: "absolute",
    width: "100%",
    minHeight: "100%",
    background: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  grid: {
    width: "360px",
    textAlign: "center",
    maxWidth: `calc(100vw - ${theme.spacing(6)})`
  },
  avatar: {
    marginBottom: theme.spacing(2),
    width: "46px",
    height: "46px"
  },
  declineButton: {
    marginRight: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(3)})`
  },
  acceptButton: {
    marginLeft: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(3)})`
  },
  logoutButton: {
    textDecoration: "underline"
  },
  divider: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2)
  },
  description: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  declinedDescription: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  buttonIcon: {
    color: "inherit",
    height: "16px",
    width: "16px"
  }
}));

export default function InviteView(props) {
  const { invite } = props;
  const classes = useStyles();
  const history = useHistory();
  const { profile } = useRTACProfile();
  const { logout } = useRTACAuth();
  const { avatarUrl } = useRTACUserAvatarUrl(invite.senderPublicId);
  const [accepting, setAccepting] = useState(false);
  const [declining, setDeclining] = useState(false);
  const mounted = useRef(true);

  useEffect(() => {
    return () => {
      mounted.current = false;
    }
  }, []);

  const onAccept = () => {
    setAccepting(true);
    invite.accepted = true;
    invite.commit().then(
      () => { // accepted
        if (mounted.current) {
          setAccepting(false);
        }
      },
      (err) => { // rejected
        if (mounted.current) {
          setAccepting(false);
        }
      }
    );
  };

  const onDecline = () => {
    setDeclining(true);
    invite.declined = true;
    invite.commit().then(
      () => { // accepted
        if (mounted.current) {
          setDeclining(false);
        }
      },
      (err) => { // rejected
        if (mounted.current) {
          setDeclining(false);
        }
      }
    );
  };

  const buttonsDisabled = invite.declined || invite.accepted || declining || accepting;

  return (
    <Scrollbars autoHide hideTracksWhenNotNeeded>
      <Grid container className={classes.root} justifyContent="center" alignItems="center">
        <Grid className={classes.grid} container direction="row" justifyContent="center" alignItems="center">
          <Grid item>
            <Avatar className={classes.avatar} alt={invite.senderUsername} src={avatarUrl} variant="rounded" />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">Accept invitation?</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1" className={classes.description}>
              {invite.senderUsername} shared the map “{invite.contextName}” with you. Do you want to join?
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="neutral"
              className={classes.declineButton}
              onClick={onDecline}
              disabled={buttonsDisabled}
              endIcon={
                declining ? (
                  <CircularProgress variant="indeterminate" color="inherit" size="16px" />
                ) : (
                  invite.declined ? (
                    <CheckIcon size="inherit" className={classes.buttonIcon} />
                  ) : null
                )
              }
            >
              {declining || !invite.declined ? "Decline" : "Declined"}
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.acceptButton}
              disabled={buttonsDisabled}
              onClick={onAccept}
              endIcon={
                accepting ? (
                  <CircularProgress variant="indeterminate" color="inherit" size="16px" />
                ) : (
                  invite.accepted ? (
                    <CheckIcon size="inherit" className={classes.buttonIcon} color="inherit" />
                  ) : null
                )
              }
            >
              Accept
            </Button>
          </Grid>

          <Collapse in={!declining && invite.declined}>
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary" className={classes.declinedDescription}>
                Declined successfully. If you declined by accident. Ask {invite.senderUsername} to send you a new invitation.
              </Typography>
            </Grid>
          </Collapse>

          <Collapse in={!declining && invite.declined}>
            <Grid item xs={12}>
              <Button
                onClick={() => history.push("/app/home")}
                variant="contained"
                color="primary"
              >
                Open Emberly
              </Button>
            </Grid>
          </Collapse>

          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary">
              You are logged in as {profile?.email || "..."}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <ButtonBase onClick={() => logout()}>
              <Typography className={classes.logoutButton} variant="body2" color="textSecondary">
                Log out
              </Typography>
            </ButtonBase>
          </Grid>

        </Grid>
      </Grid >
    </Scrollbars>
  );
}
