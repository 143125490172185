import { useState } from "react";
import { ButtonBase, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { MAX_WIDTH, SECTION_SPACING, WIDTH } from "./constants";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: SECTION_SPACING,
    marginBottom: SECTION_SPACING,
    maxWidth: MAX_WIDTH,
    width: WIDTH,
  },
  textItem: {
    marginTop: theme.spacing(-0.75)
  },
  header: {
    marginBottom: theme.spacing(0.5)
  },
  image: {
    width: "100%",
    height: "auto",
    boxShadow: theme.shadows[3],
    borderRadius: theme.shape.borderRadius,
    "@media (max-width: 900px)": {
      marginTop: theme.spacing(0)
    }
  },
  feature: {
    borderRadius: theme.shape.borderRadiusMd,
    background: theme.palette.grey[500_8],
    padding: theme.spacing(1.5),
    marginTop: theme.spacing(0.75),
    marginBottom: theme.spacing(0.75),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    textAlign: "left"
  },
  featureSelected: {
    background: theme.palette.grey[500_32],
  }
}));

export default function FeaturesSection(props) {
  const classes = useStyles();
  const { title, description, features } = props;
  const [selected, setSelected] = useState(0);

  const selectedFeature = features[selected];

  return (
    <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" className={classes.root} spacing={3}>

      <Grid item container xs={12} direction="column">
        <Typography variant="p_header" className={classes.header}>{title}</Typography>
        <Typography variant="p_subtitle" color="textSecondary">{description}</Typography>
      </Grid>

      <Grid item container xs={12} md={3} direction="column" className={classes.textItem}>
        {features.map((feature, i) => (
          <ButtonBase key={i} className={`${classes.feature} ${i === selected ? classes.featureSelected : ""}`} onClick={() => setSelected(i)}>
            <Typography variant="subtitle1" className={classes.header}>{feature.title}</Typography>
            <Typography variant="body2" color="textSecondary">{feature.description}</Typography>
          </ButtonBase>
        ))}
      </Grid>

      <Grid item xs={12} md={9}>
        <img src={selectedFeature.image} alt={selectedFeature.title} className={classes.image} loading="lazy" />
      </Grid>

    </Grid>
  );
}
