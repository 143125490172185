import { Auth0Client } from "@auth0/auth0-spa-js";
import axios from "axios";
import { Task, Retry, AuthenticationProvider } from "@emberly/rtac";
import * as AuthApi from "./api/auth";

const DEFAULT_REDIRECT_CALLBACK = (appState, history = null) => {
  const query = !!appState.searchParams ? appState.searchParams : "";
  const targetUrl = !!appState && !!appState.targetUrl ? appState.targetUrl : "";
  window.history.replaceState({}, document.title, (targetUrl || window.location.pathname) + query);
  if (history !== null) {
    history.replace({
      pathname: (targetUrl || window.location.pathname),
      search: query
    });
  }
}

export default class EmberlyAuthenticationProvider extends AuthenticationProvider {

  constructor(config, history, allowRedirect = true) {
    super();
    this.onRedirectCallback = DEFAULT_REDIRECT_CALLBACK;
    this.history = history;
    try {
      this.auth0 = new Auth0Client({
        ...config,
        cacheLocation: window.location.pathname === "/extension/login" ? "memory" : "localstorage",
        useRefreshTokens: true
      });
    } catch (err) {
      console.log(err);
    }
    this.isAuthenticated = false;
    this.refreshTimer = null;
    this.user = null;
    this.setupTask = new Task();

    window.refreshAuth = async () => {
      const token = await this.auth0.getTokenSilently();
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    };

    window.forceRefreshAuth = async () => {
      if (allowRedirect) {
        await this.auth0.loginWithRedirect({
          redirect_uri: window.location.origin + "/app",
          appState: { targetUrl: window.location.pathname, searchParams: window.location.search }
        });
      } else {
        this.setAuthenticated(false);
      }
    };
  }

  async tryAuthenticate() {
    if (!this.isEmbed()) {
      if (window.location.search.includes("code=")) {
        try {
          const { appState } = await this.auth0.handleRedirectCallback();
          this.onRedirectCallback(appState, this.history);
        } catch (err) {
          console.log("auth0 error", err);
          window.location.replace(window.location.origin);
        }
      }

      if (!this.shouldWaitForAuth()) {
        this.context.setLoaded();
      }

      try {
        const initToken = await this.auth0.getTokenSilently();
        axios.defaults.headers.common["Authorization"] = `Bearer ${initToken}`;
        const user = await this.auth0.getUser();
        this.setDefaultGroupName(user.sub);
        this.setAuthenticated();
      } catch {
        this.setAuthenticated(false);
      }
    } else {
      this.setAuthenticated(false);
    }

    this.context.setLoaded();
    this.setupTask.complete();
  }

  onAuthenticated() {
    clearInterval(this.refreshTimer);

    this.refreshTimer = setInterval(async () => {
      try {
        console.log("refreshing credentials");
        const token = await this.auth0.getTokenSilently();
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      } catch (err) {

        await this.auth0.loginWithRedirect({
          redirect_uri: window.location.origin + "/app",
          appState: { targetUrl: window.location.pathname, searchParams: window.location.search }
        });
      }
    }, 1000 * 60 * 60 * 2);

    this.setWaitForAuth();
  }

  requiresAuthentication(groupName) {
    return !groupName;
  }

  async forceAuthentication(path = "", requestSignup = false, loginHint = null) {
    if (this.hasAuthentication()) {
      return true;
    } else {
      await this.setupTask.wait();
      if (this.hasAuthentication()) {
        return true;
      } else {
        await this.auth0.loginWithRedirect({
          redirect_uri: window.location.origin + path,
          appState: { targetUrl: window.location.pathname, searchParams: window.location.search },
          screen_hint: requestSignup ? "signup" : undefined,
          login_hint: loginHint || ""
        });
        return false;
      }
    }
  }

  async useAuthentication() {
    if (this.hasAuthentication()) {
      return true;
    }
    await this.setupTask.wait();
    return this.hasAuthentication();
  }

  async getAccessToken() {
    try {
      return await this.auth0.getTokenSilently();
    } catch (err) {
      console.log("error fetching access token", err);

      await this.auth0.loginWithRedirect({
        redirect_uri: window.location.origin + "/app",
        appState: { targetUrl: window.location.pathname, searchParams: window.location.search }
      });

      try {
        return await this.auth0.getTokenSilently();
      } catch (err) {
        return null;
      }
    }
  }

  async loginWithRedirect(data) {
    await this.auth0.loginWithRedirect(data);
  }

  logout(...p) {
    this.unsetWaitForAuth();
    this.auth0.logout(...p);
    axios.defaults.headers.common["Authorization"] = "";

    try {
      for (const key in  localStorage) {
        if (key.indexOf("auth") !== -1) {
          localStorage.removeItem(key);
        }
      }
    } catch (err) {
    }
  }

  shouldWaitForAuth() {
    try {
      const path = window.location.pathname;
      if (path.startsWith("/app") || path.startsWith("/map")) return true;
      return window.localStorage.getItem("auth_wait") === "yes";
    } catch {
      return true;
    }
  }

  setWaitForAuth() {
    try {
      window.localStorage.setItem("auth_wait", "yes");
    } catch { }
  }

  unsetWaitForAuth() {
    try {
      window.localStorage.removeItem("auth_wait");
    } catch { }
  }

  destroy() {
    super.destroy();
    clearInterval(this.refreshTimer);
  }

  async fetchProfileById(publicUserId) {
    try {
      const result = await Retry.Axios(async () => await axios(`/api/client/${publicUserId}`));
      return result.data;
    } catch (err) {
      console.log("Error loading profile", err);
      return null;
    }
  }

  async fetchProfile() { // TODO move this to interface as a fetch?
    try {
      const result = await Retry.Axios(async () => await axios(`/api/client`));
      return result.data;
    } catch (err) {
      console.log("Error loading profile", err);
      return null;
    }
  }

  isEmbed() {
    try {
      const currentLocation = new URLSearchParams(window.location.search);
      return window.self !== window.top || currentLocation.has("embed");
    } catch (e) {
      return true;
    }
  }

  // Profile updates //

  async updateProfile(fullName, onboarded, avatar, resetAvatar, openLastVisited, affiliateEnabled, affiliateId, theme) {

    try {
      this.context.setUpdatingProfile(true);
      const profile = await AuthApi.UpdateProfile(fullName, onboarded, avatar, resetAvatar, openLastVisited, affiliateEnabled, affiliateId, theme);
      this.context.setUpdatedProfile(profile);
    } catch (err) {
      console.log("err patch profile", err);
      this.context.setUpdatingProfile(false);
      this.context?.notify("Error updating profile");
    }
  }


}