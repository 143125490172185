
// App Actions
export const SET_CARD_SWIPE_ACTIVE = "SET_CARD_SWIPE_ACTIVE";
export const SET_CARD_APPROACHING_FULLSCREEN = "SET_CARD_APPROACHING_FULLSCREEN";
export const SET_CONTEXT_CARD = "SET_CONTEXT_CARD";
export const REPORT_CARD_POSITION = "REPORT_CARD_POSITION";
export const SET_NODEVIEW_TAB_INDEX = "SET_NODEVIEW_TAB_INDEX";
export const SET_SEARCHVIEW_TAB_INDEX = "SET_SEARCHVIEW_TAB_INDEX"; 
export const SET_FILTER = "SET_FILTER";
export const SET_NOTES_FULLSCREEN = "SET_NOTES_FULLSCREEN";
export const COPY_TO_CLIPBOARD_SUCCESS = "COPY_TO_CLIPBOARD_SUCCESS";
export const COPY_TO_CLIPBOARD_ERROR = "COPY_TO_CLIPBOARD_ERROR";
export const NOTIFICATION_DISPLAY = "NOTIFICATION_DISPLAY";
export const SET_CAN_SWIPE_ON_CONTENT = "SET_CAN_SWIPE_ON_CONTENT";
export const SET_DRAGOVER_ACTIVE = "SET_DRAGOVER_ACTIVE";
export const SET_MINIMUM_API_VERSION_BREACHED = "SET_MINIMUM_API_VERSION_BREACHED";

export const ADD_CONFIRM_DIALOG = "ADD_CONFIRM_DIALOG";
export const CONFIRM_DIALOG_CANCEL = "CONFIRM_DIALOG_CANCEL";
export const CONFIRM_DIALOG_CONFIRM = "CONFIRM_DIALOG_CONFIRM";

export const WORKER_INIT = "WORKER_INIT";
export const UPDATE_AVAILABLE = "UPDATE_AVAILABLE";

export const SET_TOURS = "SET_TOURS";
export const SET_APP_TOUR = "SET_APP_TOUR";
export const SET_APP_TOUR_INDEX = "SET_APP_TOUR_INDEX";

export const HIDE_UPGRADE_ALERT = "HIDE_UPGRADE_ALERT";

// Search Actions
export const SEARCH_BEGIN_LOAD = "SEARCH_BEGIN_LOAD";
export const SEARCH_FINISH_LOAD = "SEARCH_FINISH_LOAD";
export const SEARCH_ERROR_LOAD = "SEARCH_ERROR_LOAD";
export const SEARCH_SET_QUERY = "SEARCH_SET_QUERY";

export const SEARCH_SELECT_ADD = "SEARCH_SELECT_ADD";
export const SEARCH_SELECT_CANCEL = "SEARCH_SELECT_CANCEL";
export const SEARCH_SELECT_FINISH = "SEARCH_SELECT_FINISH";

export const SEARCH_SET_INBOUND_LINKS = "SEARCH_SET_INBOUND_LINKS";
export const SEARCH_SET_INBOUND_LINKS_PANEL = "SEARCH_SET_INBOUND_LINKS_PANEL";

// Auth Actions
export const AUTH_REQUEST_SIGNUP = "AUTH_REQUEST_SIGNUP";

export const AUTH_RESET_PASSWORD_BEGIN = "AUTH_RESET_PASSWORD_BEGIN";
export const AUTH_RESET_PASSWORD_ERROR = "AUTH_RESET_PASSWORD_ERROR";
export const AUTH_RESET_PASSWORD_FINISH = "AUTH_RESET_PASSWORD_FINISH";

export const AUTH_SUBSCRIBE_PREMIUM_BEGIN = "AUTH_SUBSCRIBE_PREMIUM_BEGIN";
export const AUTH_SUBSCRIBE_PREMIUM_CANCEL = "AUTH_SUBSCRIBE_PREMIUM_CANCEL";
export const AUTH_SUBSCRIBE_PREMIUM_FINISH = "AUTH_SUBSCRIBE_PREMIUM_FINISH";
export const AUTH_SUBSCRIBE_PREMIUM_ERROR = "AUTH_SUBSCRIBE_PREMIUM_ERROR";

export const AUTH_UPDATE_PREMIUM_BEGIN = "AUTH_UPDATE_PREMIUM_BEGIN";
export const AUTH_UPDATE_PREMIUM_FINISH = "AUTH_UPDATE_PREMIUM_FINISH";
export const AUTH_UPDATE_PREMIUM_ERROR = "AUTH_UPDATE_PREMIUM_ERROR";

export const AUTH_UPDATE_PREMIUM_PLAN_BEGIN = "AUTH_UPDATE_PREMIUM_PLAN_BEGIN";
export const AUTH_UPDATE_PREMIUM_PLAN_FINISH = "AUTH_UPDATE_PREMIUM_PLAN_FINISH";
export const AUTH_UPDATE_PREMIUM_PLAN_ERROR = "AUTH_UPDATE_PREMIUM_PLAN_ERROR";

export const AUTH_ONBOARDING_BEGIN = "AUTH_ONBOARDING_BEGIN";
export const AUTH_ONBOARDING_FINISH = "AUTH_ONBOARDING_FINISH";

export const AUTH_INVOICES_LOAD_BEGIN = "AUTH_INVOICES_LOAD_BEGIN";
export const AUTH_INVOICES_LOAD_FINISH = "AUTH_INVOICES_LOAD_FINISH";
export const AUTH_INVOICES_LOAD_ERROR = "AUTH_INVOICES_LOAD_ERROR";

export const AUTH_PLANS_LOAD_BEGIN = "AUTH_PLANS_LOAD_BEGIN";
export const AUTH_PLANS_LOAD_FINISH = "AUTH_PLANS_LOAD_FINISH";
export const AUTH_PLANS_LOAD_ERROR = "AUTH_PLANS_LOAD_ERROR";

// Tree actions
export const CENTER_VIEW = "CENTER_VIEW";
export const REGISTER_VIEW = "REGISTER_VIEW";
export const PAN_TO_NODE = "PAN_TO_NODE";
export const TREE_SET_DRAG_ENABLED = "TREE_SET_DRAG_ENABLED";
export const TREE_ON_LOAD = "TREE_ON_LOAD";

export const TREE_ADD_CREATE_NODE_DIALOG = "TREE_ADD_CREATE_NODE_DIALOG";
export const TREE_CREATE_NODE_DIALOG_CONFIRM = "TREE_CREATE_NODE_DIALOG_CONFIRM";
export const TREE_CREATE_NODE_DIALOG_CANCEL = "TREE_CREATE_NODE_DIALOG_CANCEL";
export const TREE_SET_ACTIVE_NODE_ID = "TREE_SET_ACTIVE_NODE_ID";
export const TREE_UPLOAD_CENTER_IMAGE_BEGIN = "TREE_UPLOAD_CENTER_IMAGE_BEGIN";
export const TREE_UPLOAD_CENTER_IMAGE_FINISH = "TREE_UPLOAD_CENTER_IMAGE_FINISH";
export const TREE_UPLOAD_CENTER_IMAGE_ERROR = "TREE_UPLOAD_CENTER_IMAGE_ERROR";

