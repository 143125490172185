import { useMemo } from "react";
import { useGroupName } from "@emberly/dataplane";
import { useLocation } from "react-router";

export function useRootUrl() {
  const location = useLocation();
  return useMemo(() => getRootUrl(location.pathname), [location.pathname]);
}

export function useGroupUrl() {
  const groupName = useGroupName();

  if (groupName.indexOf("/") !== -1) {
    return `/map/${groupName}`;
  }

  return "";
}

export function getRootUrl(path) {
  const pathname = path || window.location.pathname;

  if (pathname.startsWith("/app")) {
    return "/app";
  } else if (pathname.startsWith("/map/")) {
    const split = pathname.split("/");
    if (split.length >= 4) {
      return `/map/${split[2]}/${split[3]}`;
    }
  }

  return "";
}


export function useNotesEditor() {
  const location = useLocation();
  const groupUrl = useGroupUrl();


  return useMemo(() => {
    if (!groupUrl) {
      return { active: false, parentId: "" };
    }

    const path = location.pathname.substring(groupUrl.length + 1);
    const type = extractType(path);

    if (!type) {
      return { active: false, parentId: "" };
    }

    const parentId = extractId(type, path, groupUrl);

    if (!parentId) {
      return { active: false, parentId: "" }
    }

    return {
      hasEditor: true,
      parentId,
    };
  }, [location.pathname, groupUrl]);
}

function extractId(type, path, groupUrl) {
  if (type === "node" || type === "resource") {
    const split = path.split("/");
    return split.length > 1 ? type + "-" + split[1] : null;
  } else if (type === "description") {
    const split = groupUrl.split("/");
    return split.length > 3 ? type + "-" + split[3] : null;
  }
}

function extractType(path) {
  if (path.startsWith("node/")) {
    return "node";
  } else if (path.startsWith("resource/")) {
    return "resource";
  } else if (path.startsWith("edit")) {
    return "description";
  }
  return null;
}