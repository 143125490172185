
export default function Dialog(theme) {
  return {
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: "4px",
          padding: "0px",
        },
        paperFullScreen: {
          borderRadius: "0px"
        }
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: "24px",
          paddingBottom: "8px"
        }
      },
  
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: "24px",
          paddingTop: "24px"
        }
      },
  
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: "24px",
          paddingTop: 0,
          paddingBottom: 0
        }
      },
  
    }
  };
}
