import * as ActionTypes from "./actionTypes";
import { getRootUrl } from "../hooks/useApp";

// variables
let renderer = null;
let dragEnabled = false;

export function RegisterView(view) {
  return (dispatch, getState) => {
    renderer = view;
    const nodeId = getState().tree.activeNodeId;

    if (!!renderer && !!renderer.nodeEventHandler && !!nodeId) {
      renderer.nodeEventHandler.setActiveNodeId(nodeId);
    }

    if (dragEnabled) {
      renderer.setDragEnabled(dragEnabled);
    }

    dispatch({
      type: ActionTypes.REGISTER_VIEW
    });
  }
}

export function HandleLoad(tree, skipPanning = false) {
  return (dispatch, getState) => {
    const state = getState();
    const nodeId = state.tree.activeNodeId;
    const cardLimits = state.app.cardLimits;
    const rootNode = renderer.isFullTree() ? renderer.root : renderer.getBranchRoot();
    const activeNode = !!nodeId && nodeId !== "inbox" ? renderer.getNodeById(nodeId) : null;
    const skipAnimations = activeNode !== null && !activeNode.isRoot;
    
    // here we skip panning when loading maps, but panning for 300ms when switching between maps.
    tree.panTo(activeNode || rootNode, cardLimits[0], cardLimits[1], skipAnimations, skipPanning ? 10 : 300); 

    dispatch({
      type: ActionTypes.TREE_ON_LOAD,
      payload: { contextId: renderer.contextId }
    });
  };
}

export function CenterView() {
  return (dispatch, getState) => {
    const state = getState();
    const node = renderer.isFullTree() ? renderer.root : renderer.getBranchRoot();
    renderer.panTo(node, state.app.cardLimits[0], state.app.cardLimits[1]);
    dispatch({
      type: ActionTypes.CENTER_VIEW
    });
  };
}

export function Zoom(direction) {
  return (dispatch, getState) => {
    const state = getState();
    renderer.zoom(direction, state.app.cardLimits[0], state.app.cardLimits[1]);
    dispatch({
      type: ActionTypes.CENTER_VIEW
    });
  };
}


export function SetActiveNodeId(nodeId = null) {
  if (!!renderer && !!renderer.nodeEventHandler) {
    renderer.nodeEventHandler.setActiveNodeId(nodeId);
  }

  return {
    type: ActionTypes.TREE_SET_ACTIVE_NODE_ID,
    payload: { nodeId }
  };
}

export function PanToNode(node) {
  return (dispatch, getState) => {
    const state = getState();
    renderer.panTo(node, state.app.cardLimits[0], state.app.cardLimits[1]);
    dispatch({
      type: ActionTypes.PAN_TO_NODE,
      payload: node
    });
  };
}

export function PanToActiveNode() {
  return (dispatch, getState) => {
    setTimeout(() => {
      if (!!renderer && !renderer.closed && !!renderer.nodeEventHandler) {
        const node = renderer.nodeEventHandler.activeNodeId === "inbox" ? renderer.root : (renderer.getNodeById(renderer.nodeEventHandler.activeNodeId) || renderer.root);
        const state = getState();
        if (!!node) {
          renderer.panTo(node, state.app.cardLimits[0], state.app.cardLimits[1]);
          dispatch({
            type: ActionTypes.PAN_TO_NODE,
            payload: node
          });
        }
      }
    }, 500);
  };
}

let focusTimer = null;

// This determines what happens when we click a node
export function NodeFocused(node, history, contextId, isMobilePortraitView = false) {
  return (dispatch, getState) => {
    const state = getState();
    const selectActive = state.search.selectHandles.length !== 0;
    const nodeId = node.id;
    if (selectActive) {
      state.search.selectHandles[0].callback("node", node.id, null, contextId);
      dispatch({
        type: ActionTypes.SEARCH_SELECT_FINISH,
        payload: {
          type: "node",
          value: nodeId
        }
      });
    } else { // standard
      clearTimeout(focusTimer);
      const change = renderer.nodeEventHandler?.activeNodeId !== node.id;
      const cardState = state.app.contextCardState;
      renderer.nodeEventHandler.setActiveNodeId(node.id);
      const oldPathName = history?.location?.pathname;

      focusTimer = setTimeout(() => {
      
      // Open card if its current closed
      if (cardState === 0) {
        dispatch({
          type: ActionTypes.SET_CONTEXT_CARD,
          payload: { cardState: 1 }
          });
        } else if (!change && cardState === 1 && isMobilePortraitView) {
          dispatch({
            type: ActionTypes.SET_CONTEXT_CARD,
            payload: { cardState: 2 }
          });
        }
        
        const newPathName = history?.location?.pathname;
        const changedSinceTimer = oldPathName !== newPathName
        const path = `${getRootUrl()}/node/${nodeId}`;
        
        if (path !== history?.location?.pathname && !changedSinceTimer) {
          history.push(path);
        }
      }, 25);
    }
  };
}

export function NodeFocusedDebounce(node, history, skipDebounce = false) {
  return (dispatch) => {
    const nodeId = node.id;
    renderer.nodeEventHandler.setActiveNodeId(nodeId);
    clearTimeout(focusTimer);
    const fn = () => {
      dispatch({
        type: ActionTypes.TREE_SET_ACTIVE_NODE_ID,
        payload: { nodeId }
      });

      history.push(`${getRootUrl()}/node/${nodeId}`);
      if (!skipDebounce) {
        focus();
      }
    };

    if (skipDebounce) {
      fn();
    } else {
      focusTimer = setTimeout(fn, 500);
    }

    if (!skipDebounce) {
      focus();
    }
  };
}

// Enables draggable nodes
export function SetDragEnabled(enabled) {

  dragEnabled = enabled;

  if (!!renderer) {
    renderer.setDragEnabled(enabled);
  }

  return {
    type: ActionTypes.TREE_SET_DRAG_ENABLED,
    payload: { enabled }
  };
}


export function getTree() {
  return renderer;
}

export function getViewportSide(cardLimits) {
  const root = renderer.root;
  if (root.side !== 0) {
    return 0;
  }

  const { left, right } = renderer.viewport.hitArea;
  const width = root.container?.width;

  if (width !== undefined) {
    const cardX = cardLimits[0] / renderer.viewport.scaled;
    const w2 = (width / 2) + 500;
    return (left + w2) > 5000 ? 1 : ((right - w2 - cardX) < 5000 ? -1 : 0);
  }

  return 0;
}

export function AddCreateNodeDialog(nodeId, sidePreference = 0, onCancel = null, onConfirm = null) {
  return (dispatch, getState) => {

    // correct side based on viewport
    if (renderer?.root?.id === nodeId && sidePreference === 0) {
      const cardLimits = getState().app.cardLimits;
      sidePreference = getViewportSide(cardLimits);
    }

    dispatch({
      type: ActionTypes.TREE_ADD_CREATE_NODE_DIALOG,
      payload: {
        nodeId,
        sidePreference,
        onCancel,
        onConfirm
      }
    });
  }
}

export function CreateNodeDialogConfirm() {
  focus();
  return {
    type: ActionTypes.TREE_CREATE_NODE_DIALOG_CONFIRM,
    payload: {}
  }
}

export function CreateNodeDialogCancel() {
  focus();
  return {
    type: ActionTypes.TREE_CREATE_NODE_DIALOG_CANCEL,
    payload: {}
  }
}

function focus() {
  document.activeElement?.blur();
  setTimeout(() => {
    if (document.activeElement?.tagName !== "INPUT") {
      document.activeElement?.blur();
    }
  },50);
}