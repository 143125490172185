import * as ActionTypes from "../actions/actionTypes";

const initialState = {

  showOnboarding: false,

  requestSignup: false,

  pollForProductId: null,

  invoicesLoading: false,
  invoices: null,
  plansLoading: false,
  plans: null,
  updatingPlan: false,
  updatePlanError: false,
  updatePlanType: null,
  production: window.location.hostname.endsWith("ember.ly"),
  
  paddleOpen: false,

  resettingPassword: false,
  resettingPasswordFinished: false,
  resettingPasswordError: false,
};

export default function mapReducer(state = initialState, action) {
  switch (action.type) {

    // On subscribe
    case ActionTypes.AUTH_SUBSCRIBE_PREMIUM_BEGIN: return { ...state, paddleOpen: true, updatePlanError: false, updatePlanType: 1 };
    case ActionTypes.AUTH_SUBSCRIBE_PREMIUM_CANCEL: return { ...state, paddleOpen: false };
    case ActionTypes.AUTH_SUBSCRIBE_PREMIUM_FINISH: return { ...state, paddleOpen: false, pollForProductId: action.payload.product + "" };
    case ActionTypes.AUTH_SUBSCRIBE_PREMIUM_ERROR: return { ...state, paddleOpen: false, updatePlanError: true };
    
    // Finished onboarding
    case ActionTypes.AUTH_ONBOARDING_BEGIN: return { ...state, showOnboarding: true };
    case ActionTypes.AUTH_ONBOARDING_FINISH: return { ...state, showOnboarding: false };

    // Load Invoices
    case ActionTypes.AUTH_INVOICES_LOAD_BEGIN: return { ...state, invoicesLoading: true };
    case ActionTypes.AUTH_INVOICES_LOAD_FINISH: return { ...state, invoicesLoading: false, invoices: action.payload.invoices };
    case ActionTypes.AUTH_INVOICES_LOAD_ERROR: return { ...state, invoicesLoading: false };

    // Load Paddle Plans
    case ActionTypes.AUTH_PLANS_LOAD_BEGIN: return { ...state, plansLoading: true };
    case ActionTypes.AUTH_PLANS_LOAD_FINISH: return { ...state, plansLoading: false, plans: action.payload.plans };
    case ActionTypes.AUTH_PLANS_LOAD_ERROR: return { ...state, plansLoading: false };

    // Update Paddle Details
    case ActionTypes.AUTH_UPDATE_PREMIUM_BEGIN: return { ...state, updatingPlan: true, paddleOpen: true, updatePlanError: false };
    case ActionTypes.AUTH_UPDATE_PREMIUM_FINISH: return { ...state, updatingPlan: false, paddleOpen: false };
    case ActionTypes.AUTH_UPDATE_PREMIUM_ERROR: return { ...state, updatingPlan: false, paddleOpen: false };

    // Update Paddle Plan
    case ActionTypes.AUTH_UPDATE_PREMIUM_PLAN_BEGIN: return { ...state, updatingPlan: true, updatePlanError: false, updatePlanType: action.payload.type, pollForProductId: action.payload.product + "" };
    case ActionTypes.AUTH_UPDATE_PREMIUM_PLAN_FINISH: return { ...state, updatingPlan: false };
    case ActionTypes.AUTH_UPDATE_PREMIUM_PLAN_ERROR: return { ...state, updatingPlan: false, updatePlanError: true };

    // Reset Password
    case ActionTypes.AUTH_RESET_PASSWORD_BEGIN: return { ...state, resettingPassword: true, resettingPasswordError: false, resettingPasswordFinished: false };
    case ActionTypes.AUTH_RESET_PASSWORD_ERROR: return { ...state, resettingPassword: false, resettingPasswordError: true, resettingPasswordFinished: false };
    case ActionTypes.AUTH_RESET_PASSWORD_FINISH: return { ...state, resettingPassword: false, resettingPasswordError: false, resettingPasswordFinished: true };

    case ActionTypes.AUTH_REQUEST_SIGNUP: return { ...state, requestSignup: true };

    default: return state;
  }
};
