import { useEffect, useMemo, useState } from "react";
import { useCollection } from "@emberly/rtac";

export default function useTags(load = true, groupName = undefined) {
  const { collection } = useCollection("Resource", groupName);
  const [ updateCount, setUpdateCount ] = useState(0);

  useEffect(() => {
    if (collection && load) {
      let mounted = true;
      const fn = () => setUpdateCount(t => t + 1);

      collection.loadTags().then(() => {
        if (mounted) {
          fn();
          collection.onTagsEvent(fn);
        }
      });

      return () => {
        mounted = false;
        collection.offTagsEvent(fn);
      };
    }
  }, [collection, load]);

  // Return data to view
  return useMemo(() => {
    
    return {
      loading: !collection || !collection.tagsLoaded,
      tags: collection?.getTags() || []
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collection, updateCount]);
}