import { useCallback } from "react";
import { Grid, Typography, Button, Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { RequestSignup } from "../../../actions/auth";
import { MAX_WIDTH, WIDTH } from "./common/constants";
import { clearBootPath } from "../../../common/BootHandler";

const useStyles = makeStyles(theme => ({
  gridItem: {
    width: "100%"
  },
  nav: {
    position: "fixed",
    top: 0,
    left: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    transition: "box-shadow 1s",
    zIndex: 1,
    background: "rgba(246,249,252,0.95)",
  },
  navOnScroll: {
    boxShadow: theme.shadows[3]
  },
  content: {
    width: WIDTH,
    maxWidth: MAX_WIDTH
  },
  logoLarge: {
    verticalAlign: "middle",
    display: "inline-block",
    margin: "auto"
  },
  linkImage: {
    height: "100%",
    display: "flex",
    alignItems: "center"
  },
  footerContainer: {
    paddingTop: "48px",
    paddingBottom: "48px",
    background: "#202020"
  },
  footerHeader: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "28px",
    letterSpacing: "0.5px",
    color: "#ffffff"
  },
  footerText: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "28px",
    letterSpacing: "0.5px",
    color: "#ffffff"
  },
  termLink: {
    color: "#F5F7F6",
    textDecoration: "none",
    "&:visited": {
      color: "#F5F7F6",
    }
  },
  link: {
    textDecoration: "none",
    color: theme.palette.neutral.button,
    "&:visited": {
      color: theme.palette.secondary.dark
    }
  },
  linkPlain: {
    textDecoration: "none"
  },
  button: {
    color: theme.palette.text.primary,
  },
  buttonContained: {
    marginLeft: theme.spacing(0.5)
  },
  divider: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    height: "16px"
  },
  hiddenSmUp: {
    "@media (min-width: 900px)": {
      display: "none"
    }
  },
  hiddenSmDown: {
    "@media (max-width: 900px)": {
      display: "none"
    }
  }
}));

const year = new Date().getFullYear();

export function Footer() {
  const classes = useStyles();

  return (
    <Grid item container className={`${classes.gridItem} ${classes.footerContainer}`} justifyContent="center" direction="row">
      <Grid item className={classes.content} container justifyContent="space-evenly" alignItems="flex-start" spacing={3}>
        
        <Grid container item xs={6} sm={6} justifyContent="flex-start">
          <Grid item xs={12}>
            <img src="/icons/logo_white.svg" alt="Emberly Logo in white"></img>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" className={classes.footerText}>© {year} Emberly, AS</Typography>
            <Typography variant="caption" className={classes.termLink}>
              <a href="/policy.html" className={classes.termLink}>Terms </a>
              •
              <a href="/privacy.html" className={classes.termLink}> Privacy </a>
              •
              <a href="/cookie.html" className={classes.termLink}> Cookies</a>
            </Typography>
          </Grid>
        </Grid>

        <Grid container item xs={6} sm={6} justifyContent="flex-end">
          <Grid item>

            <Link to="/about" className={classes.link}>
              <Typography variant="body1" className={classes.footerText}>About us</Typography>
            </Link>

            <a href="https://blog.ember.ly" className={classes.link}>
              <Typography variant="body1" className={classes.footerText}>Blog</Typography>
            </a>

            <a href="https://blog.ember.ly/tag/getting-started/" className={classes.link} target="_blank" rel="noopener noreferrer">
              <Typography variant="body1" className={classes.footerText}>Help Center</Typography>
            </a>

            <a href="https://www.linkedin.com/company/emberly/" className={classes.link} target="_blank" rel="noopener noreferrer">
              <Typography variant="body1" className={classes.footerText}>LinkedIn</Typography>
            </a>

            <a href="https://discord.gg/MHUrSd5" className={classes.link} target="_blank" rel="noopener noreferrer">
              <Typography variant="body1" className={classes.footerText}>Community Chat</Typography>
            </a>

            <a href="https://www.indiehackers.com/product/emberly" className={classes.link} target="_blank" rel="noopener noreferrer">
              <Typography variant="body1" className={classes.footerText}>Indie Hackers</Typography>
            </a>

            <a href="mailto:hi@ember.ly" className={classes.link} target="_blank" rel="noreferrer">
              <Typography variant="body1" className={classes.footerText}>hi@ember.ly</Typography>
            </a>

          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}


export function Header(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onClick = useCallback(() => {
    dispatch(RequestSignup());
    clearBootPath();
    if (!!window.fathom) {
      window.fathom.trackGoal("WIEUXHEM", 0);
    }
  }, [dispatch]);

  const onClickSignIn = useCallback(() => {
    clearBootPath();
  },[])

  return (
    <Grid item container className={`${classes.gridItem} ${classes.nav} ${!props.scrollTop ? classes.navOnScroll : ""}`} direction="row" justifyContent="center">
      <Grid item container className={classes.content} justifyContent="space-between" spacing={3}>
        
        <Grid item>
          <Link to="/" className={classes.linkImage}>
            <img src="/icons/logo_text_2021.svg" height="24px" alt="Emberly logo" className={classes.logoLarge} />
          </Link>
        </Grid>
        
        <Grid item xs container direction="row" justifyContent="flex-end" alignItems="center">
          
            <Link to="/pricing" className={`${classes.linkPlain} ${classes.hiddenSmDown}`}>
              <Button className={classes.button}>Pricing</Button>
            </Link>
            <a href="https://blog.ember.ly" className={`${classes.linkPlain} ${classes.hiddenSmDown}`}>
              <Button className={classes.button}>Blog</Button>
            </a>
            <a href="https://blog.ember.ly/emberly-ai/" className={`${classes.linkPlain} ${classes.hiddenSmDown}`}>
              <Button className={classes.button}>AI Features</Button>
            </a>

            <Divider orientation="vertical" className={`${classes.divider} ${classes.hiddenSmDown}`} />

            <Link to="/app" className={`${classes.linkPlain} ${classes.hiddenSmDown}`}>
              <Button className={classes.button} onClick={onClickSignIn()}>Sign in</Button>
            </Link>
            <Link to="/app" className={`${classes.linkPlain} ${classes.hiddenSmDown}`}>
              <Button className={classes.buttonContained} variant="contained" onClick={onClick}>Sign up</Button>
            </Link>
          
          <Link to="/app" className={`${classes.linkPlain} ${classes.hiddenSmUp}`}>
            <Button className={classes.buttonContained} variant="contained">Sign in</Button>
          </Link>

        </Grid>
        
      </Grid>
    </Grid>
  );
}