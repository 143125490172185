import { useEffect, useRef, useState } from "react";
import { Fade, Slide } from "@mui/material";

const TIMEOUT = { enter: 500, appear: 500 };

export default function AnimateIn(props) {
  const { variant, direction, startVisible } = props;
  const ref = useRef(null);
  const [visible, setVisible] = useState(false || startVisible);

  useEffect(() => {
    if (!!ref.current && !!window.IntersectionObserver) {
      const observer = new IntersectionObserver(entries => setVisible(v => v || entries[0].isIntersecting));
      const element = ref.current;
      observer.observe(element);
      return () => observer.unobserve(element);
    } else if (!window.IntersectionObserver) {
      setVisible(true);
    }
  }, [ref, setVisible]);  

  return variant === "slide" ? (
    <Slide in={visible} ref={ref} timeout={TIMEOUT} direction={direction}>
      {props.children}
    </Slide>
  ) : (
    <Fade in={visible} ref={ref} timeout={TIMEOUT}>
      {props.children}
    </Fade>
  );
}