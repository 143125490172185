import axios from "axios";
import { Retry, Collection } from "@emberly/rtac";
import NodeEntity from "./NodeEntity";

export default class NodeCollection extends Collection {
  constructor(context, contextId) { // TODO fetch shareprefix from context.
    super(context, "Node", contextId);
    this.root = null;
    this.map = null;

    this.getContext().getCollectionInContext("Map", "default").then(t => {
      this.map = t;
    });

    this.getContext().on("OnDuplicated", () => {
      this.syncToRemote();
    });

    // custom events for variant
    this.entityEvents
      .on("moved", (entity) => {
        this.updatePath(entity);
        this.emitBranchEvents(entity);
      })
      .on("deleteBranch", (entity, { sync = true, metadata = null, instanceId = null, historyGroup = null, filter = () => true } = {}) => {
        this.deleteBranch(entity, { sync, metadata, instanceId, historyGroup, filter });
      })
      .on("updated", (entity) => {
        if (!entity.isRoot) {
          this.map?.addModifiedNode(contextId, entity.id);
        }
      })
      .on("created", (entity) => {
        if (!entity.isRoot) {
          this.map?.addModifiedNode(contextId, entity.id);
        }
      })
      .on("updatePath", (entity) => {
        this.updatePath(entity);
      });
  }

  /// --- Processing Changes --- //

  onPatchEntity(data, isUndo = false) {
    const entity = this.getEntityById(data.id);

    if (!entity) return false;

    // TODO add more fields to patch.
    if (data.name !== null && typeof data.name === "string") {
      entity.setName(data.name, { sync: false });
    }
    
    if (data.color !== undefined && data.color !== null) {
      entity.setColor(data.color, { sync: false });
    }

    if (data.depth !== undefined && typeof data.depth === "number") {
      entity.setDepth(data.depth, { sync: false });
    }

    if (data.rating !== undefined && typeof data.rating === "number") {
      entity.setRating(data.rating, { sync: false });
    }

    if (data.isCollapsed !== undefined && typeof data.isCollapsed === "boolean") {
      entity.setIsCollapsed(data.isCollapsed, { sync: false });
    }

    if (typeof data.state === "number") {
      if (isUndo) {
        entity.setState((entity.state & 0b1100) | (data.state & 0b11), { sync: false });
      } else {
        entity.setState(data.state, { sync: false });
      }
    }

    if (typeof data.index === "string") {
      entity.setIndex(data.index, { sync: false });
    }

    if (typeof data.side === "number") {
      entity.setSide(data.side, { sync: false });
    }

    if (!!data.parentId && typeof data.parentId === "string" && !entity.isRoot) {
      if (this.isValidParent(entity, data.parentId)) {
        entity.setParentId(data.parentId, { sync: false, refresh: true });
        this.updatePath(entity);
      } else {
        console.log("received conflicting update");
        entity.setParentId(this.root.id, { sync: true, refresh: true });
        this.updatePath(entity);
        return false;
      }
    }

    return true;
  }

  emitBranchEvents(entity) {
    this.getBranch(entity).forEach(t => this.emitEntityEvent(t.id));
  }

  // -- Diff fields -- //

  getUpdatedFields(oldNode, newNode) {
    return {
      parentId: oldNode.parentId !== newNode.parentId,
      name: oldNode.name !== newNode.name,
      state: oldNode.state !== newNode.state,
      rating: oldNode.rating !== newNode.rating,
      index: oldNode.index !== newNode.index,
      depth: oldNode.depth !== newNode.depth,
      side: oldNode.side !== newNode.side,
      color: oldNode.color !== newNode.color,
      isCollapsed: oldNode.isCollapsed !== newNode.isCollapsed,
      path: !!oldNode.path && !!newNode.path && !(oldNode.path.length === newNode.path.length && oldNode.path.every((v, i) => v === newNode.path[i]))
    };
  }

  canPatch(updatedFields) {
    return (
      updatedFields.isCollapsed ||
      updatedFields.color ||
      updatedFields.name ||
      updatedFields.rating ||
      updatedFields.state ||
      updatedFields.index ||
      updatedFields.parentId ||
      updatedFields.depth ||
      updatedFields.side ||
      updatedFields.path
    );
  }

  // path is ignored here
  canSetDepth(updatedFields) {
    return (
      !!updatedFields.depth &&
      !updatedFields.index &&
      !updatedFields.parentId &&
      !updatedFields.name &&
      !updatedFields.state &&
      !updatedFields.rating &&
      !updatedFields.side &&
      !updatedFields.color &&
      !updatedFields.isCollapsed
    );
  }

  getSiblings(id) {
    const entity = this.getEntityById(id);
    if (!entity) return [];

    if (entity.parentId === this.root.id) {
      return [...this.getParentMap(entity.parentId || "root").values()].filter(t => t.side === entity.side);
    } else {
      return [...this.getParentMap(entity.parentId || "root").values()];
    }
  }

  onBeforeCreatingEntity(entity) {
    if (entity.parentId === this.root.id) {
      if (entity.side === 0) {
        const largestSide = [...this.getParentMap(this.root.id).values()].reduce((a, b) => a + Number(b.side), 0);
        entity.side = Math.sign(largestSide) === 1 ? -1 : 1;
      }
    }
    
    if (!!entity.parentId) {
      const parent = this.getEntityById(entity.parentId);
      entity.depth = (parent?.depth || 0) + 1;
    }
  }

  /// --- Fetch and Refresh --- ///

  updatePath(entity) {
    let path = [];
    let parent = this.getEntityById(entity.parentId);

    while (parent !== null && parent.parentId !== null) {
      path.unshift(parent.name);
      parent = this.getEntityById(parent.parentId);
    }

    entity.path = path;
  }

  async fetchEntityState(id) {
    // todo this one fetches state of loaded topics.
    try {
      const res = await Retry.Axios(async () => await axios(`/api/node/${this.sharePrefix}${this.contextId}/${id}`), 6);
      return { data: res.data, success: !!res.data };
    } catch (err) {
      console.log("error fetching entity", err, err ? err.status : null, err && err.response ? err.response.status : null);
      return { data: null, success: false };
    }
  }

  async fetchEverything() {
    return await this.fetchRemoteState();
  }

  canFetchEntity() {
    return true;
  }

  canFetchEverything() {
    return true;
  }

  async fetchRemoteState() {
    try {
      const res = await Retry.Axios(async () => await axios(`/api/node/${this.sharePrefix}${this.contextId}`), 6);
      return { list: res.data.nodes, success: true };
    } catch (err) {
      console.log("error fetching tree", err, err ? err.status : null, err && err.response ? err.response.status : null);
      return { list: [{ id: "root", name: "Root", depth: 0, side: 0, index: "a0", color: 0 }], success: false };
    }
  }

  async getPotentialChildren(node) {
    try {
      const query = {
        path: node.isRoot ? [] : node.getPath(),
        children: node.parent === null ? node.leftTree.children.map(child => child.name).concat(node.rightTree.children.map(child => child.name)) : node.children.map(child => child.name)
      };

      const req = await axios.post("/api/node/context/children", query);

      return req.data;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  // crud methods //

  getBranch(entity, filter) {
    const entities = [];
    const buffer = [entity];
    const useFilter = typeof filter === "function";

    while (buffer.length !== 0) {
      let parent = buffer.shift();

      if (useFilter && !filter(parent)) {
        continue;
      }

      this.getChildrenFast(parent.id)?.forEach(c => buffer.push(c));
      entities.push(parent);
    }

    return entities;
  }

  getBranchRoot(entityId) {
    let entity = this.getEntityById(entityId)

    while (entity && !entity.isRoot) {
      const parentId = entity.parentId;
      let parent = this.getEntityById(parentId);
      if (!parent || parent.isRoot) {
        break;
      }
      entity = parent;
    }

    return entity;
  }

  deleteBranch(entity, { sync = true, metadata = null, instanceId = null, historyGroup = null, filter = () => true } = {}) {
    if (entity.isRoot) return false;
    const branch = this.getBranch(entity, filter);
    this.deleteEntities(branch.reverse(), { sync, metadata, instanceId, historyGroup });
    return true;
  }

  // ensure that the parent is not part of this branch.
  isValidParent(entity, parentId) {
    const branch = this.getBranch(entity);
    const conflict = branch.find(t => t.id === parentId);
    return !conflict;
  }

  // utility methods //
  isFullTree() {
    return this.root?.side === 0;
  }

  isEmpty() {
    const parentId = this.root?.id || "root";
    return this.getParentMap(parentId).size === 0;
  }

  isSapling() {
    return true;
  }

  makeEntity(data) {
    const entity = new NodeEntity(this.entityEvents, data);

    if (entity.isRoot) {
      this.root = entity;
    }

    return entity;
  }

  isCategoryNode(entity) {
    return !!entity && entity.parentId === this.root?.id;
  }

  async duplicateMap(targetMap, { includeRatings = true, includeNotes = true, includeResources = true, includeResourceRatings = true, includeResourceTags = true } = {}) {
    try {

      const body = {
        targetContextId: targetMap?.id || null,
        includeRatings,
        includeNotes,
        includeResources,
        includeResourceRatings,
        includeResourceTags
      };

      const publicUserId = targetMap?.publicUserId || this.getContext().getLoadedProfile().publicId;
      const res = await Retry.Axios(async () => await axios.post(`/api/map/duplicate/${this.sharePrefix}${this.contextId}/${publicUserId}`, body), 6);

      return res.data;
    } catch (err) {
      console.log(err);
      return null;
    }
  }


  async duplicateBranch(entityId, targetMap, { includeRatings = true, includeNotes = true, includeResources = true, includeResourceRatings = true, includeResourceTags = true } = {}) { // TODO
    try {
      const entity = this.getEntityById(entityId);

      if (!entity) return null;

      const body = {
        nodeIds: this.getBranch(entity).map(t => t.id),
        targetContextId: targetMap?.id || null,
        includeRatings,
        includeNotes,
        includeResources,
        includeResourceRatings,
        includeResourceTags
      };

      const publicUserId = targetMap?.publicUserId || this.getContext().getLoadedProfile().publicId;
      const res = await Retry.Axios(async () => await axios.post(`/api/map/duplicate/${this.sharePrefix}${this.contextId}/${publicUserId}`, body), 6);

      return res.data;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
}