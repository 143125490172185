import queryString from "query-string";
import { createBrowserHistory as createHistory } from "history";

function preserveQueryParameters(history, preserve, location) {
  const currentQuery = queryString.parse(history.location.search);
  const removeParameter = location.removeParameter;
  
  if (currentQuery) {
    const preservedQuery = {};
    for (let p of preserve) {
      const v = currentQuery[p];
      if (v && p !== removeParameter) {
        preservedQuery[p] = v;
      }
    }

    if (location.search) {
      Object.assign(preservedQuery, queryString.parse(location.search));
    }

    location.search = queryString.stringify(preservedQuery);
  }

  return location;
}

function createLocationDescriptorObject(location, state) {
  return typeof location === "string" ? { pathname: location, state } : location;
}

function createPreserveQueryHistory(createHistory, queryParameters) {
  return (options) => {
    const history = createHistory(options);
    const oldPush = history.push, oldReplace = history.replace;
    history.push = (path, state) => oldPush.apply(history, [preserveQueryParameters(history, queryParameters, createLocationDescriptorObject(path, state))]);
    history.replace = (path, state) => oldReplace.apply(history, [preserveQueryParameters(history, queryParameters, createLocationDescriptorObject(path, state))]);
    history.clearParams = (path, state) => oldReplace.apply(history);
    history.pushWithoutParams = oldPush;
    history.replaceWithoutParams = oldReplace;
    return history;
  };
}

export function createBrowserHistory() {
  return createPreserveQueryHistory(createHistory, ["locale", "token", "returnTo", "access", "url", "text", "embed", "forceduplicate", "editor", "quiz", "description", "invites", "inbox"])();
}