import { Divider, Typography, Grid, ButtonBase, Button, Avatar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useRTACAuth, useRTACProfile } from "@emberly/dataplane";
import Scrollbars from "react-custom-scrollbars-2";
import { useHistory } from "react-router";

const useStyles = makeStyles(theme => ({
  root: {
    position: "absolute",
    width: "100%",
    minHeight: "100%",
    background: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  grid: {
    width: "360px",
    textAlign: "center",
    maxWidth: `calc(100vw - ${theme.spacing(6)})`
  },
  avatar: {
    marginBottom: theme.spacing(2),
    width: "46px",
    height: "46px"
  },
  logoutButton: {
    textDecoration: "underline"
  },
  dividerAuth: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2)
  },
  dividerNoAuth: {
    marginBottom: theme.spacing(2)
  },
  descriptionAuth: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  descriptionNoAuth: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2.5)
  },
  fullWidthItem: {
    width: "100%"
  },
  button: {
    marginTop: theme.spacing(1)
  },
  openEmberlyButton: {
    color: theme.palette.primary.main,
  }
}));

export default function MapNotFoundView() {
  const classes = useStyles();
  const history = useHistory();
  const { profile } = useRTACProfile(false);
  const { logout, isAuthenticated } = useRTACAuth();

  return (
    <Scrollbars autoHide hideTracksWhenNotNeeded>
      <Grid container className={classes.root} justifyContent="center" alignItems="center">
        <Grid className={classes.grid} container direction="row" justifyContent="center" alignItems="center">
          <Grid item>
            <Avatar className={classes.avatar} alt="Emberly" src="/icons/apple-touch-icon.png" variant="rounded" />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">Map not found</Typography>
          </Grid>


          {isAuthenticated ? (
            <>
              <Grid item xs={12}>
                <Typography variant="body1" className={classes.descriptionAuth}>
                  Either this map doesn’t exist or you don’t have permission to view it. Ask the map owner to verify the link and/or update permissions.
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="neutral"
                  className={classes.button}
                  onClick={() => logout()}
                >
                  Log out to change account
                </Button>
              </Grid>

              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="neutral"
                  className={classes.button}
                  onClick={() => history.push("/app/home")}
                >
                  Open Emberly
                </Button>
              </Grid>

              <Grid item xs={12}>
                <Divider className={classes.dividerAuth} />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">
                  You are logged in as {profile?.email || "..."}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <ButtonBase onClick={() => logout()}>
                  <Typography className={classes.logoutButton} variant="body2" color="textSecondary">
                    Log out
                  </Typography>
                </ButtonBase>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <Typography variant="body1" className={classes.descriptionNoAuth}>
                  Either this map doesn’t exist or it’s private.
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Divider className={classes.dividerNoAuth} />
              </Grid>

              <Grid item xs={12}>
                <ButtonBase onClick={() => window.open("https://ember.ly", "_blank")}>
                  <Typography className={classes.openEmberlyButton} variant="body2">
                    Open Emberly
                  </Typography>
                </ButtonBase>
              </Grid>
            </>
          )}

        </Grid>
      </Grid >
    </Scrollbars>
  );
}
