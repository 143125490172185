import { createContext, useContext, useMemo } from "react";
import { useIsPortrait } from "../hooks/useIsPortrait";
import { isIOS, isMobile } from "react-device-detect";
import { useIsPublicAccess } from "@emberly/dataplane";

const currentLocation = new URL(window.location.href);

export const isEmbed = inIframe() || currentLocation.searchParams.has("embed");

const verifyMobile = () => {
  try {
    return isMobile || !!(navigator.userAgent || navigator.vendor || window.opera).match(/Mobile DuckDuckGo/);
  } catch (err) {
    return false;
  }
};


const verifiedIsMobile = verifyMobile();
const verifiedIsIos = isIos();

export const DeviceContext = createContext();

export const useDevice = () => useContext(DeviceContext);

export const DeviceProvider = (props) => {
  const initIsPortrait = useIsPortrait();

  const portraitOverride = useMemo(() => {
    const pathname = window.location.pathname;
    // Prevent rotation while in a form.

    if (
      verifiedIsMobile &&
      initIsPortrait &&
      (
        pathname.endsWith("/resource/add") ||
        pathname.endsWith("/edit") ||
        pathname.endsWith("/settings/plans") ||
        pathname.endsWith("/settings/account") ||
        pathname.endsWith("/settings/export")
      )
    ) {
      return true;
    } else {
      return false;
    }
  }, [initIsPortrait]);

  const isPortrait = initIsPortrait || portraitOverride;
  const isPublicAccess = useIsPublicAccess();
  
  return (
    <DeviceContext.Provider
      value={{
        isDesktopView: !isEmbed && !(isPortrait && verifiedIsMobile) && !isPublicAccess,
        isMobilePortraitView: !isEmbed && isPortrait && verifiedIsMobile && !isPublicAccess,
        isEmbedView: isEmbed || isPublicAccess,
        isEmbed: isEmbed,
        isMobilePortrait: isMobile && isPortrait,
        isPortrait: isPortrait,
        isMobile: verifiedIsMobile,
        isIos: verifiedIsIos,
        isPublicAccess
      }}
    >
      {props.children}
    </DeviceContext.Provider>
  );
};



function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

function isIos() {
  if (isIOS || /iPad|iPhone|iPod/.test(navigator.platform)) {
    return true;
  } else {
    return navigator.maxTouchPoints > 1 && /MacIntel/.test(navigator.platform);
  }
}