import { useMemo, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Snackbar, IconButton, Button, Portal } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Close as CloseIcon } from "@mui/icons-material";

const useStyles = makeStyles(theme => ({
  close: {
    padding: theme.spacing(0.5),
    color: theme.palette.text.contrast
  },
  updateButton: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main
  }
}));

const anchorOrigin = {
  vertical: "bottom",
  horizontal: "center"
};

const contentProps = {
  "aria-describedby": "message-id"
};

export default function NotificationSnackbar(props) {
  const { active } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const { snackbarMessage, lastMessage, action, actionMessage } = useSelector(state => state.notification);
  const { serviceWorkerUpdated, serviceWorkerRegistration } = useSelector(state => state.app);

  const updateServiceWorker = useCallback(() => {
    if (!!serviceWorkerRegistration) {
      const registrationWaiting = serviceWorkerRegistration.waiting;
      if (registrationWaiting) {
        registrationWaiting.postMessage({ type: "SKIP_WAITING" });
        registrationWaiting.addEventListener("statechange", e => {
          if (e.target.state === "activated") {
            window.location.reload();
          }
        });
      }
    }
    setOpen(false);
  }, [serviceWorkerRegistration, setOpen]);

  useEffect(() => {
    if (!!serviceWorkerUpdated) {
      setOpen(true);
      setShowUpdate(true);
    }
  }, [serviceWorkerUpdated, setShowUpdate, setOpen]);

  useEffect(() => {
    if (!!snackbarMessage && ((Date.now() - lastMessage) / 1000) < 5) {
      setOpen(true);
      setShowUpdate(false);
    }
  }, [snackbarMessage, setOpen, lastMessage, setShowUpdate]);

  const onClose = useCallback((ev, reason) => {
    if (reason !== "clickaway") {
      setOpen(false);
    }
  }, [setOpen]);

  const onUndoAction = useCallback(() => {
    if (action !== null) {
      try {
        action();
        setOpen(false);
      } catch (err) {
        console.log(err);
        console.log("error performing undo action");
      }
    }
  }, [action, setOpen]);

  return useMemo(() => active ? (
    <Portal>
      <Snackbar
        anchorOrigin={anchorOrigin}
        open={open}
        autoHideDuration={showUpdate ? 15000 : 4000}
        onClose={onClose}
        ContentProps={contentProps}
        message={showUpdate ? "New version available" : <span>{snackbarMessage}</span>}
        action={
          <>
            {
              showUpdate ? (
                <Button className={classes.updateButton} onClick={updateServiceWorker} size="medium">Update</Button>
              ) : null
            }
            {
              action && actionMessage ? (
                <Button className={classes.updateButton} onClick={onUndoAction} size="medium">{actionMessage}</Button>
              ) : null
            }
            <IconButton
              key="close"
              aria-label="close"
              className={classes.close}
              onClick={onClose}
              size="medium"
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </Portal>
  ) : null, [classes, open, onClose, snackbarMessage, updateServiceWorker, showUpdate, onUndoAction, action, actionMessage, active]);
}