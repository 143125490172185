const FONT_PRIMARY = "\"IBM Plex Sans\", Arial";

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 600,
  
  p_header: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "30px",
    lineHeight: "130%",
  },

  p_subtitle: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "150%",
    letterSpacing: "0.15px",
  },

  h1: {
    fontWeight: 600,
    lineHeight: "80px",
    fontSize: "64px",
    letterSpacing: "-1.5px"
  },
  h2: {
    fontWeight: 600,
    lineHeight: "64px",
    fontSize: "48px",
    letterSpacing: "-0.5px"
  },
  h3: {
    fontWeight: 600,
    lineHeight: "48px",
    fontSize: "32px",
    letterSpacing: "0px"
  },
  h4: {
    fontWeight: 600,
    lineHeight: "36px",
    fontSize: "24px",
    letterSpacing: "0.25px"
  },
  h5: {
    fontWeight: 600,
    lineHeight: "30px",
    fontSize: "20px",
    letterSpacing: "0px",
  },
  h6: {
    fontWeight: 600,
    lineHeight: "28px",
    fontSize: "18px",
    letterSpacing: "0.15px"
  },
  subtitle1: {
    fontWeight: 500,
    lineHeight: "24px",
    fontSize: "16px",
    letterSpacing: "0.15px"
  },
  subtitle2: {
    fontWeight: 500,
    lineHeight: "22px",
    fontSize: "14px",
    letterSpacing: "0.1px"
  },
  body1: {
    fontWeight: 400,
    lineHeight: "24px",
    fontSize: "16px",
    letterSpacing: "0.15px"
  },
  body2: {
    fontWeight: 400,
    lineHeight: "22px",
    fontSize: "14px",
    letterSpacing: "0.15px"
  },
  caption: {
    fontWeight: 400,
    lineHeight: "20px",
    fontSize: "12px",
    letterSpacing: "0.4px"
  },
  overline: {
    fontWeight: 600,
    lineHeight: "18px",
    fontSize: "12px",
    letterSpacing: "1px",
    textTransform: "uppercase"
  },
  button: {
    fontWeight: 500,
    lineHeight: "24px",
    fontSize: "14px",
    letterSpacing: "0.2px",
    textTransform: "none"
  }
};

export default typography;
