import { alpha } from "@mui/material/styles";

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const GREY = {
  0: "#FFFFFF",
  50: "#FAFAFA",
  100: "#F6F6F6",
  200: "#EDEDED",
  300: "#E3E3E3",
  400: "#CDCDCD",
  500: "#9E9E9E",
  600: "#727272",
  700: "#505050",
  800: "#2C2C2C",
  900: "#1D1D1D",
  500_8: alpha("#9E9E9E", 0.08),
  500_12: alpha("#9E9E9E", 0.12),
  500_16: alpha("#9E9E9E", 0.16),
  500_24: alpha("#9E9E9E", 0.24),
  500_32: alpha("#9E9E9E", 0.32),
  500_48: alpha("#9E9E9E", 0.48),
  500_56: alpha("#9E9E9E", 0.56),
  500_80: alpha("#9E9E9E", 0.8)
};

const PRIMARY = {
  //lighter: "#C8FACD",
  light: "#48B5E4",
  main: "#1A9AD1",
  dark: "#12729B",
  //darker: "#005249",
  darker: "#050038",
  contrastText: "#fff"
};
const SECONDARY = {
  lighter: "#D6E4FF",
  light: "#FCD69A",
  main: "#FFCF54",
  dark: "#FBB03B",
  darker: "#091A7A",
  contrastText: "#513D0A"
};

const NEUTRAL = {
  light: GREY[500_12],
  main: GREY[700],
  dark: GREY[600],
  contrastText: GREY[0],
  button: "#DEDEDE"
};

const INFO = {
  lighter: "#D0F2FF",
  light: "#64B6F7",
  main: "#2196F3",
  dark: "#0B79D0",
  darker: "#04297A",
  contrastText: "#fff"
};

const SUCCESS = {
  lighter: "#E9FCD4",
  light: "#81C784",
  main: "#66BB6A",
  dark: "#388E3C",
  darker: "#08660D",
  contrastText: GREY[0]
};

const WARNING = {
  lighter: "#FFF7CD",
  light: "#FFE16A",
  main: "#FFC107",
  dark: "#B78103",
  darker: "#7A4F01",  
  contrastText: GREY[800]
};

const ERROR = {
  lighter: "#FFE7D9",
  light: "#FFA48D",
  main: "#FF4842",
  dark: "#B72136",
  darker: "#7A0C2E",
  contrastText: "#fff"
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  neutral: createGradient(NEUTRAL.light, NEUTRAL.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main)
};

const CHART_COLORS = {
  violet: ["#826AF9", "#9E86FF", "#D0AEFF", "#F7D2FF"],
  blue: ["#2D99FF", "#83CFFF", "#A5F3FF", "#CCFAFF"],
  green: ["#2CD9C5", "#60F1C8", "#A4F7CC", "#C0F2DC"],
  yellow: ["#FFE700", "#FFEF5A", "#FFF7AE", "#FFF3D6"],
  red: ["#FF6C40", "#FF8F6D", "#FFBD98", "#FFF2D4"]
};

const palette = {
  mode: "dark",
  common: {
    black: "#000",
    white: "#fff",
    rating: "#FFCF54",
    heart: "#FF3C38",
    canvas: "#1E1E1E",
    canvasHex: 0x1E1E1E,
    darkBlue: "#050038",
    imageBackground: "#F5F7F6",
    saturateFilter: "saturate(85%) brightness(80%)",
    textSelection: "#135564",
    selectedResource: "#222",
    softShadowBorder: GREY[900],
    icon: "#DEDEDE",
    softIcon: GREY[400],
    hardIcon: GREY[200],
    searchInputIcon: GREY[500],
    chip: GREY[700],
    scrollbar: "rgba(155, 155, 155, 0.418)",
    dashboardSidebar: "#1D1D1D",
    tag: "rgba(255,255,255,0.03)",
    editorTooltip: GREY[900],
    themeColor: GREY[900],
    npcChatBubble: "#274A66",
    playerChatBubble: GREY[700]
  },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  neutral: { ...NEUTRAL },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  text: { 
    primary: "#DEDEDE", 
    secondary: "#999999", 
    disabled: "#616161",
    icon: "#DEDEDE",
    contrast: "#000"
  },
  action: {
    active: GREY[600],
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
    canvasButtonActive: GREY[700]
  },
  background: {
    paper: GREY[800], 
    default: "#121212", 
    neutral: "#EAEDF0",
    glass: "rgba(30, 30, 30, 0.85)",
    lowered: GREY[900],
    sidebar: GREY[900],
    quiz: "#1E1E1E"
  }
};

export default palette;
