import { Divider, Typography, Grid, ButtonBase, Button, Avatar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useRTACAuth, useRTACProfile } from "@emberly/dataplane";
import Scrollbars from "react-custom-scrollbars-2";

const useStyles = makeStyles(theme => ({
  root: {
    position: "absolute",
    width: "100%",
    minHeight: "100%",
    background: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  grid: {
    width: "360px",
    textAlign: "center",
    maxWidth: `calc(100vw - ${theme.spacing(6)})`
  },
  avatar: {
    marginBottom: theme.spacing(2),
    width: "46px",
    height: "46px"
  },
  logoutButton: {
    textDecoration: "underline"
  },
  dividerAuth: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2)
  },
  descriptionAuth: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  button: {
    marginTop: theme.spacing(1)
  }
}));

export default function ExtensionLoginPage() {
  const classes = useStyles();
  const { profile } = useRTACProfile(true);
  const { logout } = useRTACAuth();

  return (
    <Scrollbars autoHide hideTracksWhenNotNeeded>
      <Grid container className={classes.root} justifyContent="center" alignItems="center">
        <Grid className={classes.grid} container direction="row" justifyContent="center" alignItems="center">
          <Grid item>
            <Avatar className={classes.avatar} alt="Emberly" src="/icons/apple-touch-icon.png" variant="rounded" />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">Great success!</Typography>
          </Grid>


          <Grid item xs={12}>
            <Typography variant="body1" className={classes.descriptionAuth}>
              You have logged in, you can close this tab and use the extension now!
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Button
              fullWidth
              variant="outlined"
              color="neutral"
              className={classes.button}
              onClick={() => window.location.replace("/app/home")}
            >
              Open Emberly
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Divider className={classes.dividerAuth} />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary">
              You are logged in as {profile?.email || "..."}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <ButtonBase onClick={() => logout()}>
              <Typography className={classes.logoutButton} variant="body2" color="textSecondary">
                Log out
              </Typography>
            </ButtonBase>
          </Grid>


        </Grid>
      </Grid >
    </Scrollbars>
  );
}
