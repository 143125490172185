import { useState, useEffect, useCallback } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { RequestSignup } from "../../../../actions/auth";
import { MAX_WIDTH, WIDTH } from "./constants";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(7),
    maxWidth: MAX_WIDTH,
    width: WIDTH,
    "@media (max-width: 900px)": { 
      marginBottom: "0px",
    }
  },
  header: {
    fontSize: "60px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "120%",
    letterSpacing: "-1.5px",
    textAlign: "left",
    "@media (max-width: 1080px)": {
      fontSize: "56px"
    },
    "@media (max-width: 900px)": {
      fontSize: "42px",
      textAlign: "center"
    }
  },
  description: {
    color: theme.palette.grey[700],
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
    lineHeight: "150%",
    "@media (max-width: 900px)": {
      textAlign: "center"
    }
  },
  image: {
    width: "896px",
    height: "558px",
    minHeight: "auto",
    boxShadow: theme.shadows[3],
    borderRadius: theme.shape.borderRadius,
    background: "#F5F7F6",
    "@media (max-width: 900px)": {
      marginTop: theme.spacing(3),
      width: "100%",
      minHeight: "calc((100vw - 56px) / 1.6035)",
      height: "auto",
      marginLeft: 0
    },
    "&:focus": {
      outline: "none !important"
    }
  },
  link: {
    textDecoration: "none"
  },
  textItem: {
    "@media (max-width: 900px)": {
      alignItems: "center"
    }
  },
  buttonItem: {
    "@media (max-width: 900px)": {
      justifyContent: "center"
    }
  },
  button: {
    height: "48px"
  }
}));

export default function HeaderSection(props) {
  const classes = useStyles();
  const { title, description, video, poster } = props;
  const dispatch = useDispatch();

  const [startVideo, setStartVideo] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setStartVideo(true), 2000);
    return () => clearTimeout(timer);
  },[]);

  const onClick = useCallback(() => {
    dispatch(RequestSignup());
    if (!!window.fathom) {
      window.fathom.trackGoal("WIEUXHEM", 0);
    }
  }, [dispatch]);

  return (
    <Grid container direction="row" justifyContent="space-between" alignItems="center" className={classes.root} spacing={3}>

      <Grid item container xs={12} md={6} direction="column" justifyContent="flex-start" alignItems="flex-start" className={classes.textItem}>
        <Typography variant="h1" className={classes.header}>{title}</Typography>
        <Typography variant="p_subtitle" className={classes.description}>{description}</Typography>
        <Grid item container className={classes.buttonItem}>
          <Link to="/app" className={classes.link}>
            <Button color="primary" variant="contained" onClick={onClick} size="large" className={classes.button}>Use Emberly for free</Button>
          </Link>
        </Grid>
      </Grid>

      <Grid item xs={12} md={6}>
        <video className={classes.image} autoPlay loop disableRemotePlayback muted poster={poster}>
          {startVideo ? <source src={video} type="video/mp4" /> : null}
        </video>
      </Grid>

    </Grid>
  );
}
