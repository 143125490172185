import React, { useCallback, useRef, useState, createContext, useContext, useEffect } from "react";
import PluginsHandler from "./PluginsHandler";
import { useGroup, useGroupName } from "@emberly/rtac";


export const PluginsContext = createContext();
export const usePlugins = () => useContext(PluginsContext);


export default function PluginsContextProvider(props) {
  const groupName = useGroupName();
  const { group } = useGroup(groupName);
  const handlerRef = useRef(null);

  useEffect(() => {
    if (!!group) {
      const h = new PluginsHandler(group)
      handlerRef.current = h;
      return () => {
        h.destroy();
      };
    }
  },[group]);
  
  const handler = handlerRef.current;

  return (
    <PluginsContext.Provider
      value={{
        handler
      }}>
        {props.children}
    </PluginsContext.Provider>
  );
}