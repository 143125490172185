import { Entity, Retry } from "@emberly/rtac"
import axios from "axios";

export default class MapEntity extends Entity {

  constructor(eventEmitter, data) {
    super(eventEmitter, data);
    this.name = data.name || "";
    this.mapType = data.mapType || 0;
    this.lastOpened = new Date(data.lastOpened || Date.now());
    this.tags = data.tags || [];
    this.favorite = data.favorite || false;
    this.preview = data.preview || "";
    this.hasPreview = data.hasPreview || false;
    this.previewFileSize = data.previewFileSize || 0;
    this.previewFileType = data.previewFileType || "";
    this.isPublic = data.isPublic || false;
    this.duplicateEnabled = data.duplicateEnabled || false;
    this.watermarkEnabled = data.watermarkEnabled || false;
    this.isOwner = data.isOwner || false;
    this.publicUserId = data.publicUserId || "";
    this.lastModifiedBy = data.lastModifiedBy || [];
    this.lastEdited = this.lastModifiedBy.length !== 0 ? (new Date(this.lastModifiedBy[0]?.time) || new Date(data.lastModified || Date.now())) : new Date(data.lastModified || Date.now());
    this.members = data.members || [];
    this.created = new Date(data.created || Date.now());
    this.updateAccess = [];
    this.access = data.access || 0;
    this.templateQuery = data.templateQuery;
    this._file = null;
    this._uploadState = null;
  }

  get groupName() {
    return `${this.publicUserId}/${this.id}`;
  }

  setMemberAccess(publicUserId, access, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    const member = this.members.find(t => t.publicUserId === publicUserId);

    if (member && member.access !== access) {
      this.members = [...this.members];
      member.access = access;
      const index = this.updateAccess.findIndex(t => t.publicUserId === publicUserId);

      this.updateAccess = [...this.updateAccess];

      if (index !== -1) {
        this.updateAccess[index] = { access, publicUserId };
      } else {
        this.updateAccess.push({ access, publicUserId });
      }

      if (sync) {
        this.update("updated", { sync, metadata, instanceId, historyGroup });
      }
    }
  }

  updateMembers(updateAccess, publicId) {
    this.members = [...this.members];
    
    updateAccess.forEach(update => {
      const member = this.members.find(t => t.publicUserId === update.publicUserId);
      if (member) {
        member.access = update.access;
        
        if (member.publicUserId === publicId) {
          this.access = update.access;
        }
      }
    });

    this.updateAccess = updateAccess;
  }

  setMembers(members) {
    this.members = members;
    this.updateAccess = [];
  }

  setName(val, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    if (this.name !== val) {
      this.name = val;
      if (sync) {
        this.update("updated", { sync, metadata, instanceId, historyGroup });
      }
    }
  }

  setMapType(val, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    if (this.mapType !== val) {
      this.mapType = val;
      if (sync) {
        this.update("updated", { sync, metadata, instanceId, historyGroup });
      }
    }
  }

  setFavorite(val, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    if (this.favorite !== val) {
      this.favorite = val;
      if (sync) {
        this.update("updated", { sync, metadata, instanceId, historyGroup });
      }
    }
  }

  setIsPublic(val, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    if (this.isPublic !== val && this.isOwner) {
      this.isPublic = val;
      if (sync) {
        this.update("updated", { sync, metadata, instanceId, historyGroup });
      }
    }
  }

  setDuplicateEnabled(val, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    if (this.duplicateEnabled !== val && this.isOwner) {
      this.duplicateEnabled = val;
      if (sync) {
        this.update("updated", { sync, metadata, instanceId, historyGroup });
      }
    }
  }

  setWatermarkEnabled(val, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    if (this.watermarkEnabled !== val && this.isOwner) {
      this.watermarkEnabled = val;
      if (sync) {
        this.update("updated", { sync, metadata, instanceId, historyGroup });
      }
    }
  }

  setTags(val, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    if (!(this.tags.length === val.length && this.tags.every((v, i) => v === val[i]))) {
      this.tags = val;
      if (sync) {
        this.update("updated", { sync, metadata, instanceId, historyGroup });
      }
    }
  }

  setLastOpened(val, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    if (this.lastOpened < val) {
      const delta = Date.now() - this.lastOpened.getTime();
      this.lastOpened = val;
      if (sync && delta > 10000) {
        this.update("updated", { sync, metadata, instanceId, historyGroup });
      }
    }
  }


  setPreviewFileSize(val, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    if (this.previewFileSize !== val) {
      this.previewFileSize = val;
      if (sync) {
        this.update("updated", { sync, metadata, instanceId, historyGroup });
      }
    }
  }

  setPreviewFileType(val, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    if (this.previewFileType !== val) {
      this.previewFileType = val;
      if (sync) {
        this.update("updated", { sync, metadata, instanceId, historyGroup });
      }
    }
  }

  setPreview(val, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    if (this.preview !== val) {
      this.preview = val;
      this.hasPreview = !!val;
      this._uploadState = null;
      if (sync) {
        this.update("updated", { sync, metadata, instanceId, historyGroup });
      }
    }
  }

  setLastModifiedBy(val, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    this.lastModifiedBy = val;
    if (sync) {
      this.update("updated", { sync, metadata, instanceId, historyGroup });
    }
  }

  addModifiedNode(nodeId, userId, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    if (!nodeId) return;
    
    const wasEmpty = this.lastModifiedBy.length === 0;
    const oldElementIndex = this.lastModifiedBy.findIndex(t => t.id === nodeId);

    if (wasEmpty || oldElementIndex !== 0 || this.lastModifiedBy[0].userId !== userId) {
      this.lastModifiedBy = this.lastModifiedBy.filter((_, i) => i !== oldElementIndex);
      this.lastEdited = new Date();

      this.lastModifiedBy.unshift({
        id: nodeId,
        time: this.lastEdited.toISOString(),
        userId: userId,
        operation: 4
      });

      if (this.lastModifiedBy.length > 5) {
        this.lastModifiedBy.pop();
      }

      if (sync) {
        this.update("updated", { sync, metadata, instanceId, historyGroup });
      }
    }
  }

  getPreviewUrl(error) {
    if (error || !this.preview || !this.hasPreview) {
      return "/assets/map_empty_state.svg";
    }
    return this.preview;
  }

  delete(data) {
    this.lastEdited = new Date();
    super.delete(data)
  }

  restore(data) {
    this.lastEdited = new Date();
    this.members = this.members.filter(t => t.publicUserId === this.publicUserId);
    this.updateAccess = [];
    this.lastModifiedBy = [];
    super.restore(data)
  }

  getTimeSinceLastEdit() { // TODO
    return this.renderDeltaTime(Date.now() - this.lastEdited.getTime());
  }

  getTimeSinceLastOpen() {
    return this.renderDeltaTime(Date.now() - this.lastOpened.getTime());
  }

  getTimeUntilDelete() {
    const t = 1000 * 60 * 60 * 24 * 3.05;
    const delta = (Date.now() - this.lastEdited.getTime()) - t;
    return delta < -1000 * 60 * 15 ? `in ${this.renderDeltaTime(delta)}` : "now";
  }

  getData() {
    return {
      id: this.id,
      name: this.name,
      mapType: this.mapType,
      lastOpened: this.lastOpened.toISOString(),
      tags: this.tags,
      favorite: this.favorite,
      lastModifiedBy: this.lastModifiedBy,
      preview: this._uploadState,
      previewFileSize: this.previewFileSize,
      previewFileType: this.previewFileType,
      isPublic: this.isPublic,
      duplicateEnabled: this.duplicateEnabled,
      watermarkEnabled: this.watermarkEnabled,
      updateAccess: this.updateAccess,
      members: this.members,
      templateQuery: this.templateQuery
    };
  }

  uploadPreview(file = null, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    // TODO upload file
    this._file = file;
    this.fileHandle = file.name;
    this.previewFileSize = file.size;
    this.previewFileType = file.type
    this._uploadState = "upload";
    this.eventEmitter.emit("uploadRequested", this);

    if (sync) {
      this.update("updated", { sync: false, metadata, instanceId, historyGroup });
    }
  }


  onHandleReceived(handles) {
    //console.log("got handles", handles);
    const previewKey = `${this.id}/preview`;
    const hasPreview = !!handles[previewKey];

    if (hasPreview) {
      this.uploadPreviewFile(handles[previewKey], this._file);
    }

    this._file = null;

    return hasPreview;
  }

  async uploadPreviewFile(handles, file) {

    try {
      const form = new FormData();

      Object.keys(handles).forEach(key => {
        if (key !== "postUrl") {
          form.append(key, handles[key]);
        }
      });

      form.append("file", file);

      await Retry.Axios(async () => await axios.post(
        handles["postUrl"],
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      ));

      this._uploadState = "complete";
      this.update("updated", { sync: false });

    } catch (err) {
      console.log("error uploading resource file to handle");
      console.log(err);
    }
  }
}
