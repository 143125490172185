import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { MAX_WIDTH, SECTION_SPACING, WIDTH } from "./constants";
import AnimateIn from "../../../atoms/views/AnimateIn";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: SECTION_SPACING,
    marginBottom: SECTION_SPACING,
    maxWidth: MAX_WIDTH,
    width: WIDTH,
  },
  header: {
    position: "relative",
    marginBottom: theme.spacing(0.5),
    "@media (max-width: 360px)": {
      "& img": {
        display: "none"
      }
    }
  },
  highlight: {
    position: "absolute",
    left: "-20px",
    top: "-15px"
  },
  arrow: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(-2)
  },
  embed: {
    width: "100%",
    height: "586px",
    maxHeight: "calc(80vh - 32px)",
    background: "#FFFFFF",
    border: `4px solid ${theme.palette.divider}`,
    boxSizing: "border-box",
    borderRadius: theme.shape.borderRadiusMd
  }
}));

export default function EmbedSection(props) {
  const { title, description, src } = props;
  const classes = useStyles();
  return (
    <Grid container direction="row" justifyContent="space-between" alignItems="center" className={classes.root} spacing={3}>
     
      <Grid item container xs={12} direction="column" alignItems="center">
        <Typography variant="p_header" className={classes.header} align="center">
          <img src="/assets/text_highlight.svg" alt="highlight" className={classes.highlight} />
          {title}
        </Typography>
        <Typography variant="p_subtitle" color="textSecondary" align="center">{description}</Typography>
        <img src="/assets/arrow_down.svg" alt="arrow pointing to embed" className={classes.arrow} loading="lazy"/>
      </Grid>

      <Grid item xs={12}>
        <AnimateIn>
          <iframe
            loading="lazy"
            title="Emberly Design Thinking Embed"
            id="emberly-embed-iframe-pub"
            src={src}
            className={classes.embed}
          ></iframe>
        </AnimateIn>
      </Grid>
    </Grid>
  );
}
