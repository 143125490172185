import { useEffect, useMemo, useState } from "react";
import { useRTACPlan, useCollection } from "@emberly/rtac";

export default function useUploadedSize(load = true, groupName = undefined) {
  const { collection } = useCollection("Resource", groupName);
  const [ updateCount, setUpdateCount ] = useState(0);
  const { plan } = useRTACPlan();  
  const handleLoad = load && plan?.type > 0;

  useEffect(() => {
    if (collection && handleLoad) {
      let mounted = true;
      const fn = () => setUpdateCount(t => t + 1);

      collection.loadUploadedSize().then(() => {
        if (mounted) {
          fn();
          collection.onUploadedSizeEvent(fn);
          // add event listeners
        }
      });

      return () => {
        mounted = false;
        collection.offUploadedSizeEvent(fn);
      };
    }
  }, [collection, handleLoad]);

  // Return data to view
  return useMemo(() => {
    return {
      loading: !collection || !collection.uploadedSizeLoaded,
      uploadedSize: collection?.getUploadedSize() || 0,
      collection
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collection, updateCount]);
}