import { Entity } from "@emberly/rtac";

export default class PluginFileEntity extends Entity {

  constructor(eventEmitter, data) {
    super(eventEmitter, data);
    this.name = data.name || "";
    this.fullPath = data.fullPath || "";
    this.data = data.data || "";
    this.fileType = data.fileType || "";
    this.folderId = data.folderId || null;
    this.isFolder = this.fileType === "folder";
  }

  setName(val, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    if (this.name !== val) {
      this.name = val;
      if (sync) {
        this.update("updated", { sync, metadata, instanceId, historyGroup });
      }
      return true;
    }
    return false;
  }

  setFullPath(val, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    if (this.fullPath !== val) {
      this.fullPath = val;
      if (sync) {
        this.update("updated", { sync, metadata, instanceId, historyGroup });
      }
      return true;
    }
    return false;
  }

  setData(val, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    if (this.data !== val) {
      this.data = val;
      if (sync) {
        this.update("updated", { sync, metadata, instanceId, historyGroup });
      }
      return true;
    }
    return false;
  }

  writeData(val, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    if (this.data !== val) {
      this.data = val;
      if (sync) {
        this.update("updated", { sync, metadata, instanceId, historyGroup });
      }
      return true;
    }
    return false;
  }

  async readData() {
    return this.data;
  }

  setFileType(val, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    if (this.fileType !== val) {
      this.fileType = val;
      if (sync) {
        this.update("updated", { sync, metadata, instanceId, historyGroup });
      }
      return true;
    }
    return false;
  }


  setFolderId(val, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    if (this.folderId !== val) {
      this.folderId = val;
      if (sync) {
        this.update("updated", { sync, metadata, instanceId, historyGroup });
      }
      return true;
    }
    return false;
  }


  hasParent() {
    return true;
  }

  getData() {
    return {
      id: this.id,
      name: this.name,
      fullPath: this.fullPath,
      index: this.index,
      data: this.data,
      fileType: this.fileType,
      parentId: this.parentId,
      folderId: this.folderId
    };
  }

}
