import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  textItem: {
    marginBottom: theme.spacing(2)
  },
  proof: {
    marginBottom: theme.spacing(1)
  },
  cite: {
    fontStyle: "normal"
  }
}));

export default function SocialProof(props) {
  const classes = useStyles();
  const { quote, name } = props;
  return (
    <Grid item xs={12} md={4} lg={4} container direction="column" className={classes.textItem}>
      <Typography variant="p_subtitle" className={classes.proof} component="blockquote">{quote}</Typography>
      <Typography variant="subtitle2" component="cite" className={classes.cite}>{name}</Typography>
    </Grid>
  );
}
