import { createContext, useEffect, useContext, useCallback, useRef } from "react";
import useWindowSize from "./useWindowSize";
import EventEmitter from "events";
import { useDevice } from "../providers/DeviceContext";
import { useTheme } from "@mui/styles";
import { useDispatch } from "react-redux";
import { PanToActiveNode } from "../actions/tree";

export const RendererContext = createContext();

export const useRenderer = () => useContext(RendererContext);


let prevWidth = Math.max(window.innerWidth, 128), prevHeight = Math.max(window.innerHeight, 128), bootTime = Date.now();

export const RendererProvider = (props) => {
  const containerRef = useRef(null);
  const appRef = useRef(null);
  const viewportRef = useRef(null);
  const eventEmitterRef = useRef(null);
  const resizeTimer = useRef(null);
  const size = useWindowSize();
  const { isMobile, isEmbed } = useDevice();
  const trackpadModeRef = useRef(false);
  const isLockedRef = useRef(isEmbed);
  const theme = useTheme();
  const dispatch = useDispatch();

  const backgroundColor = theme.palette.common.canvasHex;

  const isTrackpadMode = useCallback(() => {
    try {
      return !!window.localStorage && window.localStorage.getItem("trackpad") === "yes" && !isMobile;
    } catch (err) {
      return false;
    }
  }, [isMobile]);

  const enableTrackpadMode = useCallback((enabled = false) => {
    try {
      if (!!viewportRef.current) {
        if (enabled && !!viewportRef.current.plugins.get("wheel")) {
          viewportRef.current.plugins.pause("wheel");
        } else if (!viewportRef.current.plugins.get("wheel")) {
          viewportRef.current.plugins.resume("wheel");
        }
      }

      trackpadModeRef.current = enabled;
      window.localStorage.setItem("trackpad", enabled ? "yes" : "no");

    } catch (err) {
      console.log(err);
    }
  }, [trackpadModeRef, viewportRef]);

  // Create the renderer
  const createRenderer = useCallback((injectRenderer) => {
    prevWidth = Math.max(window.innerWidth, 128);
    prevHeight = Math.max(window.innerHeight, 128);
    bootTime = Date.now();

    const eventEmitter = new EventEmitter();
    eventEmitterRef.current = eventEmitter;

    const { renderer, viewport } = injectRenderer(size, containerRef.current, isMobile, backgroundColor);
    trackpadModeRef.current = isTrackpadMode();

    if (isEmbed) {
      const handleWheel = (ev) => {
        if (ev.cancelable && isEmbed && !isLockedRef.current) {
          ev.preventDefault();
        }
      };
  
      renderer.view.addEventListener("wheel", handleWheel, { passive: false });
    }


    if (trackpadModeRef.current) {
      viewport.plugins.pause("wheel");
    }

    appRef.current = renderer;
    viewportRef.current = viewport;

    let eventsEnabled = false;

    const pauseEvents = () => {
      if (eventsEnabled && viewportRef.current !== null) {
        eventsEnabled = false;

        if (!trackpadModeRef.current) {
          viewportRef.current.plugins.pause("wheel");
        }

        viewportRef.current.plugins.pause("drag");
      }
    };

    const resumeEvents = () => {
      if (!eventsEnabled && viewportRef.current !== null) {
        eventsEnabled = true;

        if (!trackpadModeRef.current) {
          viewportRef.current.plugins.resume("wheel");
        }

        viewportRef.current.plugins.resume("drag");
      }
    };

    if (isLockedRef.current) {
      const onContainerClick = () => {
        if (isLockedRef.current) {
          isLockedRef.current = false;
        }

        containerRef.current.removeEventListener("touchend", onContainerClick);
        containerRef.current.removeEventListener("click", onContainerClick);
      };
  
      containerRef.current.addEventListener("touchend", onContainerClick);
      containerRef.current.addEventListener("click", onContainerClick);
    }

    if (!isMobile) {
      containerRef.current.addEventListener("mouseleave", pauseEvents);
      containerRef.current.addEventListener("mouseenter", resumeEvents);    
      containerRef.current.addEventListener("mousemove", resumeEvents);
    }

    // Handle zoom pinch
    const onZoom = (e) => {
      e.preventDefault();

      if (isLockedRef.current) {
        e.stopPropagation();
        return;
      }

      if (e.ctrlKey) {
        e.stopPropagation();

        if (!trackpadModeRef.current) return;

        let point = viewport.input.getPointerPosition(e);
        const step = -e.deltaY * (e.deltaMode ? 20 : 2) / 500;
        const change = Math.pow(2, step);

        let oldPoint = viewport.toLocal(point);

        viewport.scale.x *= change;
        viewport.scale.y *= change;
        viewport.emit("zoomed", { viewport, type: "wheel" });
        const clamp = viewport.plugins.get("clamp-zoom");

        if (clamp) {
          clamp.clamp();
        }

        const newPoint = viewport.toGlobal(oldPoint);
        viewport.x += point.x - newPoint.x;
        viewport.y += point.y - newPoint.y;
      }
    };

    containerRef.current.addEventListener("wheel", onZoom, { passive: false });

    const preventDefault = (ev) => ev.preventDefault();
    containerRef.current.addEventListener("gesturestart", preventDefault);
    containerRef.current.addEventListener("gesturechange", preventDefault);
    containerRef.current.addEventListener("gestureend", preventDefault);

    if (!isMobile) {
      pauseEvents();
    }

    const onContextLost = (ev) => {
      console.log("context lost, rebooting...");
      eventEmitterRef.current.emit("contextlost");
    };

    renderer.view.addEventListener("webglcontextlost", onContextLost);

    return () => {
      try {
        renderer.view.removeEventListener("webglcontextlost", onContextLost);
        console.log("shutdown context");
      } catch {}
    };
  }, [containerRef, trackpadModeRef, appRef, size, isTrackpadMode, isLockedRef, isEmbed, isMobile, backgroundColor]);


  const resize = useCallback((force = false) => {
    if (viewportRef.current.screenWidth !== size.width || viewportRef.current.screenHeight !== size.height || force) {
      
      if (size.height !== 0 && size.width !== 0) {
        viewportRef.current.resize(size.width, size.height);
        appRef.current.resize(size.width, size.height);
      }

      if (resizeTimer.current !== null) {
        clearTimeout(resizeTimer.current);
      }

      // calc new and prev area
      const deltaTime = Date.now() - bootTime;

      if (deltaTime < 1500) {
        const prevArea = Math.sqrt(prevWidth * prevHeight);
        const nextArea = Math.sqrt(size.width * size.height);
        const diff = Math.abs(prevArea - nextArea);      
        prevWidth = size.width;
        prevHeight = size.height;
  
        if (diff > 100 && viewportRef.current?.plugins?.plugins?.drag?.active !== true) {
          dispatch(PanToActiveNode());
        }
      }

      resizeTimer.current = setTimeout(() => {
        resizeTimer.current = null;
        
        if (size.width !== 0 && size.height !== 0) {
          viewportRef.current.resize(size.width, size.height);
          appRef.current.resize(size.width, size.height); 
        }
        
        if (eventEmitterRef.current !== null) {
          eventEmitterRef.current.emit("dirty");
        }
      }, 1500);
      
      if (eventEmitterRef.current !== null) {
        eventEmitterRef.current.emit("dirty");
      }
    }
  }, [dispatch, eventEmitterRef, viewportRef, appRef, size, resizeTimer]);


  // Start the renderer process if it doesnt exist
  const startRenderer = useCallback((injectRenderer) => {
    if (appRef.current === null) {
      createRenderer(injectRenderer);
    }
  }, [appRef, createRenderer]);

  // Handle resize
  useEffect(() => {
    if (appRef.current !== null) {
      resize();
    }
  }, [size.width, size.height, resize]);


  useEffect(() => {
    if (appRef.current) {
      appRef.current.backgroundColor = backgroundColor;
    }
  }, [backgroundColor]);

  return (
    <RendererContext.Provider
      value={{
        containerRef,
        eventEmitterRef,
        appRef,
        viewportRef,
        startRenderer,
        isTrackpadMode,
        enableTrackpadMode
      }}
    >
      <div id="renderer" ref={containerRef}></div>
      {props.children}
    </RendererContext.Provider>
  );
};


