import "@emberly/editor/dist/index.css";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import config from "./auth_config.json";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import reducer from "./reducers";
import { createBrowserHistory } from "./common/createBrowserHistory";
import { UPDATE_AVAILABLE, WORKER_INIT } from "./actions/actionTypes";
import { RendererProvider } from "./hooks/useRenderer";
import { EmberlyAuthenticationProvider, EmberlyCollectionProvider, RTACProvider, GroupContextProvider } from "@emberly/dataplane";
import { ConditionalPortalView } from "./components/atoms/views/ConditionalPortalView";
import { DisplayNotification, MinimumApiVersionBreached } from "./actions/app";
import { DeviceProvider } from "./providers/DeviceContext";
import { isMobile } from "react-device-detect";
import ThemeConfig from "@emberly/theme";
import "./index.css";
import { enableBootPath, getPreviousMap, isBootPathEnabled } from "./common/BootHandler";
import { getLocalThemeMode, setLocalThemeMode } from "./common/ThemeHandler";
import EventEmitter from "events";

// Middleware
let middleware = [thunk];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const CLIENT_API_VERSION = 3;

// Store
const store = composeEnhancers(applyMiddleware(...middleware))(createStore)(reducer);

const history = createBrowserHistory();

const handlerProvider = new EmberlyCollectionProvider();

const authProvider = new EmberlyAuthenticationProvider({
  "domain": config.domain,
  "client_id": config.clientId,
  "audience": config.audience
}, history);


// dispatch a snackbar for events.
const onNotify = (message) => store.dispatch(DisplayNotification(message));

let initialProfileLoad = false;
const initialPath = window.location.pathname;
const initialPathDefault = initialPath === "/" || initialPath === "/app";


const themeMode = getLocalThemeMode();
const themeEventListener = new EventEmitter();

// dispatch a warning if the client is lagging too much behind
const onProfile = (profile) => {
  if (CLIENT_API_VERSION < profile.minimumApiVersion) {
    store.dispatch(MinimumApiVersionBreached());
  }

  // handle open mode
  const openLast = isBootPathEnabled();

  if (profile.openLastVisited !== openLast) {
    enableBootPath(profile.openLastVisited);

    if (!initialProfileLoad) {
      initialProfileLoad = true;
      const path = window.location.pathname;

      if (initialPathDefault) {
        if (profile.openLastVisited) {
          const prev = getPreviousMap();
          history.replace(prev);
        } else if (path.startsWith("/map/")) {
          history.replace("/app/home");
        }
      }
    }
  }

  // handle theme mode
  const themeValue = profile.theme || "light";
  if (themeValue !== themeMode) {
    setLocalThemeMode(themeValue);
  }

  themeEventListener.emit("themeMode", themeValue);
};



ReactDOM.render(
  <Provider store={store}>
    <RTACProvider authenticationProvider={authProvider} collectionProvider={handlerProvider} onNotify={onNotify} onProfile={onProfile} backgroundTimeout={isMobile ? 5 * 60000 : 15 * 60000}>
      <GroupContextProvider>
        <DeviceProvider>
          <ThemeConfig mode={themeMode} events={themeEventListener}>
            <RendererProvider>
              <App history={history} />
            </RendererProvider>
          </ThemeConfig>
        </DeviceProvider>
      </GroupContextProvider>
    </RTACProvider>
    <ConditionalPortalView portalId="root-portal" />
  </Provider>,
  document.getElementById("root")
);

serviceWorkerRegistration.register({
  onUpdate: reg => store.dispatch({ type: UPDATE_AVAILABLE, payload: reg }),
  onSuccess: () => store.dispatch({ type: WORKER_INIT })
});

// ios disable wierd scroll
document.ontouchmove = function (e) {
  e.preventDefault();
}

const timer = setInterval(() => {
  if (!!window.Paddle) {
    window.Paddle.Setup({ vendor: 108453 });
    clearInterval(timer);
  }
}, 500);

if (window.location.href.startsWith("https://www.")) {
  window.location.replace("https://" + window.location.href.substr(12));
}