import axios from "axios";
import ImageUtility from "../common/ImageUtility";
import { Entity, Retry, TaskQueue } from "@emberly/rtac";

const UPLOAD_QUEUE = new TaskQueue(1, true);

export default class ResourceEntity extends Entity {

  constructor(eventEmitter, data) { // TODO default to sane values on undefined here!!!!! then finish up createUrlResource etc in useResources.
    super(eventEmitter, data);
    // identifiers
    this.contextId = data.contextId;
    this.type = data.type || "website";

    // data properties
    this.name = data.name || "";
    this.description = data.description || "";
    this.source = data.source || "";
    this.rating = data.rating || 0;
    this.tags = data.tags || [];

    // links
    this.url = data.url || "";
    this.imageUrl = data.imageUrl || "";

    // file properties
    this.fileHandle = data.fileHandle || "";
    this.fileSize = data.fileSize || 0;
    this.fileType = data.fileType || "";

    // state properties
    this.incomplete = data.incomplete || false;
    this.archived = data.archived || false;
    this.lastModified = data.lastModified || new Date().toISOString();
    this.created = data.created || new Date().toISOString();

    // internal properties
    this._pending = data.pending || false;
    this._lastModified = new Date(this.lastModified);
    this._cleanSource = ResourceEntity.StripSource(this.source || this.url);
    this._progress = -1;
    this._file = null;
    this._uploading = false;
  }

  get cleanSource() {
    return this._cleanSource;
  }

  // TODO
  uploadFile(file, { sync = true } = {}) {
    if (!!file && file.size > 0) {
      this._file = file;
      this._progress = 0;
      const handle = this.sanitizeFileHandle(file.name);
      if (handle === this.fileHandle) {
        this.fileHandle = this.incrementNumber(handle);
      } else {
        this.fileHandle = handle;
      }
      this.fileSize = file.size;
      this.fileType = file.type
      this.name = this.name || file.name;
      this.type = "file";
      this.url = "";
      this.incomplete = true;
      this._pending = false;
      this.eventEmitter.emit("uploadRequested", this);

      if (sync) {
        this.update();
      }
    }
  }

  incrementNumber(name) {
    try {
      const { filename, extension } = this.splitFilename(name);
      return `${filename}(1)${extension}`;
    } catch {
      return name;
    }
  }

  splitFilename(filename) {
    const idx = filename.lastIndexOf(".");

    if (idx !== -1) {
      return { 
        filename: filename.substr(0,idx),
        extension: filename.substr(idx)
      };
    }

    return { filename, extension: "" };
  }

  sanitizeFileHandle(name) {
    return name.replace(/[/\\?%*:|"<>\[\] #]/g, "_");
  }

  getData() {
    return {
      id: this.id,
      parentId: this.parentId,
      contextId: this.contextId,
      type: this.type,
      index: this.index,

      // data properties
      name: this.name,
      description: this.description,
      source: this.source,
      rating: this.rating,
      tags: this.tags,

      // links
      url: this.url,
      imageUrl: this.imageUrl,

      // file properties
      fileHandle: this.fileHandle,
      fileSize: this.fileSize,
      fileType: this.fileType,

      // state properties
      incomplete: this.incomplete,
      archived: this.archived
    }
  }

  hasParent() {
    return true;
  }

  setType(val, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    if (this.type !== val) {
      this.type = val;
      if (sync) {
        this.update("updated", { sync, metadata, instanceId, historyGroup });
      }
    }
  }

  // data properties
  setName(val, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    if (this.name !== val) {
      this.name = val;
      if (sync) {
        this.update("updated", { sync, metadata, instanceId, historyGroup });
      }
    }
  }

  setDescription(val, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    if (this.description !== val) {
      this.description = val;
      if (sync) {
        this.update("updated", { sync, metadata, instanceId, historyGroup });
      }
    }
  }

  setSource(val, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    if (this.source !== val) {
      this.source = val;
      this._cleanSource = ResourceEntity.StripSource(this.source);
      if (sync) {
        this.update("updated", { sync, metadata, instanceId, historyGroup });
      }
    }
  }

  setRating(val, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    if (this.rating !== val) {
      this.rating = val;
      if (sync) {
        this.update("updated", { sync, metadata, instanceId, historyGroup });
      }
    }
  }

  setTags(val, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    if (!(this.tags.length === val.length && this.tags.every((v, i) => v === val[i]))) {
      this.tags = val;
      if (sync) {
        this.update("updated", { sync, metadata, instanceId, historyGroup });
      }
    }
  }


  // links
  setUrl(val, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    if (this.url !== val) {
      if (val === "" || this.isValidHttpUrl(val)) {
        this._lastModified = new Date();
        this.url = val;
        if (sync) {
          this.update("updated", { sync, metadata, instanceId, historyGroup });
        }
      } else {
        throw new Error("invalid url");
      }
    }
  }

  setImageUrl(val, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    if (this.imageUrl !== val) {
      this._lastModified = new Date();
      this.imageUrl = val;
      if (sync) {
        this.update("updated", { sync, metadata, instanceId, historyGroup });
      }
    }
  }

  // file properties
  setFileHandle(val, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    if (this.fileHandle !== val) {
      this.fileHandle = val;
      if (sync) {
        this.update("updated", { sync, metadata, instanceId, historyGroup });
      }
    }
  }

  setFileSize(val, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    if (this.fileSize !== val) {
      this._lastModified = new Date();
      this.fileSize = val;
      if (sync) {
        this.update("updated", { sync, metadata, instanceId, historyGroup });
      }
    }
  }

  setFileType(val, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    if (this.fileType !== val) {
      this._lastModified = new Date();
      this.fileType = val;
      if (sync) {
        this.update("updated", { sync, metadata, instanceId, historyGroup });
      }
    }
  }

  // state properties
  setIncomplete(val, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    if (this.incomplete !== val) {
      this._lastModified = new Date();
      this.incomplete = val;

      if (sync) {
        this.update("updated", { sync, metadata, instanceId, historyGroup });
      }
    }
  }

  setArchived(val, { sync = true, metadata = null, instanceId = null, historyGroup = null } = {}) {
    if (this.archived !== val) {
      this.archived = val;
      if (sync) {
        this.update("updated", { sync, metadata, instanceId, historyGroup });
        this.eventEmitter.emit("archived", this);
      }
    }
  }

  isVisible() {
    return !this.archived;
  }

  getImageUrl(fallback = false) {
    if (this.imageUrl && !this.incomplete && !fallback) {
      return this.imageUrl;
    }

    return !!this.url ? "/assets/placeholder_online.svg" : "/assets/placeholder_offline.svg";
  }

  isFile() {
    return this.type === "file" || this.type === "image";
  }

  isWorking() {
    return (this.incomplete || this._pending) && (
      ((Date.now() - this._lastModified.getTime()) < 1000 * 60 * 30) ||
      (this.isFile() && this._file !== null) ||
      this._uploading
    );
  }

  isInInbox() {
    return this.parentId === "inbox";
  }

  isUploading() {
    return this._file !== null || (!!this.fileHandle && this.incomplete);
  }

  hasProgress() {
    return this._progress > 0;
  }

  getProgress() {
    return Math.max(0, Math.min(100, (this._progress / this.fileSize) * 100));
  }

  // File Utility //
  
  onHandleReceived(handles) {
    const mainKey = `${this.id}/main`;
    const previewKey = `${this.id}/preview`;
    const hasMain = !!handles[mainKey];
    const hasPreview = !!handles[previewKey];
    
    if (!this._uploading && (hasMain || hasPreview)) {
      this._uploading = true;
      this.handleUpload(handles, mainKey, previewKey, hasMain, hasPreview);
    }

    return hasMain || hasPreview;
  }

  async handleUpload(handles, mainKey, previewKey, hasMain, hasPreview) {
    try {
      await UPLOAD_QUEUE.wait();
      
      if (hasPreview) {
        await this.uploadPreviewFile(handles[previewKey], this._file);
      }
  
      if (hasMain) {
        await this.uploadMainFile(handles[mainKey], this._file);
      }
  
    } catch (err) {
      console.log(err);
    } finally {
      this._file = null;
      UPLOAD_QUEUE.finish();
    }
  }

  async uploadMainFile(handles, file) {

    try {
      const form = new FormData();

      Object.keys(handles).forEach(key => {
        if (key !== "postUrl") {
          form.append(key, handles[key]);
        }
      });

      form.append("file", file);

      await Retry.Axios(async () => await axios.post(
        handles["postUrl"],
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          },
          onUploadProgress: (progress) => {
            this._progress = progress.loaded;
            this.eventEmitter.emit("uploadProgress", this);
          }
        }
      ));

      this._uploading = false;
      
      if (!this._deleted) {
        this.setIncomplete(false);
      }

      this.eventEmitter.emit("uploadFinished", this);

    } catch (err) {
      console.log("error uploading resource file to handle");
      console.log(err);
      this._uploading = false;
      this.eventEmitter.emit("uploadFinished", this);
    }
  }

  async uploadPreviewFile(handles, file) {
    try {
      if (ImageUtility.IsImage(file)) {
        const preview = await ImageUtility.GenerateFilePreviewAsync(file, 480);
        const form = new FormData();
  
        Object.keys(handles).forEach(key => {
          if (key !== "postUrl") {
            form.append(key, handles[key]);
          }
        });
  
        form.append("file", preview);
  
        await Retry.Axios(async () => await axios.post(
          handles["postUrl"],
          form,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
        ));
  
        this.broadcast();
      }
    } catch (err) {
      console.log(err, file);
    }
  }

  isProcessing() {
    return this._uploading;
  }

  openUrl() {
    window.open(this.url.startsWith("http://") || this.url.startsWith("https://") ? this.url : `https://${this.url}`, "_blank");
  }

  async getFileUrl(sharePrefix = "") {
    try {
      const result = await Retry.Axios(async () => await axios(`/api/resource/file/${sharePrefix}${!!sharePrefix ? `${this.contextId}/` : ""}${this.id}/${this.fileHandle}`));
      return result.data.signedUrl;
    } catch (err) {
      console.log("error fetching download url", err);
      return null;
    }
  }

  isValidHttpUrl(string) {
    let url;
    try {
      url = new URL(string.startsWith("https://") || string.startsWith("http://") ? string : `https://${string}`);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  }

  static StripSource(source) {
    try {
      if (source.startsWith("https://") || source.startsWith("http://")) {
        let url = (new URL(source)).hostname;
        return url.startsWith("www.") ? url.substr(4) : url;
      } else {
        return source;
      }
    } catch (err) {
      return source;
    }
  }

}
