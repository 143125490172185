import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Backdrop(theme) {
  const color = alpha(theme.palette.mode === "dark" ? "rgba(0,0,0,1)" : theme.palette.grey[900], 0.5);

  return {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          background: [
            color,
          ],
          '&.MuiBackdrop-invisible': {
            background: 'transparent'
          }
        }
      }
    }
  };
}
